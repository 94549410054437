import { AuditHistoryPageEnum, AuditHistoryEnityEnum } from './constants';
import { RULE_ID } from 'common/constants';
import { IncomeRuleIds as INCOME_RULE_ID } from 'pages/IncomeCalculator/enums';
import {
  DepositoryLiquidAssetTypeArray,
  DepositoryNonLiquidAssetTypeArray,
} from 'pages/Assets/AssetDepositoryAccounts/enum';

// TOOD: this is probably not the best name
export const AuditHistoryMap = {
  [RULE_ID.TITLE_COMMITMENT_RULE]:
    AuditHistoryPageEnum.PROPERTY_TITLE_COMMITMENT,
  [RULE_ID.NET_TANGIBLE_BENEFITS_RULE_ID]:
    AuditHistoryPageEnum.NET_TANGIBLE_BENEFITS,
  [RULE_ID.CPL_REVIEW_RULE]: determineCPL,
  [RULE_ID.WIRE_INSTRUCTIONS_RULE]:
    AuditHistoryPageEnum.PROPERTY_WIRE_INSTRUCTIONS,
  [RULE_ID.ASSET_TYPE_RULE_ID]: determineAssets,
  [RULE_ID.HOMEOWNERS_PARENT_RULE_ID]:
    AuditHistoryPageEnum.HOMEOWNERS_INSURANCE,
  [RULE_ID.PURCHASE_AGREEMENT_RULE_ID]: AuditHistoryPageEnum.CONTRACT_REVIEW,
  [INCOME_RULE_ID.SmartValidateDocument]: determineIncome,
  [INCOME_RULE_ID.SmartCalculateIncome]: determineIncome,
};

export function determineCPL(selectedAlert) {
  if (selectedAlert?.isEandO) {
    return AuditHistoryPageEnum.PROPERTY_CLOSING_PROTECTION_LETTER_E_AND_O;
  }
  return AuditHistoryPageEnum.PROPERTY_CLOSING_PROTECTION_LETTER;
}

export function determineAssets(selectedAlert, assetType) {
  if (selectedAlert?.ruleId !== RULE_ID.ASSET_TYPE_RULE_ID)
    return AuditHistoryPageEnum.UNKNOWN;

  const id = selectedAlert.ruleReferenceId;
  if (
    DepositoryLiquidAssetTypeArray.includes(assetType) ||
    DepositoryNonLiquidAssetTypeArray.includes(assetType)
  ) {
    return 'assets/' + id;
  }
  return `${AuditHistoryPageEnum.ASSET_EMD}/${id}/${AuditHistoryEnityEnum.ASSET_EMD}`;
}

export function determineIncome(selectedAlert) {
  const customerRecordId = +(selectedAlert?.customerRecordId || 0);
  const employerId = +(selectedAlert?.ruleReferenceId || 0);

  if (selectedAlert?.ruleId == INCOME_RULE_ID.SmartValidateDocument) {
    const path = 'income/validateDocument';
    const documentId = +(selectedAlert?.additionalReferences?.DocumentId || 0);
    return `${path}/${customerRecordId}/${employerId}/${documentId}`;
  } else if (selectedAlert?.ruleId == INCOME_RULE_ID.SmartCalculateIncome) {
    const path = 'income/calculate';
    const calculatorTypeId = +(
      selectedAlert?.additionalReferences?.CalculatorTypeId || 0
    );
    return `${path}/${customerRecordId}/${employerId}/${calculatorTypeId}`;
  } else {
    return `${AuditHistoryPageEnum.UNKNOWN}`;
  }
}

/**
 *
 * @param {{ ruleId: number, isEandO: boolean, ruleReferenceId: number }} selectedAlert
 * @param {string} assetType
 * @returns {number | string}
 */
export function getAuditHistoryPageId(selectedAlert, assetType) {
  const { ruleId } = selectedAlert;
  const mapResult = AuditHistoryMap[ruleId] ?? AuditHistoryPageEnum.UNKNOWN;

  if (typeof mapResult === 'number') {
    return mapResult;
  }

  // INFO: if/when there are a few conditions for ruleIds, turn this into a switch statement
  if (ruleId === RULE_ID.ASSET_TYPE_RULE_ID) {
    return mapResult(selectedAlert, assetType);
  }

  return mapResult(selectedAlert);
}
