import CrudGrid from 'common/components/CrudGrid';
import { useState, useMemo } from 'react';
import { useToast } from '@chakra-ui/react';
import Text from 'common/components/Texts/Text';
import IncomeSummariesForm from './Income1003Form/Income1003Form';
import { useSelector } from 'react-redux';
import { typedApiClient } from 'common/util/api-client';
import {
  concatenateObjectAttributes,
  renderDate,
  renderNullValueAsDash,
} from 'common/components/CrudGrid/crudGridUtils';
import { OPERATORS } from 'common/components/CrudGrid/Filters/filterGridData';
import { useDispatch } from 'react-redux';
import { setLoanSummaryUpsertOrDelete } from 'common/redux/actions';
import { formatCurrency } from 'common/util/format';
import { USER_ROLE } from 'common/constants';
import { StyledLoan1003LayoutContainer } from '../common/components/StyledComponents';
import ScrollIntoViewContainer from '../common/components/ScrollIntoViewContainer';
import { isReadOnlyUrl } from 'common/util/appState';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export class NewIncome {
  employerId = 0;
  employerName = '';
  borrowerName = {
    firstName: '',
    middleName: '',
    lastName: '',
    suffixName: '',
  };
  customerRecordId = null;
  customerRecordIdOriginal = null;
  currentEmployment = 'Y';
  primaryEmployment = false;
  country = null;
  employerPhone = undefined;
  endDate = null;
  startDate = null;
  percentageOwnership = null;
  yearsOnJob = null;
  cpaInformation = [];
  selfEmployed = false;
  employerAddress = {
    addressLine1: '',
    state: '',
    city: '',
    zipCode: '',
  };
  monthlyIncomeDetails = [];
  verifiedDate = null;
}

export default function Income1003Layout() {
  //TODO refactor to useReducer
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [rowToEdit, setRowToEdit] = useState({});
  const queryClient = useQueryClient();
  const { obfuscatedLoanIdentifier } = useSelector(
    ({ url: { obfuscatedLoanIdentifier } }) => {
      return {
        obfuscatedLoanIdentifier,
      };
    },
  );
  const {
    user: { isUnderwriter, role },
    boltStatus,
  } = useSelector((state) => state.home);

  const { data, isLoading, isError } = useQuery({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: ['getSummaryIncomeData'],
    queryFn: async () => {
      try {
        const { data } =
          await typedApiClient.v1.underwritingSummaryIncomeGetUnderwritingSummaryIncomeData(
            {
              obfuscatedLoanIdentifier,
            },
          );

        return data;
      } catch (e) {
        toast({
          title: 'Error',
          description: 'Could not get data',
          status: 'error',
          duration: 5000,
          isClosable: false,
        });

        throw e;
      }
    },
  });

  const { employmentReviewDetail, allBorrowers } = useMemo(
    () => ({
      employmentReviewDetail: data?.employmentReviewDetail ?? [],
      allBorrowers: data?.allBorrowers ?? [],
    }),
    [data],
  );

  const totalMonthlyPayments = useMemo(() => {
    return employmentReviewDetail.reduce(
      (sum, obj) =>
        sum + (obj.currentEmployment === 'Y' ? obj.monthlyAmount : 0),
      0,
    );
  }, [employmentReviewDetail]);
  const isWriteAccessDisabled = useMemo(() => {
    return (
      (!isUnderwriter && boltStatus?.value) ||
      role === USER_ROLE.ACCOUNT_EXECUTIVE ||
      (isUnderwriter && !data?.hasWritePermission) ||
      isReadOnlyUrl()
    );
  }, [isUnderwriter, boltStatus, data, role]);

  const toast = useToast();

  const columns = [
    {
      fieldName: 'borrowerName',
      headerName: 'Borrower Name',
      sortable: false,
      isNumeric: false,
      display: true,
      filterable: true,
      renderCell: (val) =>
        concatenateObjectAttributes(val, [
          'firstName',
          'middleName',
          'lastName',
          'suffixName',
        ]),
    },
    {
      fieldName: 'employerName',
      headerName: 'Employer or Description',
      isNumeric: false,
      sortable: false,
      display: true,
    },
    {
      fieldName: 'title',
      headerName: 'Job Title',
      isNumeric: false,
      sortable: false,
      display: true,
      renderCell: (val) => renderNullValueAsDash(val, '-'),
    },
    {
      fieldName: 'currentEmployment',
      headerName: 'Current',
      isNumeric: false,
      sortable: false,
      display: true,
      renderCell: (val) => (val === 'Y' ? 'Yes' : 'No'),
    },
    {
      fieldName: 'startDate',
      headerName: 'Start Date',
      isNumeric: false,
      sortable: true,
      display: true,
      renderCell: (val) => renderDate(val),
    },
    {
      fieldName: 'endDate',
      headerName: 'End Date',
      isNumeric: false,
      sortable: true,
      display: true,
      renderCell: (val) => renderDate(val),
    },
    {
      fieldName: 'monthlyAmount',
      headerName: 'Monthly Income',
      isNumeric: true,
      display: true,
      renderCell: (val) => formatCurrency(val),
    },
  ];

  const handleEditMode = (row) => {
    setRowToEdit({
      ...row,
      customerRecordIdOriginal: row.customerRecordId,
    });
    setShowForm(true);
  };

  const handleClose = () => {
    setRowToEdit({});
    setShowForm(false);
  };

  const handleSave = () => {
    queryClient.invalidateQueries({ queryKey: ['getSummaryIncomeData'] });
    setShowForm(false);
    setRowToEdit({});
  };

  const { mutate: handleDelete } = useMutation({
    mutationFn: async (value) => {
      const itemToDelete = value[0];

      const { data } =
        await typedApiClient.v1.underwritingSummaryIncomeDeleteUnderwritingSummaryIncome(
          {
            obfuscatedLoanIdentifier,
            customerIdentifier: {
              obfuscatedLenderDatabaseId:
                obfuscatedLoanIdentifier.obfuscatedLenderDatabaseId,
              customerRecordId: itemToDelete.customerRecordId,
            },
            employerId: itemToDelete.employerId,
          },
        );

      return data;
    },
    onError: () => {
      toast({
        title: 'Delete Failed',
        description: 'Record was not able to be deleted.',
        status: 'error',
        duration: 5000,
        isClosable: false,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getSummaryIncomeData'] });
      setRowToEdit({});
      setShowForm(false);
      toast({
        title: 'Success',
        description: 'Employment Deleted',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      dispatch(setLoanSummaryUpsertOrDelete(true));
    },
  });

  const gridDef = {
    gridType: 'Income',
    defaultSort: { field: 'monthlyAmount', direction: 'desc' },
    uniqueIdAttributeFields: ['customerRecordId', 'employerId'],
    gridLanguage: {
      singularItem: 'Income',
    },
    getDialogDeleteMessage: (row) =>
      `Are you sure you want to remove income source -${row[0].employerName}`,
    getAriaLabel: (row) => row.employerName,
    readOnly: false,
    manageColumns: false,
    allowFilters: true,
  };

  const buttonDefs = [
    {
      label: 'Add Income',
      action: () => {
        handleEditMode(new NewIncome());
      },
      visible: !isWriteAccessDisabled,
      isDisable: false,
      testId: 'addIncomeButton',
    },
  ];

  const filters = [
    {
      options: allBorrowers.map((borrower) => {
        return {
          value: borrower.customerRecordId,
          label: concatenateObjectAttributes(borrower, [
            'firstName',
            'lastName',
          ]),
        };
      }),
      label: 'Borrower(s)',
      inputType: 'multiSelect',
      fieldName: 'customerRecordId',
      operator: OPERATORS.EXCLUDE,
      evaluateFilter: (row, value) => value.includes(row.customerRecordId),
    },
  ];

  return (
    <StyledLoan1003LayoutContainer>
      <CrudGrid
        gridDef={gridDef}
        columns={columns}
        disableRowClick={isReadOnlyUrl()}
        data={employmentReviewDetail}
        onRowEditClick={(row) => {
          handleEditMode(row);
        }}
        filters={filters}
        isLoading={isLoading || isError}
        readOnly={isWriteAccessDisabled}
        selectedRow={rowToEdit}
        onDelete={handleDelete}
        buttonDefs={buttonDefs}
      />
      {!isLoading && (
        <div
          style={{
            margin: '1rem 0rem',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Text fontWeight={'bold'} margin={'2rem 0rem'}>
            Total Monthly Income : {formatCurrency(totalMonthlyPayments)}
          </Text>
        </div>
      )}
      {showForm && (
        <ScrollIntoViewContainer
          valueToWatch={rowToEdit}
          style={{ width: '100%' }}
        >
          <IncomeSummariesForm
            rowToEdit={rowToEdit}
            onClose={() => handleClose()}
            onSave={handleSave}
            isWriteAccessDisabled={isWriteAccessDisabled}
            handleToastMessage={(toastMessage) => toast(toastMessage)}
            allBorrowerOptions={allBorrowers}
            obfuscatedIds={obfuscatedLoanIdentifier}
          />
        </ScrollIntoViewContainer>
      )}
    </StyledLoan1003LayoutContainer>
  );
}
