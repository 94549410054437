import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import AccordionItem from 'common/components/Accordions/AccordionItem';
import AccordionPanel from 'common/components/Accordions/AccordionPanel';
import Accordion from 'common/components/Accordions/Accordion';
import AccordionIcon from 'common/components/Accordions/AccordionIcon';
import AccordionButton from 'common/components/Accordions/AccordionButton';
import Box from 'common/components/Box';
import Text from 'common/components/Texts/Text';

const styleMap = {
  ['knowYourLoan']: {
    backgroundColor: 'boltV2.blue',
    color: 'white',
    borderRadius: '4px 4px 0 0',
    fontWeight: 500,
  },
  ['reconciliation']: {
    backgroundColor: 'blue.50',
    color: 'black',
    borderRadius: '4px 4px 0 0',
    fontWeight: 500,
  },
  ['purchaseAgreement']: {
    backgroundColor: 'none',
    borderRadius: 0,
    fontWeight: 500,
  },
  ['summary1003AuditHistory']: {
    backgroundColor: 'none',
    color: 'gray.900',
  },

  ['default']: {
    backgroundColor: 'gray.50',
    color: 'black',
    borderRadius: 0,
    fontWeight: 'bold',
  },
};

const AccordionContainer = ({
  header,
  secondaryHeader,
  children,
  layoutType,
  accordionButtonSlot,
  accordionButtonProps,
  accordionPanelProps,
  defaultIndex,
  sx,
  alwaysOpenContainerSlot,
  h2Style,
  ...props
}) => {
  const { backgroundColor, color, borderRadius, fontWeight } =
    sx ?? styleMap[layoutType] ?? styleMap['default'];

  const headerWithoutSpaces = header?.replace(/[\s\-|']/g, '') || '';
  const is1003AuditHistory = layoutType === 'summary1003AuditHistory';

  return (
    <Accordion
      allowMultiple
      defaultIndex={defaultIndex ? defaultIndex : [0]}
      mt={props.mt ?? 6}
      mb={props.mb ?? 4}
      style={sx}
      {...props}
      css={css`
        & .chakra-collapse {
          overflow: unset !important;
        }
      `}
    >
      <AccordionItem
        border={props.border ?? 'solid 1px #E2E8F0'}
        borderRadius="4px"
      >
        <h2 style={h2Style}>
          {!accordionButtonSlot && (
            <AccordionButton
              borderBottom={accordionButtonProps?.borderBottom}
              color={accordionButtonProps?.color}
              paddingLeft={accordionButtonProps?.paddingLeft}
              borderRadius={borderRadius}
              padding="1rem"
              bg={backgroundColor}
              _hover={{ backgroundColor }}
              onClick={accordionButtonProps?.onClick || (() => {})}
              sx={{
                ...(is1003AuditHistory && {
                  '&[aria-expanded="true"]': {
                    backgroundColor: accordionButtonProps?.accordionBgExpanded
                      ? accordionButtonProps.accordionBgExpanded
                      : 'gray.50',
                  },
                }),
              }}
              data-testid={`accordionButton-${headerWithoutSpaces}`}
            >
              <Text
                as="h4"
                d="inline-flex"
                flex="1"
                textAlign="left"
                fontSize={accordionButtonProps?.fontSize ?? 'sm'}
                fontWeight={fontWeight}
                color={color}
              >
                {header}
              </Text>
              <Text>{secondaryHeader}</Text>
              <AccordionIcon color={color} />
            </AccordionButton>
          )}
          {accordionButtonSlot && <div>{accordionButtonSlot}</div>}
        </h2>
        {alwaysOpenContainerSlot}
        <AccordionPanel p="unset" m="unset" pb={4}>
          <Box
            p={accordionPanelProps?.padding ?? 3}
            pl={accordionPanelProps?.paddingLeft ?? 6}
            pr={accordionPanelProps?.paddingRight ?? 6}
          >
            {children}
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

AccordionContainer.propTypes = {
  /** The header text to display for the Validate Forms */
  header: PropTypes.string.isRequired,
  /** The header text to display for the Validate Forms */
  secondaryHeader: PropTypes.string,
  /** The header text to display for the Validate Forms */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  defaultIndex: PropTypes.arrayOf(PropTypes.number),
  layoutType: PropTypes.string,
  alwaysOpenContainerSlot: PropTypes.node,
  accordionButtonSlot: PropTypes.node,
  accordionButtonProps: PropTypes.shape(),
  accordionPanelProps: PropTypes.object,
  sx: PropTypes.object,
  mt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  border: PropTypes.string,
  h2Style: PropTypes.object,
};

export default AccordionContainer;
