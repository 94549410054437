import { REFI_PURPOSE_TYPE } from 'pages/MortgageHistory/utils';

export const TITLE_PROPERTY_TYPES = {
  'Single Family Residence': 22,
  '2-4 Unit Dwelling': 25,
  'Planned Unit Development': 32,
  Condominium: 34,
  'SFR Purchase Home/Refi Land': 47,
  'Site Condo': 48,
  'PUD Purchase Home/Refi Land': 49,
  'Manufactured Single Wide': 54,
  'Manufactured Multi Wide': 55,
  'Manufactured PUD Single Wide': 53,
  'Manufactured Condo Single Wide': 52,
  'Manufactured PUD Multi Wide': 57,
  'Manufactured Condo Multi Wide': 56,
  Cooperative: 30,
};

export const AUS_METHOD = {
  DU: 'Desktop Underwriter',
  LP: 'Loan Prospector',
  LPA: 'Loan Product Advisor',
};

export const allRefinanceTypes = [
  REFI_PURPOSE_TYPE.CASH_OUT_COLLEGE_EDUCATION,
  REFI_PURPOSE_TYPE.CASH_OUT_DEBT_CONSOLIDATION,
  REFI_PURPOSE_TYPE.CASH_OUT_HOME_IMPROVEMENT,
  REFI_PURPOSE_TYPE.CASH_OUT_OTHER,
  REFI_PURPOSE_TYPE.NOT_APPLICABLE,
  REFI_PURPOSE_TYPE.RATE_AND_TERM_REDUCTION_CONVENTIONAL,
  REFI_PURPOSE_TYPE.RATE_AND_TERM_REDUCTION_FHA,
  REFI_PURPOSE_TYPE.STREAMLINE_REFINANCE,
  REFI_PURPOSE_TYPE.CASH_OUT_STUDENT_LOAN,
];

// Vesting
export const IS_PROPERTY_HELD_IN_TRUST = 'd2afefee-b7d9-4181-a7c8-92eb329c3492';
export const vestingCustomers = 'vestingCustomers';
export const WILL_BORROWERS_CLOSE_IN_TRUST =
  '7fffbb08-67b0-43f3-a85e-7ecf75ab345e';
export const ARE_ANY_NON_BORROWERS_ON_LOAN =
  'c1f02014-85e3-4ef5-a445-1d73d5c2de06';
export const ANSWER_YES = 'Yes';
export const ANSWER_NO = 'No';

export const TITLE_WALK_ME_PRIMARY_BUTTON_ID = 'titleCommitmentWalkMe';
export const WIRE_QC_REVIEW_LABEL = 'Title Review';
export const WIRE_QC_REVIEW_VALUE = '68087-129992-Title Review';
export const WIRE_PICK_FROM_LIST_DEFAULT = '<Pick From List>';
export const DOCUMENT_ID = 'documentId';
export const UNDERWRITER_VERIFIED = 'fe571d22-4342-4743-aee2-af48e8c73c2d';
export const TITLE_UNDERWRITER_COMPANY = 'a9f2f08e-c741-4656-804d-982a6cc70a01';
