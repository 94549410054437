import PropTypes from 'prop-types';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import SelectedCategoryNavContainer from '../components/SelectedCategoryNavContainer';
import NavItemWrapper from '../components/NavItemWrapper';
import { NavWrapper } from '../components/Styled';
import LeftDrawerWrapper from 'common/components/Nav/LeftDrawer/components/LeftDrawerWrapper';
import SelectedLeftDrawerContent from 'common/components/Nav/LeftDrawer/components/SelectedLeftDrawerContent';

import {
  PanelContainerFullWidth,
  RightContainer,
} from '../../StyledActivityPanel';
import Income1003Layout from 'pages/Loan1003DataGrids/Income';
import Liabilities1003Layout from 'pages/Loan1003DataGrids/Liabilities';
import Asset1003Layout from 'pages/Loan1003DataGrids/Assets';
import Borrower1003Layout from 'pages/Loan1003DataGrids/Borrower';
import RailNav from '../Rail/components/RailNav';
import BlockedPage from '../../BlockedPage';
import RealEstateOwned1003Layout from 'pages/Loan1003DataGrids/RealEstateOwned';
import { useSelector } from 'react-redux';
import { isReadOnlyUrl } from 'common/util/appState';
import { API_STATUS } from 'common/constants';
import Spinner from 'common/components/Spinner';

export const categories = [
  {
    to: '/borrower',
    label: 'Borrower',
    flagName: 'IncludeBorrower',
    component: () => <Borrower1003Layout />,
  },
  {
    to: '/liabilities',
    label: 'Liabilities',
    flagName: 'IncludeLiability',
    component: () => <Liabilities1003Layout />,
  },
  {
    to: '/income',
    label: 'Income',
    flagName: 'IncludeIncome',
    component: () => <Income1003Layout />,
  },
  {
    to: '/assets',
    label: 'Assets',
    flagName: 'IncludeAsset',
    component: () => <Asset1003Layout />,
  },
  {
    to: '/reo',
    label: 'REO',
    flagName: 'IncludeREO',
    component: () => <RealEstateOwned1003Layout />,
  },
].map((category) => {
  if (isReadOnlyUrl()) {
    if (
      category.label === 'REO' ||
      category.label === 'Income' ||
      category.label === 'Assets' ||
      category.label === 'Liabilities'
    ) {
      return { ...category, to: `${category.to}/readOnly` };
    }
  }
  return category;
});

const SummaryNav = ({ url, featureFlags }) => {
  return (
    <NavWrapper>
      <SelectedCategoryNavContainer>
        {categories.map(({ flagName, label, to }, i) =>
          featureFlags[flagName] ? (
            <NavItemWrapper
              key={`${label}-${i}`}
              label={label}
              to={`${url}${to}`}
            />
          ) : null,
        )}
      </SelectedCategoryNavContainer>
    </NavWrapper>
  );
};
SummaryNav.propTypes = {
  url: PropTypes.string,
  featureFlags: PropTypes.object,
};

export default function SummaryWrapper() {
  const { path, url } = useRouteMatch();
  const featureFlags = useSelector(({ featureFlags }) => featureFlags);

  if (featureFlags.status === API_STATUS.GETTING) {
    return <Spinner />;
  }

  return (
    <>
      <SummaryNav url={url} featureFlags={featureFlags} />
      <PanelContainerFullWidth templateColumns="auto auto 1fr">
        <RailNav />
        {!isReadOnlyUrl() && (
          <LeftDrawerWrapper data-testid="left-drawer-wrapper">
            <SelectedLeftDrawerContent data-testid="selected-left-drawer-content" />
          </LeftDrawerWrapper>
        )}
        <RightContainer featureFlag>
          <Switch>
            {categories.map(({ to, flagName, component: Component }) => {
              return (
                <Route
                  key={crypto.randomUUID()}
                  path={`${path}${to}`}
                  render={() => {
                    if (featureFlags[flagName]) {
                      return <Component />;
                    }
                    return <BlockedPage />;
                  }}
                />
              );
            })}
          </Switch>
        </RightContainer>
      </PanelContainerFullWidth>
    </>
  );
}
