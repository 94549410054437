import { useQuery } from '@tanstack/react-query';
import { typedApiClient } from 'common/util/api-client';
import { useSelector } from 'react-redux';
import { REQUEST_REFERENCE_TYPE } from 'pages/IncomeCalculator/enums';

export const queryKeys = {
  all: 'genericReviewBreakdownCalculation',
  employment: (incomeIdentifier, calculationId = 0) => [
    ...queryKeys.all,
    'employmentIncome',
    incomeIdentifier,
    calculationId,
  ],
  rental: (rentalAddressId, calculationId = 0) => [
    ...queryKeys.all,
    'rentalIncome',
    rentalAddressId,
    calculationId,
  ],
};

export default function useCalculationData(
  incomeIdentifier,
  calculationId = 0,
  enabled = true,
) {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url }) => url.obfuscatedLoanIdentifier,
  );

  return useQuery(
    queryKeys.employment(incomeIdentifier, calculationId),
    async function () {
      const result = await typedApiClient.v1.incomeCalculatorGetCalculationData(
        {
          obfuscatedLoanIdentifier,
          requestReferenceType: REQUEST_REFERENCE_TYPE.employmentIncome,
          incomeIdentifier,
          calculationId,
          rentalAddressId: 0,
        },
      );
      return result.data;
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  );
}
