import * as API_ENDPOINTS from './api-endpoints';
import API_STATUS from './api-status';
import {
  US_STATES,
  US_STATES_OPTIONS,
  US_STATES_LABEL_VALUE_ARRAY,
} from './us-states';
import {
  RULE_ID,
  QUESTION_ID,
  RULE_CATEGORY_ID,
  RULE_SUB_CATEGORY_ID,
  QUESTION_DISPLAY_TYPE_ID,
  RULE_RESULT_STATUS_ID,
  RULE_REFERENCE_TYPE_ID,
  ALERT_STYLE,
  ALERT_STYLE_DESCRIPTION,
  ALERT_SEVERITY,
  INCOME_RULE_ID,
} from './api-ids';

export const TRUE_STRING_VALUE = 'true';
export const FALSE_STRING_VALUE = 'false';

const ALERT_TYPES = {
  CHILD: 'Child',
  COMPLETED: 'Completed',
  PARENT: 'Parent',
  VERIFIED: 'Child',
};

const USER_ROLE = {
  UNDERWRITER: 'Underwriter',
  ACCOUNT_EXECUTIVE: 'AccountExecutive',
};

export const BOLT_IMPERSONATE_COOKIE = (obfuscatedLoanRecordId) =>
  'boltimpersonate' + obfuscatedLoanRecordId;

export const YES = 'Yes';
export const NO = 'No';

const SIGNAL_R_STATUS = {
  RULE_EVALUATION_COMPLETED: 0,
  ADR_PROCESS_COMPLETED: 1,
  CLASSIFY_STATUS_CHANGE: 2,
  ADR_PROCESS_FAILED: 3,
  FRAUD_GUARD_RED_FLAGS_AVAILABLE: 4,
  AUS_COMPLETED: 5,
  THIRD_PARTY_CHECK_ALERT: 6,
  BOLT_CONDITIONS_APPLIED: 7,
  RULE_EVALUATION_ERROR: 8,
  REQUEST_COMPLETED: 14,
  REQUEST_ERRORED: 15,
  LOAN_STATUS_FLIP_SUCCESS: 18,
  LOAN_STATUS_FLIP_FAILED: 19,
};

const PLACEHOLDER_TEXT = {
  BORROWER_NAME: '{{BorrowerName}}',
};

const RADIO_BUTTON_OPTIONS = ['Yes', 'No'];

const GIFT_OF_EQUITY_OPTIONS = {
  APPRAISAL_WAIVER: 'AppraisalWaiver',
  GIFT_OF_EQUITY: 'GiftOfEquity',
};

const CREDIT = {
  LOAN_PRODUCT_ADVISOR: 'Loan Product Advisor',
  DESKTOP_UNDERWRITER: 'Desktop Underwriter',
};

const GENERIC_ERROR_MESSAGE =
  'An unexpected error has occurred. Please try again, or submit a CR for additional assistance if the error persists.';

const FORM_MESSAGES = {
  requiredField: 'Required field',
  startDate: (startDate) =>
    'Date entered cannot be before start date of ' + startDate,
  currentDate:
    'Date entered cannot be after the current date of ' +
    new Date().toLocaleDateString(),
};

const RESOLUTION_TYPE_ID = {
  NEW_CONDITION_ACTION_REQUIRED: 1,
  CONDITIONS_ADDED_TO_LOAN: 2,
  NO_ACTION: 3,
  NEXT_QUESTION: 4,
};
Object.freeze(RESOLUTION_TYPE_ID);

const RESOLUTION_TYPE_ALERT_MAPPING = {
  [RESOLUTION_TYPE_ID.NEW_CONDITION_ACTION_REQUIRED]: 'warning',
  [RESOLUTION_TYPE_ID.CONDITIONS_ADDED_TO_LOAN]: 'warning',
  [RESOLUTION_TYPE_ID.NO_ACTION]: 'success',
};
Object.freeze(RESOLUTION_TYPE_ALERT_MAPPING);

const REGEX = {
  HANDLEBAR: /{{.*?}}/, // capture everything within the {{}}
  SPECIAL_CHARACTERS: /[^a-zA-Z0-9' ]+/g, // captures non-alphanumerics
};
const NA = 'NA';

const LOAN_PRODUCT_TYPE = {
  CONVENTIONAL: 1,
  VA: 2,
  FHA: 3,
  USDA: 4,
};

const LOAN_PRODUCT_TYPE_BY_NAME = {
  FHA: 'FHA',
  CONVENTIONAL: 'Conventional',
};

const OCCUPANCY_TYPE_BY_NAME = {
  PRIMARY_RESIDENCE: 1,
  INVESTMENT_PROPERTY: 2,
  SECONDARY_RESIDENCE: 3,
  RESIDENCE_BEYOND_SECOND_HOME: 4,
  OWNER_OCCUPIED: 5,
  INVESTMENT_OCCUPY_FOURTEEN_DAYS: 6,
};

const PROPERTY_TYPES = {
  SFR: 'SFR',
  MULTIUNIT: '2-4Unit',
  CONDO: 'Condo',
};

const LOAN_PURPOSE_TYPE = {
  REFI_FIRST_MORTGAGE: 3,
  PURCHASE: 1,
  HELOC: 2,
  CONSTRUCTION_TO_PERMANENT: 6,
  PURCHASE_SECOND_MORTGAGE: 9,
  REFINANCE_SECOND_MORTGAGE: 10,
  HELOC_SECOND_MORTGAGE: 11,
  CONSTRUCTION_REFINANCE: 12,
};

const BUSINESS_CHANNEL = {
  WHOLESALE: 0,
  CONSUMER_DIRECT: 1,
  RETAIL: 2,
  EMPLOYEE: 3,
  CORRESPONDENT: 4,
};

const INVESTOR_COLLATERAL_PROGRAM_IDENTIFIER = {
  BLANK: 0,
  PIW: 1,
  ID2075: 2,
  LEVEL1_PIW: 3,
  ACE: 4,
  EASY_VALUATION: 6,
  FAST_FREDDIE: 7,
};
export const INVALID_DATE = 'Invalid Date';

export const PROPERTY_REVIEW_LEVEL_BY_NUMBER = {
  15: 'Drive By',
  16: 'Full Appraisal',
  17: 'None',
  27: 'Field Review',
};

export const DOCUMENT_GENERATION_PROVIDER_BY_NUMBER = {
  1: 'Docutech',
  2: 'DocMagic',
};

export const BUSINESS_CHANNEL_BY_NUMBER = {
  0: 'Wholesale',
  1: 'Consumer Direct',
  2: 'Retail',
  3: 'Employee',
  4: 'Correspondent',
};

export const LOAN_PURPOSE_TYPE_BY_NUMBER = {
  1: 'Purchase Home',
  2: 'HELOC',
  3: 'Refinance 1st Mortgage',
  6: 'Construction to permanent',
  9: 'Purchase second mortgage',
  10: 'Refinance second mortgage',
  11: 'HELOC second mortgage',
  12: 'Construction Refinance',
};
export const AUS_METHOD_BY_NUMBER = {
  1: 'Desktop Underwriter',
  2: 'Loan Product Advisor',
};

export const PROPERTY_TYPES_BY_NUMBER = {
  22: 'Single Family Residence',
  25: '2-4 Unit Dwelling',
  30: 'Cooperative',
  32: 'Planned Unit Development',
  34: 'Condominium',
  47: 'SFR Purchase Home/Refi Land',
  48: 'Site Condo',
  49: 'PUD Purchase Home/Refi Land',
  52: 'Manufactured Condo Single Wide',
  53: 'Manufactured PUD Single Wide',
  54: 'Manufactured Single Wide',
  55: 'Manufactured Multi Wide',
  56: 'Manufactured Condo Multi Wide',
  57: 'Manufactured PUD Multi Wide',
};

export const OCCUPANCY_TYPE_BY_NUMBER = {
  1: 'Primary Residence',
  2: 'Investment Property',
  3: 'Second Home',
};

export const CLOSING_TYPE_BY_NUMBER = {
  1: 'In Person',
  2: 'Full Virtual',
};

const SUB_CATEGORY_IDS = {
  CREDIT: 1,
  DRIVERS_LICENSE: 2,
  CREDIT_LIABILITIES: 3,
  DRIVERS_LICENSE_LIABILITIES: 4,
  NET_TANGIBLE_BENEFITS: 5,
  INCOME_CALCULATOR: 6,
  BORROWER1003: 7,
  PURCHASE_AGREEMENT: 8,
  FHA_REQUIREMENTS: 17,
  ASSETS: 9,
  REO: 10,
  THIRD_PARTY_CHECKS_: 11,
  RENTAL_INCOME: 12,
  FINAL_CHECKS: 13,
  EARLY_CLOSING_DISCLOSURE: 15,
  MANUAL_CHECKS: 16,
  SUBJECT_PROPERTY: 19,
  NON_OCCUPYING_CO_BORROWERS: 18,
  FINAL_UNDERWRITE_REVIEW: 20,
  TITLE: 21,
  HOMEOWNERS_INSURANCE: 14,
  SUPPLIMENTAL_INSURANCE: 23,
  FLOOD_INSURANCE: 24,
};

export {
  ALERT_SEVERITY,
  ALERT_STYLE,
  ALERT_STYLE_DESCRIPTION,
  ALERT_TYPES,
  API_ENDPOINTS,
  API_STATUS,
  CREDIT,
  FORM_MESSAGES,
  GENERIC_ERROR_MESSAGE,
  INCOME_RULE_ID,
  NA,
  PLACEHOLDER_TEXT,
  QUESTION_DISPLAY_TYPE_ID,
  QUESTION_ID,
  RADIO_BUTTON_OPTIONS,
  REGEX,
  RESOLUTION_TYPE_ID,
  RESOLUTION_TYPE_ALERT_MAPPING,
  RULE_CATEGORY_ID,
  RULE_ID,
  RULE_REFERENCE_TYPE_ID,
  RULE_RESULT_STATUS_ID,
  RULE_SUB_CATEGORY_ID,
  SIGNAL_R_STATUS,
  SUB_CATEGORY_IDS,
  USER_ROLE,
  US_STATES,
  US_STATES_OPTIONS,
  US_STATES_LABEL_VALUE_ARRAY,
  LOAN_PRODUCT_TYPE,
  LOAN_PRODUCT_TYPE_BY_NAME,
  PROPERTY_TYPES,
  LOAN_PURPOSE_TYPE,
  GIFT_OF_EQUITY_OPTIONS,
  OCCUPANCY_TYPE_BY_NAME,
  INVESTOR_COLLATERAL_PROGRAM_IDENTIFIER,
  BUSINESS_CHANNEL,
};
