import PropTypes from 'prop-types';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';
import styled from '@emotion/styled';
import { useEffect, useRef } from 'react';
import RadioGroup from 'common/components/Radios/RadioGroup';
import ChakraRadio from 'common/components/Radios/Radio';
import HStack from 'common/components/Stacks/HStack';
import Text from 'common/components/Texts/Text';
import FormLabel from 'common/componentMapper/FormLabel';
import FormControl from 'common/componentMapper/FormControl';
import FormErrorMessage from 'common/componentMapper/FormErrorMessage';
import GenericTooltip from 'common/components/GenericTooltip';
import { validationError } from '../utils';
import CalculationCard from 'common/components/CalculationCard';
import { NotApplicableOptions } from '../enum';
import LabelInput from './LabelInput';

const StyledRadio = styled(ChakraRadio)`
  margin-left: ${(props) => (props.index > 0 ? '1rem' : 0)};
`;

const LABEL_INPUT_PROPS_DEFAULT = {
  name: '',
  type: 'text',
  snippets: [],
  toggleInputOnSelection: false,
  focusInputOnSelection: false,
  optionsToRenderInput: [],
  optionLabel: '',
  hasExtraction: false,
  componentType: 'Input',
};

export default function Radio({
  name,
  sx,
  helperText,
  noteText,
  noteTextSx,
  renderAs,
  onChange,
  onFocus,
  isDisabled = false,
  labelInputProps = LABEL_INPUT_PROPS_DEFAULT,
  ...props
}) {
  const {
    label,
    options,
    input,
    isRequired,
    meta,
    validateOnMount,
    fcStyle,
    lbStyle,
    flStyle,
    parentId,
    styledRadioSx,
  } = useFieldApi({
    ...props,
    name,
  });

  const labelInputRef = useRef(null);
  const invalid = validationError(meta, validateOnMount);
  const text = invalid || ((meta.touched || validateOnMount) && meta.warning);
  const { focusInputOnSelection, optionsToRenderInput, type } = labelInputProps;

  const handleFocusOnSelection = (e) => {
    if (
      focusInputOnSelection &&
      optionsToRenderInput.includes(e) &&
      labelInputRef.current
    ) {
      type === 'date' //INFO: React DatePicker uses setFocus instead of focus
        ? labelInputRef.current.setFocus(true)
        : labelInputRef.current.focus();
    }
  };

  useEffect(() => {
    // maybe change this
    if (input) {
      input.onChange(`${input.value}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <FormControl
        id={name}
        isRequired={isRequired}
        isInvalid={!!invalid}
        sx={{ marginBottom: '1rem', ...fcStyle }}
        isDisabled={isDisabled}
      >
        {label && (
          <FormLabel
            sx={{ ...flStyle }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {label}
            {!!helperText && <GenericTooltip label={helperText} />}
          </FormLabel>
        )}
        {!!noteText && (
          <Text mb={'0.5rem'} sx={noteTextSx}>
            <i>{noteText}</i>
          </Text>
        )}
        <RadioGroup
          name={name}
          data-testid="radioGroup"
          defaultValue={meta.initial}
          {...input}
          onChange={(e) => {
            handleFocusOnSelection(e); //TODO: change which event this is triggered on
            input.onChange(e);
            onChange?.(e);
          }}
          onFocus={(e) => {
            input.onFocus(e);
            onFocus?.(e);
          }}
          id={parentId}
        >
          <HStack sx={{ ...sx }}>
            {options.map(({ label, value, ...rest }, i) => {
              if (renderAs === 'CalculationCard') {
                return (
                  <CalculationCard
                    key={`card-${i}`}
                    {...rest}
                    isChecked={input.value === value}
                    onClick={(e) => {
                      input.onChange(value);
                      onChange?.(e);
                    }}
                  />
                );
              }

              const showLabelInput = optionsToRenderInput?.includes(label);

              return (
                <>
                  <StyledRadio
                    key={`${i}-${label}`}
                    index={i}
                    value={value}
                    isInvalid={!!invalid}
                    isDisabled={
                      NotApplicableOptions.includes(label) || isDisabled
                    }
                    sx={{ ...styledRadioSx }}
                  >
                    <Text sx={{ ...lbStyle }}>{label}</Text>
                  </StyledRadio>

                  {showLabelInput && (
                    <LabelInput
                      ref={labelInputRef}
                      key={`labelInput-${i}`}
                      optionLabel={label}
                      selectedOption={input?.value}
                      onChange={input?.onChange}
                      {...labelInputProps}
                    />
                  )}
                </>
              );
            })}
          </HStack>
        </RadioGroup>
        {!!invalid && <FormErrorMessage>{text}</FormErrorMessage>}
      </FormControl>
    </>
  );
}

Radio.propTypes = {
  name: PropTypes.string,
  parentId: PropTypes.string,
  helperText: PropTypes.string,
  noteText: PropTypes.string,
  noteTextSx: PropTypes.object,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  renderAs: PropTypes.any,
  sx: PropTypes.object,
  styledRadioSx: PropTypes.object,
  labelInputProps: PropTypes.object,
  isDisabled: PropTypes.bool,
};
