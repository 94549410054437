import PropTypes from 'prop-types';
import DatePicker from 'common/components/DatePicker';

export const FB_DatePicker = ({
  name,
  label,
  placeholder,
  disabled,
  getValues,
  onChangeHandler,
  onBlur,
  isInvalid,
  tabIndex,
  dataTestId,
  isRequired,
}) => (
  <DatePicker
    name={name}
    label={label}
    value={getValues(name)}
    data-testid={dataTestId}
    placeholder={placeholder}
    tabIndex={tabIndex}
    isRequired={isRequired}
    isDisabled={disabled}
    error={isInvalid}
    onChange={onChangeHandler}
    onBlur={onBlur}
  />
);
FB_DatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  getValues: PropTypes.func,
  disabled: PropTypes.bool,
  onChangeHandler: PropTypes.func,
  onBlur: PropTypes.func,
  isInvalid: PropTypes.bool,
  tabIndex: PropTypes.number,
  dataTestId: PropTypes.string,
  isRequired: PropTypes.bool,
};
