import { combineReducers } from 'redux/es/redux.mjs';
import {
  url,
  loanInfo,
  validationQuestions,
  featureFlags,
  loanSummaryUpsertOrDelete,
  pdfCitation,
  pdfDocumentId,
} from '../common/redux/reducers';
import assets from '../pages/Assets/redux/reducers';
import { AuditHistoryReducer as auditHistory } from '../pages/AuditHistory/redux';
import { BoltApprovalReducer as boltApproval } from '../pages/BoltApproval/redux';
import { CitizenshipReducer as citizenship } from '../pages/Citizenship/redux';
import { CreditReducer as credit } from '../pages/Liabilities/redux';
import { DriverLicenseReducer as driverLicenseReviewInfo } from '../pages/DriverLicense/redux';
import { earlyClosingDisclosureReducer as earlyClosingDisclosure } from '../pages/EarlyClosingDisclosure/redux/reducers';
import { FinalChecksReducer as finalChecks } from '../pages/FinalReview/FinalChecks/redux';
import { FirstTimeHomeBuyerReducer as firstTimeHomeBuyer } from '../pages/FirstTimeHomeBuyer/redux';
import { HomeReducer as home } from '../pages/Home/redux';
import { IncomeCalculatorReducer as incomeCalculator } from '../pages/IncomeCalculator/redux';
import manualChecks from 'pages/FinalReview/ManualChecks/redux/reducers';
import { otherIncome, saveOtherIncome } from 'pages/OtherIncome/Redux/reducer';
import purchaseAgreement from '../pages/PurchaseAgreement/redux/reducers';
import { RentalIncomeDataReducer as rentalIncomeData } from '../pages/RentalIncome/redux';
import { RentalIncomeValidationReducer as rentalIncomeValidation } from '../pages/RentalIncome/RentalIncomeValidation/redux';
import { default as reo } from '../pages/REO/redux/reducers';
import { ThirdPartyCheckReducer as thirdPartyCheck } from '../pages/FinalReview/ThirdPartyChecks/redux';
import classifyDocumentsV2 from 'pages/ClassifyDocumentsV2/redux/reducers';
import snippets from 'common/components/SnippetViewer/redux/reducers';
import { insuranceReducer as insurance } from 'pages/Insurance/redux';
import feVerified from 'common/components/UnderwriterCheckbox/redux/reducers';
import titleCommitment from 'pages/TitlePackageReview/redux/reducers';
import { questionExtractionReducer as questionExtraction } from 'common/componentMapper/QuestionExtraction/redux/reducers';

// Keep this organized for convenience in Redux Devtools
const rootReducer = combineReducers({
  feVerified,
  url,
  validationQuestions,
  snippets,
  auditHistory,
  boltApproval,
  citizenship,
  classifyDocumentsV2,
  credit,
  driverLicenseReviewInfo,
  earlyClosingDisclosure,
  featureFlags,
  finalChecks,
  firstTimeHomeBuyer,
  home,
  incomeCalculator,
  loanInfo,
  manualChecks,
  otherIncome,
  purchaseAgreement,
  rentalIncomeData,
  rentalIncomeValidation,
  reo,
  thirdPartyCheck,
  saveOtherIncome,
  assets,
  insurance,
  pdfCitation,
  titleCommitment,
  questionExtraction,
  loanSummaryUpsertOrDelete,
  pdfDocumentId,
});

export default rootReducer;
