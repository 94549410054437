export const COMMON_TYPES = {
  // URL
  URL_SET_LOAN_PARAMS: 'URL@@SET_LOAN_PARAMS',
  URL_SET_ALL_PARAM: 'URL@@SET_ALL_PARAMS',
  URL_SET_ATTACH_PARAMS: 'URL@@SET_ATTACH_PARAMS',

  // LoanInfo
  GET_LOAN_INFO_STARTED: 'GET_LOAN_INFO_STARTED',
  GET_LOAN_INFO_SUCCESS: 'GET_LOAN_INFO_SUCCESS',
  GET_LOAN_INFO_ERROR: 'GET_LOAN_INFO_ERROR',

  // SaveQuestions
  SAVE_ANSWERS_STARTED: 'SAVE_ANSWERS_STARTED',
  SAVE_ANSWERS_SUCCESS: 'SAVE_ANSWERS_SUCCESS',
  SAVE_ANSWERS_ERROR: 'SAVE_ANSWERS_ERROR',

  // FeatureFlags
  SET_FEATURE_FLAG: 'SET_FEATURE_FLAG',
  GET_FEATURE_FLAGS_STARTED: 'GET_FEATURE_FLAGS_STARTED',
  GET_FEATURE_FLAGS_ERROR: 'GET_FEATURE_FLAGS_ERROR',

  // PDF Citation
  GET_PDF_CITATION: 'GET_PDF_CITATION',
  SET_PDF_CITATION: 'SET_PDF_CITATION',

  SET_PDF_DOCUMENT_ID: 'SET_PDF_DOCUMENT_ID',

  // LoanSummary
  SET_LOAN_SUMMARY_UPSERT_OR_DELETE: 'SET_LOAN_SUMMARY_UPSERT_OR_DELETE',
};

export default COMMON_TYPES;
