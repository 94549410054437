import PropTypes from 'prop-types';
import { UnnamedWidget } from 'lib-node-amb-renderer';

import UnorderedList from 'common/components/Lists/UnorderedList';
import ListItem from 'common/components/Lists/ListItem';
import Box from 'common/components/Box';
import styled from '@emotion/styled';

import { formatCurrency } from 'common/util/format';
import { useSelector } from 'react-redux';
import { LOAN_PRODUCT_TYPE_BY_NAME } from 'common/constants';

const GridTitleItem = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;
`;

const GridDataItem = styled.span`
  font-size: 14px;
`;

const formatAmount = (val) => {
  let amount = parseFloat(val).toFixed(2);
  return formatCurrency(amount);
};

const AssetSummaryHeaderStyle = styled(Box)`
  margin-bottom: 10px;
  min-height: 72px;
  border-bottom: ${(props) => `1px solid ${props.theme.colors?.gray[200]}`};
`;

const AssetSummaryListitem = styled(ListItem)`
  margin: 0.5rem 0 0.5rem 0.5rem;
`;

const defaultSx = {
  position: 'sticky',
  top: 0,
  width: '100%',
  zIndex: 100,
  background: 'white',
};

export class AssetSummaryHeaderClass extends UnnamedWidget {
  assetSummaryData;

  constructor(props) {
    super(props);
  }

  execute(data, props) {
    this.assetSummaryData = data?.assetsCalculationInformation;

    return super.execute(data, props);
  }
}

const AssetSummaryHeader = ({ assetSummaryData, sx }) => {
  const productType = useSelector(
    ({ home }) => home?.loanSummary?.value?.productType,
  );

  return (
    <Box sx={{ ...defaultSx, ...sx }}>
      <AssetSummaryHeaderStyle>
        <UnorderedList
          sx={{
            listStyle: 'none',
            display: 'flex',
            marginLeft: '10px',
            padding: '4px',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <AssetSummaryListitem id="cashToFromBorrower">
              <GridTitleItem>Cash to/from Borrower:</GridTitleItem>
              <GridDataItem>
                {formatAmount(assetSummaryData?.cashToFromBorrower)}
              </GridDataItem>
            </AssetSummaryListitem>
            <AssetSummaryListitem id="largeDepositThreshold">
              <GridTitleItem>Large Deposit Threshold:</GridTitleItem>
              <GridDataItem>
                {formatAmount(assetSummaryData?.largeDepositThreshold)}
              </GridDataItem>
            </AssetSummaryListitem>
            {productType === LOAN_PRODUCT_TYPE_BY_NAME.FHA && (
              <AssetSummaryListitem id="totalVerifiedFunds">
                <GridTitleItem>Total Verified Funds:</GridTitleItem>
                <GridDataItem>
                  {formatAmount(assetSummaryData?.totalVerifiedFunds)}
                </GridDataItem>
              </AssetSummaryListitem>
            )}
          </Box>
          <Box>
            <AssetSummaryListitem id="maxFundsAvailable">
              <GridTitleItem>Max Funds Available:</GridTitleItem>
              <GridDataItem>
                {formatAmount(assetSummaryData?.totalFundsAvailable)}
              </GridDataItem>
            </AssetSummaryListitem>
            <AssetSummaryListitem id="totalReservedRequired">
              <GridTitleItem>Total Reserves Required:</GridTitleItem>
              <GridDataItem>
                {formatAmount(assetSummaryData?.totalReservesRequired)}
              </GridDataItem>
            </AssetSummaryListitem>
          </Box>
        </UnorderedList>
      </AssetSummaryHeaderStyle>
    </Box>
  );
};

AssetSummaryHeader.propTypes = {
  assetSummaryData: PropTypes.object,
  sx: PropTypes.object,
};

export default AssetSummaryHeader;
