import { useFieldApi, FormSpy } from '@data-driven-forms/react-form-renderer';
import PropTypes from 'prop-types';
import ChakraInput from 'common/components/Inputs/Input';
import Tooltip from 'common/components/Tooltip';
import FormControl from 'common/componentMapper/FormControl';
import FormLabel from 'common/componentMapper/FormLabel';
import FormErrorMessage from 'common/componentMapper/FormErrorMessage';
import { PatternFormat } from 'react-number-format';
import { validationError } from '../utils';
import CurrencyInput from './Currency';
import DatePicker from 'common/components/DatePicker';
import GenericTooltip from 'common/components/GenericTooltip';
import TextArea from './TextArea';
import dayjs from 'dayjs';

import { useState, useEffect, forwardRef } from 'react';
import { handleParcelIdLabelChange } from './handlers';
import { formatCurrencyValue, formatDate } from 'common/util/format';

const InputSwitch = forwardRef(
  ({ type, input, onChange, meta, isRequired, ...rest }, ref) => {
    const handleChange = (e) => {
      input.onChange(e);
      onChange?.(e);
    };

    const invalid =
      validationError(meta, false) || (meta.invalid && meta.touched);
    const error = invalid ? meta.error || true : false;

    switch (type) {
      case 'currency':
        return (
          <CurrencyInput
            {...input}
            meta={meta}
            {...rest}
            onChange={handleChange}
            ref={ref}
          />
        );
      case 'text':
        return (
          <ChakraInput
            {...input}
            {...rest}
            tabIndex={rest.tabIndex}
            onChange={handleChange}
            defaultValue={meta.initial}
            type="text"
            ref={ref}
          />
        );
      case 'number':
        return (
          <ChakraInput
            {...input}
            {...rest}
            tabIndex={rest.tabIndex}
            onChange={handleChange}
            defaultValue={meta.initial}
            type="number"
            ref={ref}
          />
        );
      case 'email':
        return (
          <ChakraInput
            {...input}
            {...rest}
            tabIndex={rest.tabIndex}
            onChange={handleChange}
            defaultValue={meta.initial}
            type="email"
            ref={ref}
          />
        );
      case 'ssn':
        return (
          <PatternFormat
            format="###-##-####"
            mask="_"
            {...input}
            {...rest}
            tabIndex={rest.tabIndex}
            onChange={handleChange}
            defaultValue={meta.initial}
            customInput={ChakraInput}
            getInputRef={ref}
          />
        );
      case 'phone':
        return (
          <PatternFormat
            format="+1 (###) ###-####"
            mask="_"
            {...input}
            {...rest}
            tabIndex={rest.tabIndex}
            onChange={handleChange}
            defaultValue={meta.initial}
            customInput={ChakraInput}
            getInputRef={ref}
          />
        );
      case 'percentage':
        return (
          <PatternFormat
            format="##%"
            {...input}
            {...rest}
            tabIndex={rest.tabIndex}
            onChange={handleChange}
            defaultValue={meta.initial}
            customInput={ChakraInput}
            getInputRef={ref}
          />
        );
      case 'date':
        return (
          <DatePicker
            name={input.name}
            label={rest.label}
            placeholder={rest.placeholder}
            helperText={rest.helperText}
            placement={rest.placement}
            value={input.value}
            onChange={handleChange}
            onBlur={rest.onBlur}
            onFocus={rest.onFocus}
            tabIndex={rest.tabIndex}
            isRequired={isRequired}
            isDisabled={rest.isDisabled}
            error={error}
            sx={rest.sx}
            fcStyle={rest.fcStyle}
            inputRef={ref}
          />
        );
      case 'textarea':
        return (
          <TextArea
            {...input}
            {...rest}
            tabIndex={rest.tabIndex}
            onChange={handleChange}
            defaultValue={meta.initial}
            ref={ref}
          />
        );
      case 'hidden':
        return (
          <ChakraInput
            {...input}
            defaultValue={meta.initial}
            type="hidden"
            ref={ref}
          />
        );
      default:
        return (
          <ChakraInput
            {...input}
            {...rest}
            onChange={handleChange}
            tabIndex={rest.tabIndex}
            defaultValue={meta.initial}
            type="text"
            ref={ref}
          />
        );
    }
  },
);

InputSwitch.displayName = 'InputSwitch';

InputSwitch.propTypes = {
  type: PropTypes.oneOf([
    'currency',
    'text',
    'number',
    'email',
    'ssn',
    'phone',
    'percentage',
    'date',
    'textarea',
    'hidden',
  ]).isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    checked: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    active: PropTypes.bool,
    dirty: PropTypes.bool,
    error: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.node,
    ]),
    invalid: PropTypes.bool,
    touched: PropTypes.bool,
    initial: PropTypes.any,
  }).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  sx: PropTypes.object,
  fcStyle: PropTypes.object,
};

const Input = forwardRef(
  ({ formProps, helperText, showIsRequired, ...props }, ref) => {
    const {
      ariaLabel,
      label: labelFromInput,
      input,
      meta,
      validateOnMount,
      type,
      isInvalid,
      hidden,
      isRequired,
      sx,
      fcStyle = {},
      placeholder = '',
      isDisabled,
      disabled,
      maxLength,
      resize,
      showMaxLength,
      readonly,
      placement,
      maxWidth,
      role,
      showToolTip,
      onChange,
      onBlur,
      onFocus,
    } = useFieldApi({
      ...props,
    });
    const { name } = props;

    const [label, setLabel] = useState(labelFromInput);
    const [isEditing, setIsEditing] = useState(false);

    const invalid =
      validationError(meta, validateOnMount) || (meta.invalid && meta.touched);
    const errorText =
      ((meta.touched || validateOnMount) && meta.warning) ||
      isInvalid ||
      meta.error;

    // MUI components will have labels and form error texts in the component
    // Put here to skip placing them separately in the input component.
    const isMui = ['date'].includes(type);

    const showError = !(errorText === true || errorText === false);
    const valueLength = input.value?.toString().length;
    const tabIndex = isDisabled ? -1 : 0;
    const cutOffLength = 5;
    const showInputWithToolTip =
      showToolTip && valueLength > cutOffLength && !isEditing;
    const backgroundColor = sx?.backgroundColor ? sx.backgroundColor : 'white';

    const handleOnBlur = (e) => {
      input.onBlur();
      onBlur?.(e);
      setIsEditing(false);
    };

    const handleOnFocus = (e) => {
      setIsEditing(true);
      onFocus?.(e);
    };

    useEffect(() => {
      if (!input) {
        return;
      }

      if (type === 'date') {
        const date = dayjs(input.value);
        input.onChange(date.isValid() ? date.format('MM/DD/YYYY') : null);
        return;
      }

      input.onChange(`${input.value}`);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (showIsRequired === undefined && !label) {
      showIsRequired = false;
    }

    if (isMui) {
      return (
        <InputSwitch
          sx={{ ...sx, backgroundColor }}
          fcStyle={fcStyle}
          tabIndex={tabIndex}
          type={hidden ? 'hidden' : type}
          input={input}
          id={name}
          label={label}
          placeholder={placeholder}
          helperText={helperText}
          meta={meta}
          isRequired={isRequired && showIsRequired}
          isDisabled={isDisabled}
          maxLength={maxLength}
          placement={placement}
          maxWidth={maxWidth}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChange={onChange}
          ref={ref}
          ariaLabel={ariaLabel}
        />
      );
    }

    return (
      <FormSpy subscription={{ values: true }}>
        {({ values }) => {
          const isFirstParcelInput = labelFromInput?.includes('Parcel ID');
          isFirstParcelInput &&
            handleParcelIdLabelChange({ values, isFirstParcelInput, setLabel });
          return (
            <FormControl
              sx={{
                marginBottom: formProps?.mb ? '1rem' : 0,
                ...fcStyle,
              }}
              defaultValue={meta.initial}
              hidden={type === 'hidden'}
              isRequired={
                isRequired && (showIsRequired || showIsRequired === undefined)
              }
              isInvalid={!!invalid && !isDisabled && showError}
              role={role ? role : 'group'}
            >
              <FormLabel m={0} htmlFor={name}>
                {label}
                {helperText && <GenericTooltip label={helperText} />}
              </FormLabel>
              {showInputWithToolTip ? (
                <Tooltip
                  placement="top"
                  textAlign="center"
                  color="black"
                  bg="gray.200"
                  borderRadius="2px"
                  fontSize="12px"
                  fontWeight="normal"
                  p={2}
                  label={
                    type === 'currency'
                      ? formatCurrencyValue(input.value.toString())
                      : type === 'date'
                      ? formatDate(input.value.toString())
                      : input.value
                  }
                >
                  <span role="tooltip" aria-label={label ?? 'Information'}>
                    <InputSwitch
                      sx={{
                        ...sx,
                        backgroundColor,
                      }}
                      tabIndex={tabIndex}
                      type={hidden ? 'hidden' : type}
                      input={input}
                      id={name}
                      placeholder={placeholder}
                      meta={meta}
                      readOnly={readonly}
                      isDisabled={isDisabled || disabled}
                      maxLength={maxLength}
                      showMaxLength={showMaxLength}
                      resize={resize}
                      placement={placement}
                      maxWidth={maxWidth}
                      onFocus={handleOnFocus}
                      onBlur={handleOnBlur}
                      onChange={onChange}
                      ref={ref}
                      ariaLabel={ariaLabel}
                      role={role}
                    />
                  </span>
                </Tooltip>
              ) : (
                <InputSwitch
                  sx={{ ...sx, backgroundColor }}
                  tabIndex={tabIndex}
                  type={hidden ? 'hidden' : type}
                  input={input}
                  id={name}
                  placeholder={placeholder}
                  meta={meta}
                  readOnly={readonly}
                  isDisabled={isDisabled || disabled} // Need to standardize this
                  isInvalid={invalid}
                  maxLength={maxLength}
                  showMaxLength={showMaxLength}
                  resize={resize}
                  placement={placement}
                  maxWidth={maxWidth}
                  onFocus={handleOnFocus}
                  onBlur={handleOnBlur}
                  onChange={onChange}
                  ref={ref}
                  ariaLabel={ariaLabel}
                  role={role}
                />
              )}
              {showError && <FormErrorMessage>{errorText}</FormErrorMessage>}
            </FormControl>
          );
        }}
      </FormSpy>
    );
  },
);

Input.displayName = 'Input';

Input.propTypes = {
  type: InputSwitch.propTypes.type,
  helperText: PropTypes.string,
  formProps: PropTypes.object,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  showIsRequired: PropTypes.bool,
  showMaxLength: PropTypes.bool,
  showToolTip: PropTypes.bool,
  tabIndex: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  sx: PropTypes.object,
  fcStyle: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.any,
};

export default Input;
