import PropTypes from 'prop-types';
import { useFieldApi } from '@data-driven-forms/react-form-renderer';
import CheckboxWrapper from 'common/components/Checkboxes/CheckboxGroup';
import Checkbox from 'common/components/Checkboxes/Checkbox/Checkbox';
import FormControl from 'common/componentMapper/FormControl';
import FormLabel from 'common/componentMapper/FormLabel';
import FormErrorMessage from 'common/componentMapper/FormErrorMessage';
import { FormElementWidgetWithOptions } from 'lib-node-amb-renderer';
import { useState } from 'react';
import { useEffect } from 'react';
import Box from 'common/components/Box/index.js';

export class CheckboxGroupClass extends FormElementWidgetWithOptions {
  hasNone;
  noneName;

  constructor(props) {
    super(props);
    this.hasNone = props?.hasNone;
    this.noneName = props?.noneName;
  }
}

export const CheckboxGroup = ({
  name,
  label,
  hasNone,
  noneName = 'None',
  noneLabel = 'None',
  options = [],
  flStyle,
  sx = {},
  groupSx = {},
  ...props
}) => {
  const { input, meta, isRequired } = useFieldApi({
    ...props,
    name,
    isRequired: true,
  });

  const [checkboxAnswers, setCheckboxAnswers] = useState(input.value);
  const [isDisabled, setIsDisabled] = useState(false);
  const [noneDisabled, setNoneDisabled] = useState(false);

  const disableOtherCheckboxOptions = (isTrue) => {
    setNoneDisabled(!isTrue);
    setIsDisabled(isTrue);
  };

  const disableCondition = () => {
    let userResponse = Object.values(checkboxAnswers).map(
      (value) => value !== noneName,
    );

    let noneResponse = Object.values(checkboxAnswers).map(
      (value) => value === noneName,
    );

    if (userResponse.some((v) => v === true)) {
      disableOtherCheckboxOptions(false);
    } else if (noneResponse[0] === true) {
      disableOtherCheckboxOptions(true);
    } else {
      setNoneDisabled(false);
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    disableCondition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkboxAnswers]);

  const invalid = meta.touched && meta.invalid;
  const text = 'Required';

  return (
    <FormControl
      isRequired={isRequired}
      isInvalid={invalid}
      mb={'1rem'}
      sx={sx}
    >
      <FormLabel sx={{ ...flStyle }}>{label}</FormLabel>
      <CheckboxWrapper
        isInvalid={invalid}
        isRequired={isRequired}
        name={name}
        defaultValue={input.value}
      >
        <Box sx={groupSx} {...props}>
          {options.length &&
            options.map(({ label, value, name, sx }, i) => (
              <Checkbox
                isInvalid={invalid}
                key={`${i}-${label}`}
                value={value}
                name={name}
                sx={sx}
                isChecked={true}
                isDisabled={isDisabled}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  let userSelectionArray;
                  if (!isChecked) {
                    userSelectionArray = checkboxAnswers.filter(
                      (item) => item !== value,
                    );
                  } else {
                    userSelectionArray = [...checkboxAnswers, value];
                  }
                  setCheckboxAnswers(userSelectionArray);
                  input.onChange(userSelectionArray);
                }}
              >
                {label}
              </Checkbox>
            ))}

          {hasNone && (
            <Checkbox
              isDisabled={noneDisabled}
              isInvalid={invalid}
              name={noneName}
              value={noneName}
              onChange={(e) => {
                const isChecked = e.target.checked;
                let noneSelection;
                if (isChecked) {
                  noneSelection = [];
                  noneSelection = [...checkboxAnswers, noneName];
                } else {
                  noneSelection = [];
                }
                setCheckboxAnswers(noneSelection);
                input.onChange(noneSelection);
              }}
            >
              {noneLabel}
            </Checkbox>
          )}
        </Box>
      </CheckboxWrapper>
      {meta.invalid && <FormErrorMessage>{text}</FormErrorMessage>}
    </FormControl>
  );
};

CheckboxGroup.propTypes = {
  hasNone: PropTypes.string,
  noneName: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  noneLabel: PropTypes.string,
  flStyle: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  sx: PropTypes.object,
  groupSx: PropTypes.object,
};
export default CheckboxGroup;
