import InputGroup from 'common/components/Inputs/InputGroup';
import InputLeftElement from 'common/components/Inputs/InputLeftElement';
import styled from '@emotion/styled';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { pixelsToRem } from 'theme/typography';
import { forwardRef } from 'react';

const StyledNumberFormat = styled(NumericFormat)`
  width: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};
  &:focus-visible {
    outline: transparent;
    border: 'none';
  }
  &:disabled {
    background-color: ${(props) => props.theme.colors?.gray[100]} !important;
    cursor: not-allowed;
  }
`;

const Currency = forwardRef(
  (
    { onChange, sx, isDisabled, isInvalid, maxWidth, ariaLabel, ...props },
    ref,
  ) => {
    return (
      <InputGroup
        height="2.5rem"
        maxW={maxWidth ? maxWidth : '300px'}
        border="1px solid"
        borderColor="gray.200"
        borderRadius="5px"
        display="flex"
        flexDirection="row"
        backgroundColor="none"
        _focusWithin={{
          border: '2px solid',
          borderColor: 'blue.500',
          outline: 'transparent',
        }}
      >
        {/* eslint-disable-next-line react/no-children-prop */}
        <InputLeftElement
          left="0"
          top="0"
          pointerEvents="none"
          backgroundColor="gray.100"
          borderLeftRadius="5px"
          fontWeight="400"
          height="100%"
          width="50px"
          fontSize={pixelsToRem(16)}
          position="relative"
        >
          $
        </InputLeftElement>
        <StyledNumberFormat
          placeholder={'Enter an Amount'}
          {...props}
          getInputRef={ref}
          // eslint-disable-next-line react/prop-types
          defaultValue={props.meta.initial}
          thousandSeparator={true}
          inputMode="numeric"
          allowNegative={false}
          decimalScale={2}
          style={{ paddingLeft: sx?.paddingLeft ?? '1rem', ...sx }}
          fixedDecimalScale
          max={Number.MAX_SAFE_INTEGER}
          disabled={isDisabled}
          onValueChange={(values) => {
            onChange(values.floatValue);
          }}
          aria-label={ariaLabel}
          aria-invalid={isInvalid || undefined}
        />
      </InputGroup>
    );
  },
);

Currency.displayName = 'CurrencyWithRef';

Currency.propTypes = {
  onChange: PropTypes.func,
  sx: PropTypes.object,
  marginBottom: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  maxWidth: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export default Currency;
