import { useQuery } from '@tanstack/react-query';
import apiClient from 'common/util/api-client';
import { useSelector } from 'react-redux';
import apiEndPoints from 'common/constants/api-endpoints';
import { typedApiClient } from 'common/util/api-client';
import IncomeIdentifierDataModel from '../IncomeCalculator/model/IncomeIdentifierDataModel';

export function useGetAssetAuditHistory(assetId) {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url }) => url.obfuscatedLoanIdentifier,
  );
  return useQuery({
    queryKey: ['getAssetAuditHistoryData', assetId],
    queryFn: async () => {
      const { data } = await apiClient.post(
        apiEndPoints.auditHistory.GET_ASSET_AUDIT_HISTORY,
        {
          obfuscatedLoanIdentifier,
          assetId: +assetId,
        },
      );

      // this is subject to change, for the POC/Prototype this seemed to be good enough
      return data.entity;
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetAuditHistorySummary(pageEnum) {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url }) => url.obfuscatedLoanIdentifier,
  );
  return useQuery({
    queryKey: ['getAuditHistorySummaryData', pageEnum],
    queryFn: async () => {
      const { data } =
        await typedApiClient.v1.boltAuditGetBoltAuditSummaryScreen({
          obfuscatedLoanIdentifier,
          page: pageEnum,
        });

      // this is subject to change based on document type
      return data;
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetAuditHistory(pageEnum, assetId, entityEnum) {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url }) => url.obfuscatedLoanIdentifier,
  );

  return useQuery({
    queryKey: ['getAuditHistoryData'],
    queryFn: async () => {
      const requestBody = {
        obfuscatedLoanIdentifier,
        page: pageEnum,
      };

      if (entityEnum) {
        requestBody.entityReferenceAssociations = [
          {
            entity: entityEnum,
            references: {
              assetRecordId: assetId,
            },
          },
        ];
      }

      const { data } = await apiClient.post(
        apiEndPoints.auditHistory.GET_AUDIT_HISTORY_DATA,
        requestBody,
      );

      // this is subject to change, for the POC/Prototype this seemed to be good enough
      return data.entitiesAudit;
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetValidateDocumentAuditHistory(
  customerRecordId,
  employerId,
  documentId,
) {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url }) => url.obfuscatedLoanIdentifier,
  );
  const incomeIdentifier = new IncomeIdentifierDataModel({
    obfuscatedLenderDatabaseId:
      obfuscatedLoanIdentifier.obfuscatedLenderDatabaseId,
    customerRecordId: customerRecordId,
    employerId: employerId,
    sequenceNumber: 0,
  });

  return useQuery({
    queryKey: ['getValidateDocumentAuditHistoryData', documentId],
    queryFn: async () => {
      const { data } =
        await typedApiClient.v1.boltAuditGetValidateDocumentAudit({
          obfuscatedLoanIdentifier,
          incomeIdentifier,
          documentId: +documentId,
        });

      // this is subject to change based on document type
      return data.entity;
    },
    refetchOnWindowFocus: false,
  });
}

export function useGetIncomeCalculatorAuditHistory(
  customerRecordId,
  employerId,
  calculatorTypeId,
) {
  const obfuscatedLoanIdentifier = useSelector(
    ({ url }) => url.obfuscatedLoanIdentifier,
  );
  const incomeIdentifier = new IncomeIdentifierDataModel({
    obfuscatedLenderDatabaseId:
      obfuscatedLoanIdentifier.obfuscatedLenderDatabaseId,
    customerRecordId: customerRecordId,
    employerId: employerId,
    sequenceNumber: 0,
  });

  return useQuery({
    queryKey: ['getIncomeCalculatorAuditHistoryData', calculatorTypeId],
    queryFn: async () => {
      const { data } =
        await typedApiClient.v1.boltAuditGetIncomeCalculatorAudit({
          obfuscatedLoanIdentifier,
          incomeIdentifier,
          calculatorTypeId: +calculatorTypeId,
        });

      // this is subject to change based on document type
      return data.entity;
    },
    refetchOnWindowFocus: false,
  });
}
