import Home from './pages/Home';
import NotFound from './pages/NotFound';
import SummaryWrapper from 'common/components/Nav/SummaryWrapper/SummaryWrapper';
import KnowYourLoan from 'common/components/KnowYourLoan';
import {
  AuditHistory,
  AssetAuditHistory,
  AuditHistory1003Summary,
  ValidateDocumentAuditHistory,
  IncomeCalculatorAuditHistory,
} from 'pages/AuditHistory';
import IncomeWorksheet from 'pages/IncomeWorksheet';

const routes = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/:lenderDatabaseId/:loanRecordId',
    component: Home,
    exact: true,
  },
  {
    path: '/:lenderDatabaseId/:loanRecordId/audit-history/summary/:category/:pageEnum',
    render: (props) => {
      const { category } = props.match.params;
      return <AuditHistory1003Summary category={category} />;
    },
    isUnderwriterOnly: true,
    isAuditHistoryRouteEnabled: true,
  },
  {
    path: '/:lenderDatabaseId/:loanRecordId/summary',
    component: SummaryWrapper,
  },
  {
    path: '/:lenderDatabaseId/:loanRecordId/income-worksheet',
    component: IncomeWorksheet,
  },
  {
    path: '/:lenderDatabaseId/:loanRecordId/know-your-loan',
    component: KnowYourLoan,
    isUnderwriterOnly: true,
  },
  {
    path: '/:lenderDatabaseId/:loanRecordId/audit-history/assets/:assetId',
    component: AssetAuditHistory,
    isUnderwriterOnly: true,
    isAuditHistoryRouteEnabled: true,
  },
  {
    path: '/:lenderDatabaseId/:loanRecordId/audit-history/income/validateDocument/:customerRecordId/:employerId/:documentId',
    component: ValidateDocumentAuditHistory,
    isUnderwriterOnly: true,
    isAuditHistoryRouteEnabled: true,
  },
  {
    path: '/:lenderDatabaseId/:loanRecordId/audit-history/income/calculate/:customerRecordId/:employerId/:calculatorTypeId',
    component: IncomeCalculatorAuditHistory,
    isUnderwriterOnly: true,
    isAuditHistoryRouteEnabled: true,
  },
  {
    path: '/:lenderDatabaseId/:loanRecordId/audit-history/:pageEnum/:assetId/:entityEnum',
    component: AuditHistory,
    isUnderwriterOnly: true,
    isAuditHistoryRouteEnabled: true,
  },
  {
    path: '/:lenderDatabaseId/:loanRecordId/audit-history/:pageEnum',
    component: AuditHistory,
    isUnderwriterOnly: true,
    isAuditHistoryRouteEnabled: true,
  },
  {
    path: '/:lenderDatabaseId/:loanRecordId',
    component: Home,
  },
  {
    path: '*',
    component: NotFound,
  },
];

export default routes;
