import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import AppRenderer, { FormElementWidget } from 'lib-node-amb-renderer';
import componentMapper from 'common/componentMapper';
import GlobalLayout from 'common/components/GlobalLayout';

// eslint-disable-next-line import/no-unresolved
import layoutYaml from './mortgageHistoryLayout.yaml?raw';
import apiEndPoints from 'common/constants/api-endpoints';
import apiClient from 'common/util/api-client';
import { API_STATUS } from 'common/constants';
import FormTemplate from 'common/componentMapper/FormTemplate/FormTemplate';
import {
  REFI_PURPOSE_TYPE as refiPurposeType,
  LOAN_PURPOSE_TYPE as loanPurposeType,
  LIABILITY_TYPES as liabilityMatchTypes,
} from './utils';
import './styles.css';
import {
  useFieldApi,
  useFormApi,
} from '@data-driven-forms/react-form-renderer';
import { formatDate } from 'common/util/format';
import DatePicker from 'common/components/DatePicker';
import FormLabel from '../../common/components/Forms/FormLabel';

function CannotCloseBeforeField(props) {
  const { label, name } = props;
  const { input } = useFieldApi(props);
  const { getFieldState } = useFormApi();

  const originalNoteDate = getFieldState(
    input.name.replace('cannotCloseBefore', 'originalNoteDate'),
  )?.value;

  const value =
    originalNoteDate &&
    formatDate(
      new Date(originalNoteDate).setFullYear(
        new Date(originalNoteDate).getFullYear() + 1,
      ),
    );

  return (
    <div>
      <FormLabel m={0} htmlFor={name}>
        {label}
      </FormLabel>
      <DatePicker
        name={name}
        placeholder={value}
        onChange={() => {}}
        isDisabled
        sx={{
          maxWidth: '300px',
        }}
      />
    </div>
  );
}
CannotCloseBeforeField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

class CannotCloseBeforeFieldClass extends FormElementWidget {
  constructor(props) {
    super(props);
  }
}

const customComponents = {
  CannotCloseBeforeField: CannotCloseBeforeFieldClass,
};

export default function MortgageHistory() {
  const [data, setData] = useState(null);
  const [status, setStatus] = useState(API_STATUS.GETTING);

  const {
    obfuscatedLoanIdentifier,
    isUnderwriter,
    productType,
    ausMethod,
    loanPurpose,
    refiPurpose,
    totalLoanAmount,
  } = useSelector(
    ({
      url: { obfuscatedLoanIdentifier },
      home: {
        user: { isUnderwriter },
        loanSummary: {
          value: {
            productType,
            loanPurpose,
            refiPurpose,
            totalLoanAmount,
            ausMethod,
          },
        },
      },
    }) => {
      return {
        obfuscatedLoanIdentifier,
        isUnderwriter,
        productType,
        loanPurpose,
        refiPurpose,
        totalLoanAmount,
        ausMethod,
      };
    },
  );

  useEffect(() => {
    const refinancePurpose = refiPurpose
      ? refiPurpose
      : refiPurposeType.NOT_APPLICABLE;

    const isCashOut =
      refiPurpose === refiPurposeType.CASH_OUT_COLLEGE_EDUCATION ||
      refiPurpose === refiPurposeType.CASH_OUT_HOME_IMPROVEMENT ||
      refiPurpose === refiPurposeType.CASH_OUT_OTHER ||
      refiPurpose === refiPurposeType.CASH_OUT_DEBT_CONSOLIDATION;

    const screenDataFromStateAndTypes = {
      liabilityMatchTypes,
      isUnderwriter,
      productType,
      ausMethod,
      loanPurpose,
      isCashOut,
      refinancePurpose,
      loanPurposeType,
      refiPurposeType,
      totalLoanAmount,
    };

    apiClient
      .post(apiEndPoints.GET_MORTGAGE_HISTORY_QUESTION_ANSWERS, {
        ...obfuscatedLoanIdentifier,
      })
      .then(
        (res) =>
          setData((prev) => {
            return {
              ...prev,
              ...res?.data,
              screenData: {
                ...res?.data?.screenData,
                ...screenDataFromStateAndTypes,
                realEstateLiabilities:
                  res.data.screenData.realEstateLiabilities.map(
                    (liability) => ({
                      ...liability,
                      minimumSeasoningDate: new Date(
                        liability.minimumSeasoningDate,
                      ),
                    }),
                  ),
              },
            };
          }),
        setStatus(API_STATUS.DONE),
      )
      .catch(() => {
        setStatus(API_STATUS.ERROR);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveMortgageHistory = async (formValues) => {
    try {
      const { data: resData } = await apiClient.post(
        apiEndPoints.SAVE_MORTGAGE_HISTORY_ANSWER_DATA,
        {
          obfuscatedLoanIdentifier,
          mortgageHistoryQuestions: formValues,
          resultQuestionAnswerMetadata: {
            ...data?.screenData?.resultQuestionAnswerMetadata,
          },
        },
      );
      return resData;
    } catch {
      return false;
    }
  };

  const isLoading = status === API_STATUS.GETTING || !data;
  const isUpdating = status === API_STATUS.UPDATING;
  const isError = status === API_STATUS.ERROR;

  if (isLoading || isError) {
    return (
      <GlobalLayout
        heading="Mortgage History"
        primaryButton={{ name: 'Continue' }}
        isLoading={isLoading || isUpdating}
        error={
          // TODO: this might be better with a toast
          (isError && 'There was an issue loading data for FHA Requirements') ||
          undefined
        }
      />
    );
  }

  return (
    data &&
    productType &&
    ausMethod && (
      <AppRenderer
        onSubmit={saveMortgageHistory}
        data={data.screenData}
        markup={layoutYaml}
        componentMapper={{
          ...componentMapper,
          CannotCloseBeforeField,
        }}
        initialValues={data?.initialFormValues}
        FormTemplate={FormTemplate}
        customComponents={customComponents}
      />
    )
  );
}
