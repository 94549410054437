import { useAuditHistoryWindow } from 'common/hooks/useAuditHistoryWindow';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/pro-solid-svg-icons';
import Flex from 'common/components/Flex';
import { getAuditHistoryPageId } from './utils';
import AssetSummaryHeader from 'pages/Assets/AssetSummaryHeader/AssetSummaryHeader';
import { EMDAssetTypeArray } from 'pages/Assets/AssetDepositoryAccounts/enum';
import { Form1003PopUpButton } from 'pages/Loan1003DataGrids/Form1003PopUpButton';
import AISwitchToggle from 'common/components/Switch/SwitchAI/AISwitchToggle';
import Button from 'common/components/Button';
import { useIsUnderwriter } from 'common/util/appState';

export default function Header({
  title,
  isAiSwitchButtonEnabled = false,
  isAuditHistoryButtonEnabled = true,
}) {
  const { handleChangePageId } = useAuditHistoryWindow();
  const selectedAlert = useSelector(({ home }) => home.selectedAlert);
  const isUnderwriter = useIsUnderwriter();

  const assetType = useSelector(({ assets }) => assets?.assetType);
  const isDocumentProvided = useSelector(
    ({ assets }) => assets?.isDocumentProvided,
  );
  const assetsCalculationInformation = useSelector(
    ({ assets }) => assets?.data?.assetsCalculationInformation,
  );

  const { isAuditHistoryRouteEnabled, IsSwitchAIQuestions } = useSelector(
    ({ featureFlags }) => featureFlags,
  );

  const pageTitle = title || selectedAlert?.displayTitle;
  const displayTitle = pageTitle ? pageTitle : '';

  const handleClick = () => {
    const page = getAuditHistoryPageId(selectedAlert, assetType);
    handleChangePageId(page);
  };

  return (
    <>
      <Flex id="page-header" justifyContent="space-between" p={4} pl={8}>
        {displayTitle && (
          <h2 id="page-title" data-testid="page-title">
            {displayTitle}
          </h2>
        )}
        <Flex>
          <Form1003PopUpButton style={{ marginRight: '1rem' }} />
          {isAuditHistoryButtonEnabled &&
            isAuditHistoryRouteEnabled &&
            isUnderwriter && (
              <Button
                id="audit-history"
                data-testid="audit-history--icon"
                isPrimary={false}
                aria-label="Audit History"
                onClick={() => handleClick()}
                px={pageTitle !== undefined ?? 'unset'}
              >
                <FontAwesomeIcon icon={faHistory} />
              </Button>
            )}
          {IsSwitchAIQuestions && isAiSwitchButtonEnabled && <AISwitchToggle />}
        </Flex>
      </Flex>
      {!isDocumentProvided && EMDAssetTypeArray.includes(assetType) && (
        <AssetSummaryHeader assetSummaryData={assetsCalculationInformation} />
      )}
    </>
  );
}
Header.propTypes = {
  title: PropTypes.string,
  isAiSwitchButtonEnabled: PropTypes.bool,
  isAuditHistoryButtonEnabled: PropTypes.bool,
};
