import Button from 'common/components/Buttons/Button/index';
import Flex from 'common/components/Flex';
import Text from 'common/components/Texts/Text';
import { LOAN_PRODUCT_TYPE_BY_NAME } from 'common/constants';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { US_STATES_OPTIONS } from 'common/constants';
import {
  employmentIncomeTypeOptions,
  nonEmploymentIncomeTypeOptions,
  allEmploymentIncomeTypeOptions,
} from './income1003Constants';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setLoanSummaryUpsertOrDelete } from 'common/redux/actions';
import { useTheme } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { concatenateObjectAttributes } from 'common/components/CrudGrid/crudGridUtils';
import { typedApiClient } from 'common/util/api-client';
import { useState, useEffect } from 'react';
import IncomeSummariesFormCPASection from './Income1003FormCPASection';
import Loan1003FormContainer from './../../common/components/Loan1003FormContainer';
import Loan1003FormSection from './../../common/components/Loan1003FormSection';
import FormBuilder from 'pages/Loan1003DataGrids/common/components/FormBuilder';
import IncomeFieldArray from './IncomeFieldArray';
import Loan1003FormBuilderWrapper from 'pages/Loan1003DataGrids/common/components/Loan1003FormBuilderWrapper';
import { useMutation } from '@tanstack/react-query';

function calculateElapsedYearsMonths(dateStr1, dateStr2 = new Date()) {
  if (dateStr1 === '' || dateStr1 === null) {
    return { years: 0, months: 0 };
  }
  let date1 = new Date(dateStr1);
  let date2 =
    dateStr2 === '' || dateStr2 === null || dateStr2 === '1/1/0001'
      ? new Date()
      : new Date(dateStr2);
  if (date1 > date2) [date1, date2] = [date2, date1];

  let years = date2.getFullYear() - date1.getFullYear();
  let months = date2.getMonth() - date1.getMonth();

  if (months < 0 || (months === 0 && date2.getDate() < date1.getDate())) {
    years--;
    months += 12;
  }

  months = months % 12;

  if (isNaN(months)) months = undefined;
  if (isNaN(years)) years = undefined;

  return { years: years, months: months };
}

const getDefaultValues = (rowToEdit, borrower, ids, timeElapsed) => {
  return {
    borrowerName: rowToEdit.borrowerName,
    customerRecordId: rowToEdit.customerRecordId,
    currentEmployment: rowToEdit.currentEmployment,
    employerId: rowToEdit.employerId,
    lenderDatabaseId: rowToEdit.customerLenderDatabaseId,
    employmentType: rowToEdit.isW2Employment !== 2,
    borrowerSSN: borrower?.socialSecurityNumber,
    employerPhone: rowToEdit.employerPhone,
    employmentDetails: rowToEdit.monthlyIncomeDetails?.map((item) => {
      return {
        incomeIdentifier: {
          obfuscatedLenderDatabaseId: ids.obfuscatedLenderDatabaseId,
          customerRecordId: rowToEdit.customerRecordId,
          employerId: rowToEdit.employerId,
          sequenceNumber: item.sequenceNumber,
        },
        incomeType: getIncomeDetailsType(item.type),
        monthlyNetIncome: item.amount,
        period: 0,
        taxable: item.taxable,
        hoursPerWeek: item.hoursPerWeek,
        incomeVerifiedDate: item.incomeVerifiedDate,
        incomeVerifiedFlag: '',
      };
    }),
    employerName: rowToEdit.employerName,
    empByPartyToTranFlag: rowToEdit.empByPartyToTranFlag,
    primaryEmployment: rowToEdit.primaryEmployment,
    selfEmployed: rowToEdit.selfEmployed,
    percentBusinessOwned: rowToEdit.percentBusinessOwned,
    lessThan25PercentOwnershipFlag:
      rowToEdit.lessThan25PercentOwnershipFlag === 1,
    country: rowToEdit.country,
    employerAddress: {
      addressLine1: rowToEdit.employerAddress?.addressLine1,
      city: rowToEdit.employerAddress?.city,
      state: rowToEdit.employerAddress?.state,
      zipCode: rowToEdit.employerAddress?.zipCode,
    },
    title: rowToEdit.title,
    typeOfBusiness: rowToEdit.typeOfBusiness,
    startDate: rowToEdit.startDate,
    endDate: rowToEdit.endDate != '1/1/0001' ? rowToEdit.endDate : undefined,
    yearsOnJob: timeElapsed.years,
    monthsOnJob: timeElapsed.months,
    verifiedDate: rowToEdit.verifiedDate,
    cpaInformation: rowToEdit.cpaInformation?.map((item) => {
      return {
        customerCpaDetailId: item.customerCpaDetailId ?? 0,
        lenderDatabaseId: item.lenderDatabaseId,
        customerRecordId: item.customerRecordId,
        employerId: item.employerId,
        cpaFirstName: item.cpaFirstName,
        cpaMiddleName: item.cpaMiddleName,
        cpaLastName: item.cpaLastName,
        cpaCompanyName: item.cpaCompanyName,
      };
    }),
  };
};
const getIncomeDetailsType = (value, returnTypeCode = true) => {
  const options = allEmploymentIncomeTypeOptions.find((type) =>
    returnTypeCode ? type.type === value : type.typeCode === value,
  );
  return returnTypeCode ? options?.typeCode : options?.type;
};
export default function IncomeSummariesForm({
  rowToEdit,
  onClose,
  onSave,
  allBorrowerOptions,
  isWriteAccessDisabled,
  obfuscatedIds,
  handleToastMessage,
}) {
  const dispatch = useDispatch();
  const [showErrors, setShowErrors] = useState(false);
  const [showCPASection, setShowCPASection] = useState(
    rowToEdit.cpaInformation?.length > 0 &&
      rowToEdit.selfEmployed &&
      rowToEdit.employmentType !== 0,
  );

  const featureFlags = useSelector(({ featureFlags }) => featureFlags);
  const productType = useSelector(
    ({ home }) => home?.loanSummary?.value?.productType,
  );
  const yearsMonthsElapsed = calculateElapsedYearsMonths(
    rowToEdit.startDate,
    rowToEdit.endDate,
  );
  const currentBorrower = allBorrowerOptions.find(
    (borrower) => borrower.customerRecordId === rowToEdit.customerRecordId,
  );

  const {
    register,
    trigger,
    getValues,
    formState: { errors },
    clearErrors,
    reset,
    watch,
    setValue,
    handleSubmit,
    control,
  } = useForm({
    defaultValues: getDefaultValues(
      rowToEdit,
      currentBorrower,
      obfuscatedIds,
      yearsMonthsElapsed,
    ),
    mode: 'onBlur',
  });
  register('borrowerName');
  register('employerId');
  register('lenderDatabaseId');
  register('borrowerSSN');
  register('selfEmployed');
  register('customerRecordId', { required: true });
  register('currentEmployment');
  register('employmentType');
  register('employerName', { required: true });
  register('empByPartyToTranFlag');
  register('primaryEmployment');
  register('selfEmployed');
  register('percentBusinessOwned');
  register('employerAddress.addressLine1');
  register('employerAddress.city');
  register('employerAddress.state');
  register('employerAddress.zipCode');
  register('country');
  register('employerPhone');
  register('title');
  register('typeOfBusiness');
  register('startDate', {
    required:
      getValues('employmentType') || getValues('currentEmployment') === 'N',
  });
  register('endDate', {
    validate: (value) =>
      getValues('currentEmployment') === 'N' &&
      new Date(value) < new Date(watch('startDate'))
        ? 'End date must be later than start date'
        : true,
  });
  register('yearsOnJob');
  register('monthsOnJob');
  register('verifiedDate');
  register('cpaInformation');
  register('lessThan25PercentOwnershipFlag');

  const watchAll = watch();
  const selfEmployed = watch('selfEmployed');
  const employmentType = watch('employmentType');
  const currentEmployment = watch('currentEmployment');

  const isCurrentEmployment = currentEmployment === 'Y';
  const isIncomeSourceEmployment = employmentType === true;

  useEffect(() => {
    reset(
      getDefaultValues(
        rowToEdit,
        currentBorrower,
        obfuscatedIds,
        yearsMonthsElapsed,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowToEdit]);

  const theme = useTheme();

  const handleChange = (field, value) => {
    clearErrors([field, 'endDate']);
    switch (field) {
      case 'startDate':
      case 'endDate':
        handleStartEndDateChange(field, value);
        break;
      case 'customerRecordId':
        handleBorrowerNameChange(value);
        break;
      case 'percentBusinessOwned':
        setValue(
          'lessThan25PercentOwnershipFlag',
          value < 25 && value != 0 && value != '',
        );
        setValue('percentBusinessOwned', value);
        break;
      case 'employmentType':
        setValue(field, value);
        handleEmploymentTypeChange(value, getValues());
        break;
      case 'currentEmployment':
        handleCurrentEmploymentChange(value);
        break;
      default:
        setValue(field, value);
    }

    trigger();
  };

  const updateYearsAndMonthsOnJob = () => {
    const newElapsedTime = calculateElapsedYearsMonths(
      getValues('startDate'),
      getValues('endDate'),
    );
    setValue('yearsOnJob', newElapsedTime.years);
    setValue('monthsOnJob', newElapsedTime.months);
  };

  const handleCurrentEmploymentChange = (value) => {
    setValue('currentEmployment', value);
    setValue('endDate', null);
    updateYearsAndMonthsOnJob();
  };

  const closeCPASection = () => {
    const currentValues = getValues();
    reset({ ...currentValues, cpaInformation: [] });
    trigger();
    setShowCPASection(false);
  };

  const handleEmploymentTypeChange = (value, formData) => {
    if (!value) {
      reset({
        borrowerName: formData.borrowerName,
        customerRecordId: formData.customerRecordId,
        currentEmployment: formData.currentEmployment,
        employerId: formData.employerId,
        lenderDatabaseId: formData.customerLenderDatabaseId,
        employmentType: value,
        borrowerSSN: formData.socialSecurityNumber,
        employerPhone: '',
        employmentDetails: [{ ...newDetailsObject() }],
        employerName: formData.employerName,
        empByPartyToTranFlag: 0,
        primaryEmployment: formData.primaryEmployment,
        selfEmployed: false,
        percentBusinessOwned: '',
        country: '',
        employerAddress: {
          addressLine1: '',
          city: '',
          state: '',
          zipCode: '',
        },
        title: '',
        typeOfBusiness: '',
        startDate: '',
        endDate: '',
        yearsOnJob: 0,
        monthsOnJob: 0,
        verifiedDate: '',
        cpaInformation: [],
        lessThan25PercentOwnershipFlag: false,
      });
    }
  };

  const handleStartEndDateChange = (field, value) => {
    setValue(field, value);
    if (field === 'endDate') {
      setValue('currentEmployment', value ? 'N' : 'Y');
    }

    updateYearsAndMonthsOnJob();
  };

  const handleBorrowerNameChange = (customerId) => {
    const borrower = allBorrowerOptions.find(
      (obj) => obj.customerRecordId === +customerId,
    );
    setValue('borrowerName', {
      firstName: borrower.firstName,
      lastName: borrower.lastName,
      middleName: borrower.middleName ?? '',
      suffixName: borrower.suffixName ?? '',
    });
    setValue('borrowerSSN', borrower.socialSecurityNumber ?? null);
    setValue('customerRecordId', +customerId);
  };

  const { mutate: makeUpsertCall, isLoading } = useMutation({
    mutationFn: async (saveObject) => {
      return await typedApiClient.v1.underwritingSummaryIncomeUpsertUnderwritingSummaryIncome(
        saveObject,
      );
    },
    onSuccess: () => {
      onSave();
      handleToastMessage({
        title: 'Success',
        description: 'Saved Successfully',
        status: 'success',
        duration: 5000,
        isClosable: false,
      });
      dispatch(setLoanSummaryUpsertOrDelete(true));
    },
    onError: () => {
      handleToastMessage({
        title: 'Save Failed',
        description: 'Record was not able to be saved.',
        status: 'error',
        duration: 5000,
        isClosable: false,
      });
    },
  });

  const onSubmit = (data) => {
    let employmentDetailsArray = [];
    if (data.employmentDetails?.length > 0) {
      employmentDetailsArray = data.employmentDetails.map((detail, i) => {
        if (!detail.incomeIdentifier) {
          return {
            incomeIdentifier: {
              obfuscatedLenderDatabaseId:
                obfuscatedIds.obfuscatedLenderDatabaseId,
              customerRecordId: data.customerRecordId,
              employerId: detail.employerId ?? rowToEdit.employerId,
              sequenceNumber: i + 1 * 10,
            },
            incomeType: detail.incomeType,
            monthlyNetIncome: +detail.monthlyNetIncome,
            incomeVerifiedDate: detail.incomeVerifiedDate,
          };
        }
        return {
          ...detail,
          incomeIdentifier: {
            ...detail.incomeIdentifier,
            customerRecordId: data.customerRecordId,
          },
        };
      });
    }
    let cpaInformationArray = [];
    if (data.cpaInformation?.length > 0) {
      cpaInformationArray = data.cpaInformation.map((cpa) => {
        return {
          customerCpaDetailId: cpa.customerCpaDetailId ?? 0,
          lenderDatabaseId: cpa.lenderDatabaseId ?? 1,
          customerRecordId: data.customerRecordId,
          employerId: data.employerId,
          cpaFirstName: cpa.cpaFirstName,
          cpaMiddleName: cpa.cpaMiddleName,
          cpaLastName: cpa.cpaLastName,
          cpaCompanyName: cpa.cpaCompanyName,
        };
      });
    }

    const saveObject = {
      country: data.country ?? null,
      isCurrentEmployment: data.currentEmployment === 'Y',
      obfuscatedLoanIdentifier: obfuscatedIds,
      customerRecordIdOriginal:
        rowToEdit.customerRecordIdOriginal === data.customerRecordId
          ? null
          : rowToEdit.customerRecordIdOriginal,
      incomeIdentifier: {
        obfuscatedLenderDatabaseId: obfuscatedIds.obfuscatedLenderDatabaseId,
        customerRecordId: data.customerRecordId,
        employerId: data.employerId ?? 0,
      },
      empByPartyToTranFlag: data.empByPartyToTranFlag ?? 0,
      employerAddress: data.employerAddress ?? {
        addressLine1: '',
        city: '',
        state: '',
        zipCode: '',
      },
      employerName: data.employerName ?? null,
      employerPhone: data.employerPhone ?? '',
      employmentDetails: employmentDetailsArray,
      beginDate: data.startDate ?? '',
      endDate: data.endDate ?? null,
      percentageOwnership: data.percentBusinessOwned ?? '',
      primaryEmployment: data.primaryEmployment ? 'Y' : 'N',
      selfEmployed: data.selfEmployed,
      title: data.title ?? '',
      typeOfBusiness: data.typeOfBusiness ?? '',
      verifiedDate: data.verifiedDate ?? '',
      cpaInformation: showCPASection ? cpaInformationArray : [],
      isW2Employment: data.employmentType,
    };

    makeUpsertCall(saveObject);
  };

  const newDetailsObject = () => {
    return {
      incomeIdentifier: {
        obfuscatedLenderDatabaseId: obfuscatedIds.obfuscatedLenderDatabaseId,
        customerRecordId: rowToEdit.customerRecordId,
        employerId: rowToEdit.employerId,
        sequenceNumber: 0,
      },
      incomeType: '',
      monthlyNetIncome: null,
      period: 0,
      taxable: 0,
      hoursPerWeek: 0,
      incomeVerifiedDate: '',
      incomeVerifiedFlag: '',
    };
  };

  const incomeInformationFields = [
    {
      name: 'customerRecordId',
      type: 'select',
      label: 'Associated Borrower',
      placeholder: 'Select Borrower',
      value: 'customerRecordId',
      rowGap: '1.5rem',
      optionsAttributes: { optionsLabelName: 'label', optionsValueName: 'key' },
      onChangeHandler: (value) => {
        handleChange('customerRecordId', value);
      },
      options: allBorrowerOptions?.map((borrower) => {
        return {
          key: borrower.customerRecordId,
          label: concatenateObjectAttributes(borrower, [
            'firstName',
            'lastName',
          ]),
        };
      }),
      isRequired: true,
    },
    {
      name: 'currentEmployment',
      type: 'checkbox',
      label: 'Current',
      value: 'currentEmployment',
      isChecked: () => getValues('currentEmployment') === 'Y',
      onChangeHandler: (value) =>
        handleChange('currentEmployment', value === 'Y' ? 'N' : 'Y'),
    },
    {
      name: 'employmentType',
      value: 'employmentType',
      type: 'radio',
      label: 'Income Source',
      span: 2,
      colStart: 1,
      onChangeHandler: (value) => handleChange('employmentType', value),
      isChecked: (value) => {
        return value === getValues('employmentType');
      },
      options: [
        { label: 'Employment', value: true },
        { label: 'Non-Employment', value: false },
      ],
    },
  ];
  const employmentInfoFields = [
    {
      name: 'employerName',
      type: 'text',
      label: 'Employer Or Description',
      span: 2,
      value: 'employerName',
      onChangeHandler: (e) => handleChange('employerName', e.target.value),
      isRequired: true,
    },
    {
      type: 'checkbox',
      name: 'empByPartyToTranFlag',
      label:
        featureFlags.IncomeConvNonArmsLengthGuidelineEnabled &&
        productType === LOAN_PRODUCT_TYPE_BY_NAME.CONVENTIONAL
          ? 'Employed by Family or Interested Party'
          : 'Employed by Family',
      value: 'empByPartyToTranFlag',
      isHidden: () => !employmentType,
      isChecked: () => getValues('empByPartyToTranFlag') === 1,
      onChangeHandler: () =>
        handleChange(
          'empByPartyToTranFlag',
          getValues('empByPartyToTranFlag') === 1 ? 0 : 1,
        ),
    },
    {
      type: 'checkboxGroup',
      name: 'employmentType',
      label: 'Employment Type',
      colStart: 1,
      options: [
        { value: 'primaryEmployment', label: 'Primary' },
        {
          value: 'selfEmployed',
          label: 'Self-Employment',
          isHidden: () => !employmentType,
        },
      ],
      isChecked: (option) => getValues(option.value),
      onChangeHandler: (option) =>
        handleChange(option.value, !getValues(option.value)),
    },
    {
      name: 'percentBusinessOwned',
      type: 'inputWithPercentage',
      symbol: '%',
      label: '% Owned',
      value: 'percentBusinessOwned',
      onBlur: (value) => handleChange('percentBusinessOwned', value),
      isHidden: () => !selfEmployed || !employmentType,
      onChangeHandler: (value) => setValue('percentBusinessOwned', value),
    },
    {
      type: 'checkbox',
      name: 'lessThan25PercentOwnershipFlag',
      label: 'Less than 25% Ownership',
      value: 'lessThan25PercentOwnershipFlag',
      disabled: true,
      readOnly: true,
      isHidden: () => !selfEmployed || !employmentType,
      isChecked: () => getValues('lessThan25PercentOwnershipFlag'),
    },
    {
      type: 'text',
      name: 'employerAddress.addressLine1',
      label: 'Address Line 1',
      value: 'employerAddress.addressLine1',
      span: 2,
      colStart: 1,
      isHidden: () => !employmentType,
      onChangeHandler: (e) =>
        handleChange('employerAddress.addressLine1', e.target.value),
    },
    {
      type: 'text',
      name: 'employerAddress.city',
      label: 'City',
      value: 'employerAddress.city',
      colStart: 1,
      isHidden: () => !employmentType,
      onChangeHandler: (e) =>
        handleChange('employerAddress.city', e.target.value),
    },
    {
      type: 'select',
      name: 'employerAddress.state',
      label: 'State',
      value: 'employerAddress.state',
      optionsAttributes: { optionsLabelName: 'key' },
      options: US_STATES_OPTIONS,
      isHidden: () => !employmentType,
      onChangeHandler: (value) => handleChange('employerAddress.state', value),
    },
    {
      type: 'text',
      name: 'country',
      label: 'Country',
      value: 'country',
      isHidden: () => !employmentType,
      onChangeHandler: (e) => handleChange('country', e.target.value),
    },
    {
      type: 'text',
      name: 'employerAddress.zipCode',
      label: 'Zip Code',
      value: 'employerAddress.zipCode',
      isHidden: () => !employmentType,
      onChangeHandler: (e) =>
        handleChange('employerAddress.zipCode', e.target.value),
    },
    {
      type: 'text',
      name: 'title',
      label: 'Job Title',
      value: 'title',
      isHidden: () => !employmentType,
      onChangeHandler: (e) => handleChange('title', e.target.value),
    },
    {
      type: 'text',
      name: 'typeOfBusiness',
      label: 'Type of Business',
      value: 'typeOfBusiness',
      isHidden: () => !employmentType,
      onChangeHandler: (e) => handleChange('typeOfBusiness', e.target.value),
    },
    {
      type: 'patternedInput',
      name: 'employerPhone',
      label: 'Employer Home Phone',
      pattern: '(###) ###-####',
      value: 'employerPhone',
      isHidden: () => !employmentType,
      onChangeHandler: (value) => handleChange('employerPhone', value),
    },
    {
      type: 'datepicker',
      name: 'startDate',
      label: 'Start Date',
      value: 'startDate',
      isHidden: () => !employmentType && currentEmployment === 'Y',
      colStart: 1,
      onChangeHandler: (value) => handleChange('startDate', value),
      isRequired: !isCurrentEmployment || isIncomeSourceEmployment,
    },
    {
      type: 'datepicker',
      name: 'endDate',
      label: 'End Date',
      value: 'endDate',
      isHidden: () => !employmentType && currentEmployment === 'Y',
      onChangeHandler: (value) => handleChange('endDate', value),
      isRequired: !isCurrentEmployment,
    },
    {
      type: 'text',
      name: 'yearsOnJob',
      label: 'Years on Job',
      value: 'yearsOnJob',
      readOnly: true,
      isHidden: () => !employmentType,
    },
    {
      type: 'text',
      name: 'monthsOnJob',
      label: 'Months on Job',
      value: 'monthsOnJob',
      readOnly: true,
      isHidden: () => !employmentType,
    },
    {
      type: 'datepicker',
      name: 'verifiedDate',
      label: 'VVOE Date/VOB Date',
      value: 'verifiedDate',
      disabled: true,
      isHidden: () => !employmentType,
      onChangeHandler: (value) => handleChange('verifiedDate', value),
    },
  ];

  const incomeInformationFormDef = {
    fields: incomeInformationFields,
    columns: 4,
  };

  const employmentInfoFormDef = {
    fields: employmentInfoFields,
    columns: 4,
  };

  return (
    <Loan1003FormContainer
      isLoading={isLoading}
      onCloseClicked={() => onClose()}
      toggleErrors={() => setShowErrors(true)}
      onSaveClicked={handleSubmit(onSubmit)}
      readOnlyMode={isWriteAccessDisabled}
    >
      <Loan1003FormSection title="Income Information">
        <Loan1003FormBuilderWrapper>
          <FormBuilder
            fields={incomeInformationFields}
            formDef={incomeInformationFormDef}
            showErrors={showErrors}
            readOnlyMode={isWriteAccessDisabled}
            errors={errors}
            rowGap={'1.5rem'}
            getValues={getValues}
          />
          <IncomeFieldArray
            handleChange={handleChange}
            control={control}
            trigger={trigger}
            getValues={getValues}
            watchAll={watchAll}
            employmentIncomeTypeOptions={employmentIncomeTypeOptions}
            nonEmploymentIncomeTypeOptions={nonEmploymentIncomeTypeOptions}
            errors={errors}
            clearErrors={clearErrors}
            readOnlyMode={isWriteAccessDisabled}
            showErrors={showErrors}
            setValue={setValue}
            newAppendObject={newDetailsObject()}
            getIncomeDetailsType={getIncomeDetailsType}
            isHidden={getValues('currentEmployment') === 'N'}
          />
        </Loan1003FormBuilderWrapper>
      </Loan1003FormSection>
      <Loan1003FormSection
        title="Employment Information"
        customMarginTop="1.25rem"
      >
        <Loan1003FormBuilderWrapper>
          <FormBuilder
            fields={employmentInfoFields}
            formDef={employmentInfoFormDef}
            errors={errors}
            readOnlyMode={isWriteAccessDisabled}
            showErrors={showErrors}
            rowGap={'1.5rem'}
            getValues={getValues}
          />
          {watchAll.selfEmployed &&
            watchAll.employmentType != 0 &&
            currentEmployment === 'Y' && (
              <Flex boxSizing="border-box" justifyContent={'flex-start'}>
                <Button
                  background={'none'}
                  isPrimary={false}
                  disabled={isWriteAccessDisabled}
                  onClick={() => {
                    if (showCPASection) {
                      closeCPASection();
                    } else {
                      setShowCPASection(true);
                    }
                  }}
                  padding={'unset'}
                  sx={{
                    ':focus': {
                      boxShadow: 'none',
                    },
                  }}
                >
                  <Flex
                    align={'flex-start'}
                    justify={'space-around'}
                    wordBreak={'break'}
                  >
                    <FontAwesomeIcon
                      icon={!showCPASection ? faPlusSquare : faMinusSquare}
                      color={theme.colors?.blue[600]}
                    />
                    <Text paddingLeft={'1rem'}>
                      {showCPASection ? 'Remove' : 'Add'} CPA Information
                    </Text>
                  </Flex>
                </Button>
              </Flex>
            )}
        </Loan1003FormBuilderWrapper>
      </Loan1003FormSection>
      {showCPASection &&
        currentEmployment === 'Y' &&
        watchAll.employmentType != 0 &&
        watchAll.selfEmployed && (
          <Loan1003FormSection title="CPA Information">
            <IncomeSummariesFormCPASection
              control={control}
              handleChange={handleChange}
              trigger={trigger}
              readOnlyMode={isWriteAccessDisabled}
              closeCPASection={() => closeCPASection()}
            />
          </Loan1003FormSection>
        )}
    </Loan1003FormContainer>
  );
}
IncomeSummariesForm.propTypes = {
  rowToEdit: PropTypes.object,
  addNewIncomeTypeRow: PropTypes.func,
  deleteIncomeTypeRow: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  isEdit: PropTypes.bool,
  allBorrowerOptions: PropTypes.any,
  isWriteAccessDisabled: PropTypes.bool,
  obfuscatedIds: PropTypes.any,
  handleToastMessage: PropTypes.func,
};
