import { useParams } from 'react-router-dom';
import AuditHistoryAccordion from './AuditHistoryAccordion';
import { useGetIncomeCalculatorAuditHistory } from './hooks';
import Layout from './Layout';
import { AuditHistoryPageEnum } from './constants';

export default function IncomeCalculatorAuditHistory() {
  const { customerRecordId, employerId, calculatorTypeId } = useParams();

  const {
    data: auditHistory,
    isLoading,
    isError,
  } = useGetIncomeCalculatorAuditHistory(
    customerRecordId,
    employerId,
    calculatorTypeId,
  );

  return (
    <Layout
      pageEnum={AuditHistoryPageEnum.INCOME}
      isLoading={isLoading}
      isError={isError}
      isDataEmpty={!auditHistory?.length}
    >
      <AuditHistoryAccordion data={auditHistory} />
    </Layout>
  );
}
