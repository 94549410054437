import { useRef, useState } from 'react';

/**
 * @example ```jsx
    function ClickAuditHistory() {
      const { handleChangePageId } = useAuditHistoryWindow();
      const handleClick = (e) => {
        handleChangePageId(e.target.value);
      };
    
      return (
        <>
          <Box>current ruleId: {ruleId}</Box>
          <Button value="250" onClick={handleClick}>
            Open Audit History ruleId 250
          </Button>
          <Button
            sx={{ marginLeft: '1rem' }}
            isPrimary={false}
            value="/location/9000"
            onClick={handleClick}
          >
            Open Audit History at location/9000
          </Button>
        </>
      );
    }
```
 */
export function useAuditHistoryWindow(auditHistoryLocation = '') {
  const windowRef = useRef(null);
  const [pageId, setPageId] = useState(0);
  const basePath = auditHistoryLocation || window.location.pathname;
  const sanitizedBasePath = basePath.includes('/audit-history/')
    ? basePath
    : basePath.replace(/(\/|true)+$/, '') + '/audit-history/'; // remove trailing slashes

  function handleChangePageId(id) {
    if (!id) return;

    setPageId(id);

    if (!windowRef?.current?.history?.state) {
      windowRef.current = window.open(
        sanitizedBasePath + id,
        'window',
        `popup,width=1000,height=800`,
      );
      return;
    }

    windowRef.current.location.pathname = sanitizedBasePath + id;
  }

  return { pageId, handleChangePageId };
}
