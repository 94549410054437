import { isEmpty } from 'lodash';
import { RULE_RESULT_STATUS_ID } from 'common/constants';

/**
 * Returns the next alert based on the sort order.
 * @param alerts : array The alerts array sorted by parentRuleSortOrder.
 * @param currentAlert : object The current/selected alert.
 * @param isUnderwriter : bool Indicates if the user is an Underwriter
 * @returns object The next alert based on the sort order
 */

export const getNextAlert = (alerts, currentAlert, isUnderwriter) => {
  if (isEmpty(currentAlert)) {
    return alerts[0];
  }
  return (
    alerts?.find(
      (alert) =>
        (isUnderwriter ||
          alert?.ruleResultStatusId !== RULE_RESULT_STATUS_ID.CLEARED ||
          alert?.ruleResultStatusId !==
            RULE_RESULT_STATUS_ID.AUTOMATED_BOLT_SHIELD) &&
        alert?.parentRuleSortOrder > currentAlert?.parentRuleSortOrder,
    ) || false
  );
};

/**
 *
 * @param {[]} arr
 * @param {number} ruleId
 * @returns {[]} an array with items where the `ruleMetadata.parentRuleId` matches `ruleId`
 */
export const filterByParentRuleId = (arr, ruleId) => {
  if (!Array.isArray(arr) || !arr.length) return;
  if (!ruleId) return arr;

  return arr.filter(
    ({ ruleMetadata: { parentRuleId } }) => parentRuleId === ruleId,
  );
};
