import { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Global, css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { Box, Flex, Grid, Oops, Spinner, Stack, Text } from 'common/components';
import BreakdownView from 'pages/IncomeCalculator/CalculationHistory/HistoricalBreakdown/CalculationBreakdown';
import QualifyingIncomeWrapper from './QualifyingIncomeWrapper';
import { useGetCalculation, useGetNavigationDetails } from './hooks';
import {
  CalculationTypes,
  REQUEST_REFERENCE_TYPE,
} from 'pages/IncomeCalculator/enums';
import W2ResponseDataModel from 'pages/IncomeCalculator/model/W2/W2ResponseDataModel';
import GenericCalculationItemDataModel from 'pages/IncomeCalculator/model/GenericCalculationItemDataModel';
import WvoeResponseDataModel from 'pages/IncomeCalculator/model/Wvoe/WvoeResponseDataModel';
import { RULE_REFERENCE_TYPE_ID } from 'common/constants';
import { formatCurrency } from 'common/util/format';
import ErrorBoundary from 'pages/Home/ErrorBoundary';

const qualifyingCalcs = [
  CalculationTypes.QualifyingPaystub,
  CalculationTypes.QualifyingVoe,
];

function PrintHeader({ loanSummary }) {
  if (!loanSummary) {
    return null;
  }

  const borrowerName =
    loanSummary.primaryBorrowerFirstName +
    ' ' +
    loanSummary.primaryBorrowerLastName;

  return (
    <Grid
      borderBottomColor="gray.200"
      css={css`
        background-color: white;
        padding: 0.25rem 2.5rem;
        border-bottom: 1px solid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: space-between;
      `}
    >
      <Text as="h2" fontSize="sm">
        <b>Loan Number:</b> {loanSummary.loanNumber}
      </Text>
      <Text as="h2" fontSize="sm">
        <b>Borrower Name:</b> {borrowerName}
      </Text>
      <Text as="h2" fontSize="sm">
        <b>AUS Method: </b> {loanSummary.ausMethod}
      </Text>
      <Text as="h2" fontSize="sm">
        <b>Product Type: </b> {loanSummary.productType}
      </Text>
    </Grid>
  );
}
PrintHeader.propTypes = {
  loanSummary: PropTypes.shape({
    loanNumber: PropTypes.number,
    primaryBorrowerFirstName: PropTypes.string,
    primaryBorrowerLastName: PropTypes.string,
    ausMethod: PropTypes.string,
    productType: PropTypes.string,
  }),
};

export default function IncomeWorksheet() {
  return (
    <ErrorBoundary
      fallback={<Oops />}
      logPrefix="Error printing income worksheet: "
    >
      <IncomeWorksheetPage />
    </ErrorBoundary>
  );
}

export function IncomeWorksheetPage() {
  const { data, isFetching, isError, error } = useGetNavigationDetails();
  const { value: loanSummary, isLoading: fetchingLoanSummary } = useSelector(
    ({ home: { loanSummary } }) => loanSummary,
  );

  if (isFetching || fetchingLoanSummary) {
    return <Spinner loadingMessage="Loading, please wait" />;
  }

  if (isError) {
    throw new Error('Could not get list of relevant incomes: ' + error);
  }

  return (
    <>
      <Global
        styles={css`
          #boltHeader,
          footer {
            display: none;
          }
          * {
            line-height: 1;
          }
          h1 {
            font-size: 1.5rem;
          }
          h2 {
            font-size: 1.25rem;
          }
          h3 {
            font-size: 0.925rem;
          }
          h4 {
            font-size: 0.875rem;
          }
          p,
          dt,
          dd,
          td {
            font-size: 0.825rem;
          }
        `}
      />
      <PrintHeader loanSummary={loanSummary} />
      <Text as="h1" mx="2rem" mt="1rem">
        BOLT Income Worksheet
      </Text>
      <Flex m="2rem" mt="1rem" flexDirection="column" gap="1rem">
        {!data.length && (
          <Text>This loan does not have any valid income sources.</Text>
        )}
        {data?.map((gutterItem) => {
          const isEmployment =
            gutterItem.ruleReferenceTypeId ==
            RULE_REFERENCE_TYPE_ID.EMPLOYER_ID;
          const isRental =
            gutterItem.ruleReferenceTypeId == RULE_REFERENCE_TYPE_ID.ADDRESS_ID;

          return (
            <CalculationBreakdown
              key={gutterItem.gutterItemId}
              incomeIdentifier={
                isEmployment
                  ? {
                      obfuscatedLenderDatabaseId:
                        gutterItem.customerLenderDatabaseId,
                      customerRecordId: gutterItem.customerRecordId,
                      employerId: gutterItem.ruleReferenceId,
                    }
                  : undefined
              }
              rentalAddressId={
                isRental ? gutterItem.ruleReferenceId : undefined
              }
              requestReferenceType={
                isEmployment
                  ? REQUEST_REFERENCE_TYPE.employmentIncome
                  : REQUEST_REFERENCE_TYPE.rentalIncome
              }
              selectedCalculationId={
                +gutterItem.additionalReferences.CalculationId
              }
              incomeTitle={gutterItem.parentTitle}
            />
          );
        })}
      </Flex>
    </>
  );
}

export function CalculationBreakdown({
  requestReferenceType,
  incomeIdentifier,
  rentalAddressId,
  incomeTitle,
  selectedCalculationId,
}) {
  const { data, isFetching, isError, error } = useGetCalculation({
    requestReferenceType,
    incomeIdentifier,
    rentalAddressId,
  });

  const results = useMemo(
    () =>
      (data && [
        ...data.genericIncomeData.flatMap((x) =>
          x.calculationItems.map(
            (y) => new GenericCalculationItemDataModel({ ...x, ...y }),
          ),
        ),
        ...data.voeData.flatMap((x) =>
          x.calculationItems.map(
            (y) => new WvoeResponseDataModel({ ...x, ...y }),
          ),
        ),
        ...data.wageEarnerData.flatMap((x) =>
          x.calculationItems.map(
            (y) => new W2ResponseDataModel({ ...x, ...y }),
          ),
        ),
      ]) ||
      [],
    [data],
  );

  if (isFetching) {
    return <Spinner loadingMessage="Loading the calculation" />;
  }

  if (!isFetching && isError) {
    throw new Error(
      `Could not get calculation data: CRID=${incomeIdentifier?.customerRecordId} EID=${incomeIdentifier?.employerId} AID=${rentalAddressId}. Error: ${error}`,
    );
  }

  let selectedResult = results.filter(
    (result) =>
      result.isSelected || result.calculationId === selectedCalculationId,
  );

  if (!selectedResult.length && !!rentalAddressId) {
    selectedResult = results
      .sort((a, b) => b.calculationId - a.calculationId)
      .slice(0, 1);
  }

  const incomeType = selectedResult[0]?.incomeCalculatorType;

  return (
    <Box
      border="1px solid"
      borderColor="gray.200"
      bgColor="white"
      role="article"
      css={css`
        break-inside: avoid;
      `}
    >
      <Box role="heading" ml="0.875rem" my="1rem">
        <Text as="h2">{incomeTitle}</Text>
        {incomeType !== 'Miscellaneous' && <Text as="h3">{incomeType}</Text>}
      </Box>
      <Stack spacing={4} direction="column">
        {selectedResult.map((result) => (
          <SingleBreakdown
            results={selectedResult}
            result={result}
            incomeIdentifier={incomeIdentifier}
            key={result.calculationId}
          />
        ))}
      </Stack>
      <CommentBox comments={data.comment} />

      {!selectedResult.length && <Text px="1rem">No selected calculation</Text>}
    </Box>
  );
}

CalculationBreakdown.propTypes = {
  incomeIdentifier: PropTypes.shape({
    obfuscatedLenderDatabaseId: PropTypes.number,
    customerRecordId: PropTypes.number,
    employerId: PropTypes.number,
  }),
  requestReferenceType: PropTypes.oneOf([
    REQUEST_REFERENCE_TYPE.employmentIncome,
    REQUEST_REFERENCE_TYPE.rentalIncome,
  ]).isRequired,
  rentalAddressId: PropTypes.number,
  incomeTitle: PropTypes.string.isRequired,
  selectedCalculationId: PropTypes.number,
};

function SingleBreakdown({ results, result, incomeIdentifier }) {
  if (qualifyingCalcs.includes(result.calculatorTypeId)) {
    // This should be moved to the calculation breakdown component.
    return (
      <>
        <QualifyingIncomeWrapper incomeIdentifier={incomeIdentifier} />
        <IncomeOverrides overrideAmounts={result.overrideAmounts} />
      </>
    );
  }

  return (
    <>
      <BreakdownView
        onNewWindow={() => {}}
        results={results}
        selectedResult={result}
        hideAccordion
        incomeIdentifier={incomeIdentifier}
      />
      <IncomeOverrides overrideAmounts={result.overrideAmounts} />
    </>
  );
}
SingleBreakdown.propTypes = {
  results: PropTypes.array.isRequired,
  result: PropTypes.object.isRequired,
  incomeIdentifier: PropTypes.shape({
    obfuscatedLenderDatabaseId: PropTypes.number,
    customerRecordId: PropTypes.number,
    employerId: PropTypes.number,
  }),
};

function IncomeOverrides({ overrideAmounts }) {
  if (Object.values(overrideAmounts).every((amount) => !amount)) {
    return null;
  }

  const entries = Object.entries(overrideAmounts).filter((e) => !!e[1]);

  return (
    <Grid
      gridTemplateColumns="repeat(2, max-content)"
      margin="0 24px 12px 24px"
      rowGap="0.5rem"
      fontSize="0.875rem"
    >
      {entries.map(([key, amount]) => {
        const type = /overridden(\w+)Income/.exec(key)[1];

        return (
          <Fragment key={key}>
            <Text as="span" fontWeight="bold">
              Manually Updated {type === 'NetRental' ? 'Net Rental' : type}{' '}
              Income: &nbsp;
            </Text>

            <Text justifySelf="end">{formatCurrency(amount)}</Text>
          </Fragment>
        );
      })}
    </Grid>
  );
}
IncomeOverrides.propTypes = {
  overrideAmounts: PropTypes.shape({
    overriddenBaseIncome: PropTypes.number,
    overriddenBonusIncome: PropTypes.number,
    overriddenCommissionIncome: PropTypes.number,
    overriddenNetRentalIncome: PropTypes.number,
    overriddenOtherIncome: PropTypes.number,
    overriddenOvertimeIncome: PropTypes.number,
    overriddenSeasonalIncome: PropTypes.number,
    overriddenTipIncome: PropTypes.number,
  }).isRequired,
};

function CommentBox({ comments }) {
  if (!comments) {
    return null;
  }

  return (
    <Box
      margin="0 24px 12px 24px"
      paddingTop="12px"
      borderTop="1px solid"
      borderColor="gray.200"
    >
      <Text marginBottom="0.5rem" fontWeight="bold" fontSize="0.875rem">
        Comments:
      </Text>
      <Text fontSize="0.875rem">{comments}</Text>
    </Box>
  );
}
CommentBox.propTypes = {
  comments: PropTypes.string,
};
