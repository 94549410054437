import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { DesktopDatePicker as MUIDatePicker } from '@mui/x-date-pickers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormControl from 'common/components/Forms/FormControl';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import theme from 'theme/';
import FormLabel from './Forms/FormLabel';
import FormErrorMessage from './Forms/FormErrorMessage';
import GenericTooltip from 'common/components/GenericTooltip';

const CustomDatePicker = styled(MUIDatePicker)(() => ({
  '& .MuiInputBase-root': {
    height: '2.5rem', // Set the height to match your existing date picker
    backgroundColor: 'white', // Set the background color
    borderRadius: '4px', // Set the border radius
    width: '100%',
    padding: '0 10px', // Set padding
    '&:hover fieldset': {
      borderColor: theme.colors.gray[400],
    },

    '&.Mui-focused:hover fieldset': {
      borderColor: theme.colors.blue[600],
    },
    '&.Mui-error': {
      borderColor: theme.colors.red[600],
      boxShadow: '0 0 0 1px ' + theme.colors.red[600],

      '&.Mui-focused': {
        boxShadow: 'none',
      },

      '&:hover fieldset': {
        borderColor: theme.colors.red[600],
      },
    },
    '& .MuiInputBase-input': {
      height: '100%', // Ensure the input takes the full height
      padding: '0', // Remove default padding
      fontSize: '0.875rem', // Shrink the font size of the text in the input
    },
  },
  '& .MuiButtonBase-root': {
    color: 'black', // Set the color of the calendar icon

    '& .MuiSvgIcon-root': {
      fontSize: '1.25rem', // Set the size of the calendar icon
    },
  },
  '& .MuiButtonBase-root.Mui-disabled': {
    color: theme.colors.gray[400],
  },
}));

export default function DatePicker({
  name,
  label,
  helperText,
  placeholder,
  value,
  minDate,
  maxDate,
  error,
  ariaLabel,
  width = undefined,
  placement = 'right-start',
  tabIndex,
  isRequired = false,
  isDisabled = false,
  onChange,
  onBlur = () => {},
  onFocus,
  inputRef,
  sx = {},
  fcStyle = {},
}) {
  const dayjsValue = useMemo(() => (value ? dayjs(value) : null), [value]);
  const minDateDayJsValue = useMemo(
    () => (minDate ? dayjs(minDate) : null),
    [minDate],
  );
  const maxDateDayJsValue = useMemo(
    () => (maxDate ? dayjs(maxDate) : null),
    [maxDate],
  );

  const formatDateToString = (date) =>
    date ? date.format('MM/DD/YYYY') : null;

  const handleDateChange = (date) => {
    onChange(formatDateToString(date));
  };

  const handleDateBlur = (e) => {
    onBlur(e.target.value);
  };

  return (
    <FormControl
      id={name}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isInvalid={!!error}
      w={width}
      sx={fcStyle}
    >
      {label && (
        <FormLabel mb={0}>
          {label}
          {helperText && <GenericTooltip label={helperText} />}
        </FormLabel>
      )}
      <CustomDatePicker
        name={name}
        value={dayjsValue}
        onChange={handleDateChange}
        minDate={minDateDayJsValue}
        maxDate={maxDateDayJsValue}
        onBlur={handleDateBlur}
        slots={{
          openPickerIcon: CalendarMonthIcon,
        }}
        disabled={isDisabled}
        slotProps={{
          field: {
            sx: {
              width,
              ...sx,
            },
          },
          textField: {
            id: name,
            fullWidth: true,
            placeholder,
            onBlur: handleDateBlur,
            onFocus,
            error: !!error,
            inputRef,
            inputProps: {
              'aria-label': ariaLabel,
              tabIndex: tabIndex,
            },
          },
          popper: {
            placement,
            onBlur,
          },
          openPickerButton: {
            onFocus,
          },
        }}
      />
      {error && typeof error !== 'boolean' && (
        <FormErrorMessage>{error}</FormErrorMessage>
      )}
    </FormControl>
  );
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.node,
  ]),
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  width: PropTypes.string,
  sx: PropTypes.object,
  fcStyle: PropTypes.object,
  /** Choose where the date popover gets placed with respect to the text field. */
  placement: PropTypes.oneOf([
    'top',
    'bottom',
    'left',
    'right',
    'top-start',
    'top-end',
    'bottom-start',
    'bottom-end',
    'left-start',
    'left-end',
    'right-start',
    'right-end',
  ]),
  tabIndex: PropTypes.number,
  inputRef: PropTypes.shape({ current: PropTypes.any }),
};
