import PropTypes from 'prop-types';
import FormTemplate from './FormTemplate';
import Header from 'pages/AuditHistory/Header';

export default function FormTemplateWithAuditHistory({
  schema: { title } = {},
  isAiSwitchButtonEnabled = false,
  ...props
}) {
  return (
    <FormTemplate
      {...props}
      customHeader={
        <Header
          title={title}
          isAiSwitchButtonEnabled={isAiSwitchButtonEnabled}
        />
      }
    />
  );
}

FormTemplateWithAuditHistory.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string,
  }),
  isAiSwitchButtonEnabled: PropTypes.bool,
};
