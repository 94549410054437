import { useParams } from 'react-router-dom';
import AuditHistoryAccordion from './AuditHistoryAccordion';
import { useGetValidateDocumentAuditHistory } from './hooks';
import Layout from './Layout';
import { AuditHistoryPageEnum } from './constants';

export default function ValidateDocumentAuditHistory() {
  const { customerRecordId, employerId, documentId } = useParams();

  const {
    data: auditHistory,
    isLoading,
    isError,
  } = useGetValidateDocumentAuditHistory(
    customerRecordId,
    employerId,
    documentId,
  );

  return (
    <Layout
      pageEnum={AuditHistoryPageEnum.INCOME}
      isLoading={isLoading}
      isError={isError}
      isDataEmpty={!auditHistory?.length}
    >
      <AuditHistoryAccordion data={auditHistory} />
    </Layout>
  );
}
