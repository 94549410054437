import Input from 'common/components/Inputs/Input';
import Select from 'common/components/Selects/Select';
import Button from 'common/components/Buttons/Button/index';
import Box from 'common/components/Box';
import Flex from 'common/components/Flex';
import Text from 'common/components/Texts/Text';
import FormControl from 'common/components/Forms/FormControl';
import FormLabel from 'common/components/Forms/FormLabel';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useFieldArray, Controller } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import DatePicker from 'common/components/DatePicker';
import CurrencyInput from 'common/components/Inputs/CurrencyInput';
import FormBuilderErrorMessage from 'pages/Loan1003DataGrids/common/components/FormBuilder/FormBuilderErrorMessage';

const StyledFormControl = styled(FormControl)`
  position: relative;
  padding-right: 1.5rem;
  width: 25%;
`;
export default function IncomeFieldArray({
  handleChange,
  newAppendObject,
  control,
  trigger,
  getValues,
  watchAll,
  clearErrors,
  employmentIncomeTypeOptions,
  nonEmploymentIncomeTypeOptions,
  errors,
  showErrors,
  setValue,
  getIncomeDetailsType,
  isHidden,
  readOnlyMode,
}) {
  const { fields, append, remove } = useFieldArray({
    name: 'employmentDetails',
    control,
    shouldUnregister: false,
  });

  const theme = useTheme();

  const readOnlyStyle = {
    pointerEvents: 'none',
    opacity: 0.6,
    cursor: 'not-allowed',
  };

  const handleIncomeTypeChange = (field, value) => {
    clearErrors(field);
    if (!getValues('employmentType')) {
      setValue('employerName', getIncomeDetailsType(value, false));
      clearErrors('employerName');
    }
    setValue(field, value);
  };

  const handleMonthlyNetIncomeChange = (field, value) => {
    const newValue = value !== '' ? value : null;
    handleChange(field, newValue);
  };

  const handleEmploymentDetailsAppend = () => {
    append(newAppendObject);
  };

  if (isHidden) {
    return null;
  }

  const IncomeTypeOptions = () => {
    if (watchAll.employmentType) {
      return employmentIncomeTypeOptions.map((type, i) => (
        <option key={i} value={type.typeCode}>
          {type.type}
        </option>
      ));
    }
    return nonEmploymentIncomeTypeOptions.map((type, i) => (
      <option key={i} value={type.typeCode}>
        {type.type}
      </option>
    ));
  };

  const AddIncomeTypeButton = () => {
    // If it is not employment income, only allow for one income type i.e.
    // do not show the add income type button if employment detail present
    if (!watchAll.employmentType && watchAll.employmentDetails) {
      return null;
    }

    return (
      <Button
        background={'none'}
        marginTop={'0.5rem'}
        padding={0}
        isprimary="false"
        onClick={handleEmploymentDetailsAppend}
        tabIndex={readOnlyMode ? -1 : 0}
      >
        <Flex align={'center'} justify={'space-around'} wordBreak={'break'}>
          <FontAwesomeIcon
            icon={faPlusSquare}
            color={theme.colors?.blue[600]}
          />
          <Text paddingLeft={'1rem'}>Add Income Type</Text>
        </Flex>
      </Button>
    );
  };

  const isCurrentEmployment = watchAll.currentEmployment === 'Y';

  return (
    <Flex
      direction={'column'}
      justify={'flex-start'}
      style={readOnlyMode ? readOnlyStyle : {}}
    >
      {fields.map((field, index) => (
        <Flex
          width={'100%'}
          direction={'column'}
          marginBottom={'1rem'}
          key={field.id}
          align={'flex-end'}
          boxSizing="border-box"
          alignItems={'flex-start'}
        >
          <Flex
            width={'100%'}
            boxSizing="border-box"
            justifyContent={'flex-start'}
          >
            {/* HIDDEN INPUTS */}
            <Controller
              render={({ field }) => (
                <input
                  {...field}
                  type="hidden"
                  defaultValue={fields[index].incomeIdentifier}
                  value={fields[index].incomeIdentifier}
                />
              )}
              name={`employmentDetails.${index}.incomeIdentifier`}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <input
                  {...field}
                  type="hidden"
                  defaultValue={fields[index].period}
                  value={fields[index].period}
                />
              )}
              name={`employmentDetails.${index}.period`}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <input
                  {...field}
                  type="hidden"
                  defaultValue={fields[index].taxable}
                />
              )}
              name={`employmentDetails.${index}.taxable`}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <input
                  {...field}
                  type="hidden"
                  defaultValue={fields[index].hoursPerWeek}
                />
              )}
              name={`employmentDetails.${index}.hoursPerWeek`}
              control={control}
            />
            <Controller
              render={({ field }) => (
                <Input
                  {...field}
                  type="hidden"
                  defaultValue={fields[index].incomeVerifiedFlag}
                  value={fields[index].incomeVerifiedFlag}
                />
              )}
              name={`employmentDetails.${index}.incomeVerifiedFlag`}
              control={control}
            />
            {/* INCOME SOURCE TYPE DROPDOWN */}
            <StyledFormControl isRequired={isCurrentEmployment}>
              <FormLabel htmlFor={`incomeType-${field.id}`}>
                Income Type
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    style={{
                      backgroundColor: theme.colors?.white,
                    }}
                    isInvalid={
                      errors?.employmentDetails?.[index]?.incomeType &&
                      showErrors
                    }
                    value={getValues(`employmentDetails.${index}.incomeType`)}
                    placeholder={'Select'}
                    onChange={(e) =>
                      handleIncomeTypeChange(
                        `employmentDetails.${index}.incomeType`,
                        e.target.value,
                      )
                    }
                    tabIndex={readOnlyMode ? -1 : 0}
                  >
                    <IncomeTypeOptions />
                  </Select>
                )}
                name={`employmentDetails.${index}.incomeType`}
                rules={{ required: true }}
                control={control}
                defaultValue={fields[index].incomeType}
              />
            </StyledFormControl>
            {/* AMOUNT FOR INCOME SOURCE TYPES */}

            <StyledFormControl isRequired={isCurrentEmployment}>
              <FormLabel htmlFor={`monthlyNetIncome-${field.id}`}>
                Monthly Amount
              </FormLabel>
              <Controller
                render={({ field }) => (
                  <CurrencyInput
                    {...field}
                    allowNegative={true}
                    isInvalid={
                      errors?.employmentDetails?.[index]?.monthlyNetIncome &&
                      showErrors
                    }
                    value={getValues(
                      `employmentDetails.${index}.monthlyNetIncome`,
                    )}
                    tabIndex={readOnlyMode ? -1 : 0}
                    onChange={(e) => {
                      handleMonthlyNetIncomeChange(
                        `employmentDetails.${index}.monthlyNetIncome`,
                        e.target.value,
                      );
                    }}
                  />
                )}
                name={`employmentDetails.${index}.monthlyNetIncome`}
                rules={{
                  required: true,
                }}
                defaultValue={fields[index].monthlyNetIncome}
                control={control}
              />
            </StyledFormControl>
            {/* DOC DATE FOR INCOME SOURCE TYPES */}
            <StyledFormControl>
              <FormLabel htmlFor={`date-${field.id}`}>Doc Date</FormLabel>
              <Controller
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    value={getValues(
                      `employmentDetails.${index}.incomeVerifiedDate`,
                    )}
                    onChange={(e) =>
                      handleChange(
                        `employmentDetails.${index}.incomeVerifiedDate`,
                        new Date(e),
                      )
                    }
                    tabIndex={readOnlyMode ? -1 : 0}
                  />
                )}
                name={`employmentDetails.${index}.incomeVerifiedDate`}
                control={control}
                defaultValue={fields[index].incomeVerifiedDate}
              />
            </StyledFormControl>
            {/* DELETE BUTTON FOR INCOME SOURCE TYPE*/}
            <Button
              title="Delete Income"
              background={'none'}
              alignSelf={'flex-end'}
              padding={0}
              tabIndex={readOnlyMode ? -1 : 0}
              onClick={() => {
                remove(index);
                trigger();
              }}
            >
              <FontAwesomeIcon icon={faTrash} color={theme.colors?.red[600]} />
            </Button>
          </Flex>
          {errors?.employmentDetails?.[index] && (
            <Flex
              name={`employmentDetails.${index}.errorsRow`}
              width={'100%'}
              padding={'0rem 1rem'}
              boxSizing="border-box"
              justifyContent={'flex-start'}
            >
              <Box width={'25%'}>
                {errors?.employmentDetails?.[index]?.incomeType &&
                  showErrors && (
                    <FormBuilderErrorMessage
                      error={errors?.employmentDetails?.[index]?.incomeType}
                    />
                  )}
              </Box>
              <Box width={'25%'} padding={'0rem 1rem'}>
                {errors?.employmentDetails?.[index]?.monthlyNetIncome &&
                  showErrors && (
                    <FormBuilderErrorMessage
                      error={
                        errors?.employmentDetails?.[index]?.monthlyNetIncome
                      }
                    />
                  )}
              </Box>
            </Flex>
          )}
        </Flex>
      ))}
      <Flex
        align={'center'}
        boxSizing="border-box"
        justifyContent={'flex-start'}
        padding={0}
      >
        <AddIncomeTypeButton />
      </Flex>
    </Flex>
  );
}

IncomeFieldArray.propTypes = {
  handleChange: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  trigger: PropTypes.func.isRequired,
  newAppendObject: PropTypes.any,
  getValues: PropTypes.func.isRequired,
  watchAll: PropTypes.func.isRequired,
  employmentIncomeTypeOptions: PropTypes.array.isRequired,
  nonEmploymentIncomeTypeOptions: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  showErrors: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  getIncomeDetailsType: PropTypes.func.isRequired,
  clearErrors: PropTypes.func,
  isHidden: PropTypes.bool,
  readOnlyMode: PropTypes.bool,
};
