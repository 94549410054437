/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import axios from 'axios';
export var ContentType;
(function (ContentType) {
  ContentType['Json'] = 'application/json';
  ContentType['FormData'] = 'multipart/form-data';
  ContentType['UrlEncoded'] = 'application/x-www-form-urlencoded';
  ContentType['Text'] = 'text/plain';
})(ContentType || (ContentType = {}));
export class HttpClient {
  instance;
  securityData = null;
  securityWorker;
  secure;
  format;
  constructor({ securityWorker, secure, format, ...axiosConfig } = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '/api',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }
  setSecurityData = (data) => {
    this.securityData = data;
  };
  mergeRequestParams(params1, params2) {
    const method = params1.method || (params2 && params2.method);
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase()]) ||
          {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }
  stringifyFormItem(formItem) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }
  createFormData(input) {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent = property instanceof Array ? property : [property];
      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem),
        );
      }
      return formData;
    }, new FormData());
  }
  request = async ({ secure, path, type, query, format, body, ...params }) => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;
    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body);
    }
    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body);
    }
    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}
/**
 * @title UWMUDS API
 * @version 1.0
 * @baseUrl /api
 *
 * UWMUDS API
 */
export class Api extends HttpClient {
  v1 = {
    /**
     * No description
     *
     * @tags AccountInformation
     * @name AccountInformationGetAccountSummaryInformation
     * @request POST:/v1/AccountInformation/GetAccountSummaryInformation
     * @secure
     * @response `200` `any` OK
     */
    accountInformationGetAccountSummaryInformation: (data, params = {}) =>
      this.request({
        path: `/v1/AccountInformation/GetAccountSummaryInformation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags AccountReview
     * @name AccountReviewGetAccountReviewInformation
     * @request POST:/v1/AccountReview/GetAccountReviewInformation
     * @secure
     * @response `200` `any` OK
     */
    accountReviewGetAccountReviewInformation: (data, params = {}) =>
      this.request({
        path: `/v1/AccountReview/GetAccountReviewInformation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags AccountReviewSave
     * @name AccountReviewSaveSaveAccountReviewInformation
     * @request POST:/v1/AccountReviewSave/SaveAccountReviewInformation
     * @secure
     * @response `200` `any` OK
     */
    accountReviewSaveSaveAccountReviewInformation: (data, params = {}) =>
      this.request({
        path: `/v1/AccountReviewSave/SaveAccountReviewInformation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags AccountSummarySave
     * @name AccountSummarySaveSaveAccountSummaryInformation
     * @request POST:/v1/AccountSummarySave/SaveAccountSummaryInformation
     * @secure
     * @response `200` `any` OK
     */
    accountSummarySaveSaveAccountSummaryInformation: (data, params = {}) =>
      this.request({
        path: `/v1/AccountSummarySave/SaveAccountSummaryInformation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags AssetsInformation
     * @name AssetsInformationGetAssetsInformation
     * @request POST:/v1/AssetsInformation/GetAssetsInformation
     * @secure
     * @response `200` `(any)[]` OK
     */
    assetsInformationGetAssetsInformation: (data, params = {}) =>
      this.request({
        path: `/v1/AssetsInformation/GetAssetsInformation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags AssetsInformation
     * @name AssetsInformationGetAssetsSummaryInformation
     * @request POST:/v1/AssetsInformation/GetAssetsSummaryInformation
     * @secure
     * @response `200` `any` OK
     */
    assetsInformationGetAssetsSummaryInformation: (data, params = {}) =>
      this.request({
        path: `/v1/AssetsInformation/GetAssetsSummaryInformation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags AssetsInformation
     * @name AssetsInformationGetAssetsInformationForAssetSummary
     * @request POST:/v1/AssetsInformation/GetAssetsInformationForAssetSummary
     * @secure
     * @response `200` `any` OK
     */
    assetsInformationGetAssetsInformationForAssetSummary: (data, params = {}) =>
      this.request({
        path: `/v1/AssetsInformation/GetAssetsInformationForAssetSummary`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags AssetsInformation
     * @name AssetsInformationGetSingleAssetInformation
     * @request POST:/v1/AssetsInformation/GetSingleAssetInformation
     * @secure
     * @response `200` `any` OK
     */
    assetsInformationGetSingleAssetInformation: (data, params = {}) =>
      this.request({
        path: `/v1/AssetsInformation/GetSingleAssetInformation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags AssetsInformation
     * @name AssetsInformationDeleteAsset
     * @request POST:/v1/AssetsInformation/DeleteAsset
     * @secure
     * @response `200` `boolean` OK
     */
    assetsInformationDeleteAsset: (data, params = {}) =>
      this.request({
        path: `/v1/AssetsInformation/DeleteAsset`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags AssetsInformation
     * @name AssetsInformationBatchDeleteAssets
     * @request POST:/v1/AssetsInformation/BatchDeleteAssets
     * @secure
     * @response `200` `boolean` OK
     */
    assetsInformationBatchDeleteAssets: (data, params = {}) =>
      this.request({
        path: `/v1/AssetsInformation/BatchDeleteAssets`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags AssetsInformation
     * @name AssetsInformationGetAssetReconciliationInformation
     * @request POST:/v1/AssetsInformation/GetAssetReconciliationInformation
     * @secure
     * @response `200` `any` OK
     */
    assetsInformationGetAssetReconciliationInformation: (data, params = {}) =>
      this.request({
        path: `/v1/AssetsInformation/GetAssetReconciliationInformation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags AssetsInformation
     * @name AssetsInformationUpdateAdditionalReserves
     * @request POST:/v1/AssetsInformation/UpdateAdditionalReserves
     * @secure
     * @response `200` `any` OK
     */
    assetsInformationUpdateAdditionalReserves: (data, params = {}) =>
      this.request({
        path: `/v1/AssetsInformation/UpdateAdditionalReserves`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags AUS
     * @name AusRunAus
     * @request POST:/v1/AUS/RunAUS
     * @secure
     * @response `200` `any` OK
     */
    ausRunAus: (data, params = {}) =>
      this.request({
        path: `/v1/AUS/RunAUS`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags AUS
     * @name AusSkipAus
     * @request POST:/v1/AUS/SkipAUS
     * @secure
     * @response `200` `any` OK
     */
    ausSkipAus: (data, params = {}) =>
      this.request({
        path: `/v1/AUS/SkipAUS`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags AUS
     * @name AusGetAusRecommendation
     * @request POST:/v1/AUS/GetAUSRecommendation
     * @secure
     * @response `200` `any` OK
     */
    ausGetAusRecommendation: (data, params = {}) =>
      this.request({
        path: `/v1/AUS/GetAUSRecommendation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags BoltApproval
     * @name BoltApprovalGetBoltApprovalData
     * @request POST:/v1/BoltApproval/GetBoltApprovalData
     * @secure
     * @response `200` `any` OK
     */
    boltApprovalGetBoltApprovalData: (data, params = {}) =>
      this.request({
        path: `/v1/BoltApproval/GetBoltApprovalData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags BoltAudit
     * @name BoltAuditGetBoltAudit
     * @request POST:/v1/BoltAudit/GetBoltAudit
     * @secure
     * @response `200` `any` OK
     */
    boltAuditGetBoltAudit: (data, params = {}) =>
      this.request({
        path: `/v1/BoltAudit/GetBoltAudit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags BoltAudit
     * @name BoltAuditGetBoltAuditSummaryScreen
     * @request POST:/v1/BoltAudit/GetBoltAuditSummaryScreen
     * @secure
     * @response `200` `any` OK
     */
    boltAuditGetBoltAuditSummaryScreen: (data, params = {}) =>
      this.request({
        path: `/v1/BoltAudit/GetBoltAuditSummaryScreen`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags BoltAudit
     * @name BoltAuditGetValidateDocumentAudit
     * @request POST:/v1/BoltAudit/GetValidateDocumentAudit
     * @secure
     * @response `200` `any` OK
     */
    boltAuditGetValidateDocumentAudit: (data, params = {}) =>
      this.request({
        path: `/v1/BoltAudit/GetValidateDocumentAudit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags BoltAudit
     * @name BoltAuditGetIncomeCalculatorAudit
     * @request POST:/v1/BoltAudit/GetIncomeCalculatorAudit
     * @secure
     * @response `200` `any` OK
     */
    boltAuditGetIncomeCalculatorAudit: (data, params = {}) =>
      this.request({
        path: `/v1/BoltAudit/GetIncomeCalculatorAudit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags BoltHistory
     * @name BoltHistoryGetBoltAuditHistory
     * @request POST:/v1/BoltHistory/GetBoltAuditHistory
     * @secure
     * @response `200` `any` OK
     */
    boltHistoryGetBoltAuditHistory: (data, params = {}) =>
      this.request({
        path: `/v1/BoltHistory/GetBoltAuditHistory`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags BoltHistory
     * @name BoltHistoryIsAuditHistoryFeatureEnabled
     * @request POST:/v1/BoltHistory/IsAuditHistoryFeatureEnabled
     * @secure
     * @response `200` `boolean` OK
     */
    boltHistoryIsAuditHistoryFeatureEnabled: (data, params = {}) =>
      this.request({
        path: `/v1/BoltHistory/IsAuditHistoryFeatureEnabled`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags BoltStatus
     * @name BoltStatusGetBoltStatus
     * @request POST:/v1/BoltStatus/GetBoltStatus
     * @secure
     * @response `200` `any` OK
     */
    boltStatusGetBoltStatus: (data, params = {}) =>
      this.request({
        path: `/v1/BoltStatus/GetBoltStatus`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Borrower1003Validation
     * @name Borrower1003ValidationGetCitizenship
     * @request POST:/v1/Borrower1003Validation/GetCitizenship
     * @secure
     * @response `200` `any` OK
     */
    borrower1003ValidationGetCitizenship: (data, params = {}) =>
      this.request({
        path: `/v1/Borrower1003Validation/GetCitizenship`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Borrower1003Validation
     * @name Borrower1003ValidationSaveCitizenship
     * @request POST:/v1/Borrower1003Validation/SaveCitizenship
     * @secure
     * @response `200` `any` OK
     */
    borrower1003ValidationSaveCitizenship: (data, params = {}) =>
      this.request({
        path: `/v1/Borrower1003Validation/SaveCitizenship`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Borrower1003Validation
     * @name Borrower1003ValidationGetFirstTimeHomeBuyer
     * @request POST:/v1/Borrower1003Validation/GetFirstTimeHomeBuyer
     * @secure
     * @response `200` `any` OK
     */
    borrower1003ValidationGetFirstTimeHomeBuyer: (data, params = {}) =>
      this.request({
        path: `/v1/Borrower1003Validation/GetFirstTimeHomeBuyer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Borrower1003Validation
     * @name Borrower1003ValidationSaveFirstTimeHomeBuyer
     * @request POST:/v1/Borrower1003Validation/SaveFirstTimeHomeBuyer
     * @secure
     * @response `200` `any` OK
     */
    borrower1003ValidationSaveFirstTimeHomeBuyer: (data, params = {}) =>
      this.request({
        path: `/v1/Borrower1003Validation/SaveFirstTimeHomeBuyer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Borrower1003Validation
     * @name Borrower1003ValidationGetNonOccupyingCoBorrowerInfo
     * @request POST:/v1/Borrower1003Validation/GetNonOccupyingCoBorrowerInfo
     * @secure
     * @response `200` `any` OK
     */
    borrower1003ValidationGetNonOccupyingCoBorrowerInfo: (data, params = {}) =>
      this.request({
        path: `/v1/Borrower1003Validation/GetNonOccupyingCoBorrowerInfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Borrower1003Validation
     * @name Borrower1003ValidationSaveNonOccupyingCoBorrowerInfo
     * @request POST:/v1/Borrower1003Validation/SaveNonOccupyingCoBorrowerInfo
     * @secure
     * @response `200` `boolean` OK
     */
    borrower1003ValidationSaveNonOccupyingCoBorrowerInfo: (data, params = {}) =>
      this.request({
        path: `/v1/Borrower1003Validation/SaveNonOccupyingCoBorrowerInfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Borrower1003Validation
     * @name Borrower1003ValidationGetSubjectPropertyOccupancy
     * @request POST:/v1/Borrower1003Validation/GetSubjectPropertyOccupancy
     * @secure
     * @response `200` `any` OK
     */
    borrower1003ValidationGetSubjectPropertyOccupancy: (data, params = {}) =>
      this.request({
        path: `/v1/Borrower1003Validation/GetSubjectPropertyOccupancy`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Borrower1003Validation
     * @name Borrower1003ValidationSaveSubjectPropertyOccupancy
     * @request POST:/v1/Borrower1003Validation/SaveSubjectPropertyOccupancy
     * @secure
     * @response `200` `boolean` OK
     */
    borrower1003ValidationSaveSubjectPropertyOccupancy: (data, params = {}) =>
      this.request({
        path: `/v1/Borrower1003Validation/SaveSubjectPropertyOccupancy`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Checkpoint
     * @name CheckpointSendCheckpoint
     * @request POST:/v1/Checkpoint/SendCheckpoint
     * @secure
     * @response `200` `boolean` OK
     */
    checkpointSendCheckpoint: (data, params = {}) =>
      this.request({
        path: `/v1/Checkpoint/SendCheckpoint`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags ClassifyDocuments
     * @name ClassifyDocumentsGetReceivedMailItems
     * @request POST:/v1/ClassifyDocuments/GetReceivedMailItems
     * @secure
     * @response `200` `any` OK
     */
    classifyDocumentsGetReceivedMailItems: (data, params = {}) =>
      this.request({
        path: `/v1/ClassifyDocuments/GetReceivedMailItems`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags ClassifyDocuments
     * @name ClassifyDocumentsGetDocumentPdfDetail
     * @summary gets a pdf document
     * @request GET:/v1/ClassifyDocuments/GetDocumentPdf/{obfuscatedLenderDatabaseId}/{obfuscatedLoanRecordId}/{documentId}
     * @secure
     * @response `200` `any` OK
     */
    classifyDocumentsGetDocumentPdfDetail: (
      obfuscatedLenderDatabaseId,
      obfuscatedLoanRecordId,
      documentId,
      params = {},
    ) =>
      this.request({
        path: `/v1/ClassifyDocuments/GetDocumentPdf/${obfuscatedLenderDatabaseId}/${obfuscatedLoanRecordId}/${documentId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags ClassifyDocuments
     * @name ClassifyDocumentsUpdateDocumentClassification
     * @request POST:/v1/ClassifyDocuments/UpdateDocumentClassification
     * @secure
     * @response `200` `any` OK
     */
    classifyDocumentsUpdateDocumentClassification: (data, params = {}) =>
      this.request({
        path: `/v1/ClassifyDocuments/UpdateDocumentClassification`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags ClassifyDocuments
     * @name ClassifyDocumentsHideDocument
     * @request POST:/v1/ClassifyDocuments/HideDocument
     * @secure
     * @response `200` `any` OK
     */
    classifyDocumentsHideDocument: (data, params = {}) =>
      this.request({
        path: `/v1/ClassifyDocuments/HideDocument`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags ClassifyDocuments
     * @name ClassifyDocumentsGetAdrCompletionStatusExists
     * @request POST:/v1/ClassifyDocuments/GetAdrCompletionStatusExists
     * @secure
     * @response `200` `any` OK
     */
    classifyDocumentsGetAdrCompletionStatusExists: (data, params = {}) =>
      this.request({
        path: `/v1/ClassifyDocuments/GetAdrCompletionStatusExists`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags ClassifyDocuments
     * @name ClassifyDocumentsGetDocumentTypesAndCategoriesDetail
     * @summary Gets Document Types And Categories
     * @request GET:/v1/ClassifyDocuments/GetDocumentTypesAndCategories/{obfuscatedLenderDatabaseId}/{obfuscatedLoanRecordId}
     * @secure
     * @response `200` `any` OK
     */
    classifyDocumentsGetDocumentTypesAndCategoriesDetail: (
      obfuscatedLenderDatabaseId,
      obfuscatedLoanRecordId,
      params = {},
    ) =>
      this.request({
        path: `/v1/ClassifyDocuments/GetDocumentTypesAndCategories/${obfuscatedLenderDatabaseId}/${obfuscatedLoanRecordId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags ClassifyDocuments
     * @name ClassifyDocumentsUploadBoltApprovalLetter
     * @summary Uploads the BoltApproval Letter to Dochub
     * @request POST:/v1/ClassifyDocuments/UploadBoltApprovalLetter
     * @secure
     * @response `200` `any` OK
     */
    classifyDocumentsUploadBoltApprovalLetter: (data, params = {}) =>
      this.request({
        path: `/v1/ClassifyDocuments/UploadBoltApprovalLetter`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags ClassifyDocuments
     * @name ClassifyDocumentsGetDocumentTypeAssociations
     * @request POST:/v1/ClassifyDocuments/GetDocumentTypeAssociations
     * @secure
     * @response `200` `any` OK
     */
    classifyDocumentsGetDocumentTypeAssociations: (data, params = {}) =>
      this.request({
        path: `/v1/ClassifyDocuments/GetDocumentTypeAssociations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags ClassifyDocuments
     * @name ClassifyDocumentsGetClassifiedDocumentsForBatchProcessing
     * @request POST:/v1/ClassifyDocuments/GetClassifiedDocumentsForBatchProcessing
     * @secure
     * @response `200` `any` OK
     */
    classifyDocumentsGetClassifiedDocumentsForBatchProcessing: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/ClassifyDocuments/GetClassifiedDocumentsForBatchProcessing`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags CommunicationPreferences
     * @name CommunicationPreferencesGetCommunicationPreferences
     * @request POST:/v1/CommunicationPreferences/GetCommunicationPreferences
     * @secure
     * @response `200` `(any)[]` OK
     */
    communicationPreferencesGetCommunicationPreferences: (data, params = {}) =>
      this.request({
        path: `/v1/CommunicationPreferences/GetCommunicationPreferences`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Conditions
     * @name ConditionsGetLoanConditions
     * @request POST:/v1/Conditions/GetLoanConditions
     * @secure
     * @response `200` `any` OK
     */
    conditionsGetLoanConditions: (data, params = {}) =>
      this.request({
        path: `/v1/Conditions/GetLoanConditions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags CreditValidation
     * @name CreditValidationGetCreditValidationInfo
     * @request POST:/v1/CreditValidation/GetCreditValidationInfo
     * @secure
     * @response `200` `any` OK
     */
    creditValidationGetCreditValidationInfo: (data, params = {}) =>
      this.request({
        path: `/v1/CreditValidation/GetCreditValidationInfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags CreditValidation
     * @name CreditValidationUpdateCreditValidationData
     * @request POST:/v1/CreditValidation/UpdateCreditValidationData
     * @secure
     * @response `200` `any` OK
     */
    creditValidationUpdateCreditValidationData: (data, params = {}) =>
      this.request({
        path: `/v1/CreditValidation/UpdateCreditValidationData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags CreditValidation
     * @name CreditValidationGetCreditValidationResults
     * @request POST:/v1/CreditValidation/GetCreditValidationResults
     * @secure
     * @response `200` `any` OK
     */
    creditValidationGetCreditValidationResults: (data, params = {}) =>
      this.request({
        path: `/v1/CreditValidation/GetCreditValidationResults`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags CreditValidation
     * @name CreditValidationGetLatestCreditDocument
     * @request POST:/v1/CreditValidation/GetLatestCreditDocument
     * @secure
     * @response `200` `any` OK
     */
    creditValidationGetLatestCreditDocument: (data, params = {}) =>
      this.request({
        path: `/v1/CreditValidation/GetLatestCreditDocument`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags CreditValidation
     * @name CreditValidationGetMortgageHistoryQuestionAnswers
     * @request POST:/v1/CreditValidation/GetMortgageHistoryQuestionAnswers
     * @secure
     * @response `200` `any` OK
     */
    creditValidationGetMortgageHistoryQuestionAnswers: (data, params = {}) =>
      this.request({
        path: `/v1/CreditValidation/GetMortgageHistoryQuestionAnswers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags CreditValidation
     * @name CreditValidationSaveMortgageHistoryAnswerData
     * @request POST:/v1/CreditValidation/SaveMortgageHistoryAnswerData
     * @secure
     * @response `200` `any` OK
     */
    creditValidationSaveMortgageHistoryAnswerData: (data, params = {}) =>
      this.request({
        path: `/v1/CreditValidation/SaveMortgageHistoryAnswerData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags DocumentAssociation
     * @name DocumentAssociationSaveDocumentAssociations
     * @request POST:/v1/DocumentAssociation/SaveDocumentAssociations
     * @secure
     * @response `200` `DtoV1DocumentAssociationSuccessResponse` OK
     */
    documentAssociationSaveDocumentAssociations: (data, params = {}) =>
      this.request({
        path: `/v1/DocumentAssociation/SaveDocumentAssociations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags DocumentAssociation
     * @name DocumentAssociationSaveBatchDocumentAssociations
     * @request POST:/v1/DocumentAssociation/SaveBatchDocumentAssociations
     * @secure
     * @response `200` `DtoV1DocumentAssociationSuccessResponse` OK
     */
    documentAssociationSaveBatchDocumentAssociations: (data, params = {}) =>
      this.request({
        path: `/v1/DocumentAssociation/SaveBatchDocumentAssociations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags DocumentAssociation
     * @name DocumentAssociationGetDocumentAssociations
     * @request POST:/v1/DocumentAssociation/GetDocumentAssociations
     * @secure
     * @response `200` `any` OK
     */
    documentAssociationGetDocumentAssociations: (data, params = {}) =>
      this.request({
        path: `/v1/DocumentAssociation/GetDocumentAssociations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags DocumentAssociation
     * @name DocumentAssociationGetAssociatedDocumentsByIncome
     * @request POST:/v1/DocumentAssociation/GetAssociatedDocumentsByIncome
     * @secure
     * @response `200` `(any)[]` OK
     */
    documentAssociationGetAssociatedDocumentsByIncome: (data, params = {}) =>
      this.request({
        path: `/v1/DocumentAssociation/GetAssociatedDocumentsByIncome`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags DocumentExtraction
     * @name DocumentExtractionSnippetDetail
     * @summary Gets Document Snippet
     * @request GET:/v1/DocumentExtraction/Snippet/{obfuscatedLenderDatabaseId}/{obfuscatedLoanRecordId}/{snippetId}
     * @secure
     * @response `200` `any` OK
     */
    documentExtractionSnippetDetail: (
      obfuscatedLenderDatabaseId,
      obfuscatedLoanRecordId,
      snippetId,
      params = {},
    ) =>
      this.request({
        path: `/v1/DocumentExtraction/Snippet/${obfuscatedLenderDatabaseId}/${obfuscatedLoanRecordId}/${snippetId}`,
        method: 'GET',
        secure: true,
        ...params,
      }),
    /**
     * No description
     *
     * @tags DriversLicenseValidation
     * @name DriversLicenseValidationValidateDriversLicenseInfo
     * @request POST:/v1/DriversLicenseValidation/ValidateDriversLicenseInfo
     * @secure
     * @response `200` `boolean` OK
     */
    driversLicenseValidationValidateDriversLicenseInfo: (data, params = {}) =>
      this.request({
        path: `/v1/DriversLicenseValidation/ValidateDriversLicenseInfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags DriversLicenseValidation
     * @name DriversLicenseValidationGetDocumentSnippet
     * @request POST:/v1/DriversLicenseValidation/GetDocumentSnippet
     * @secure
     * @response `200` `any` OK
     */
    driversLicenseValidationGetDocumentSnippet: (data, params = {}) =>
      this.request({
        path: `/v1/DriversLicenseValidation/GetDocumentSnippet`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags DriversLicenseValidation
     * @name DriversLicenseValidationSaveBorrower
     * @request POST:/v1/DriversLicenseValidation/SaveBorrower
     * @secure
     * @response `200` `boolean` OK
     */
    driversLicenseValidationSaveBorrower: (data, params = {}) =>
      this.request({
        path: `/v1/DriversLicenseValidation/SaveBorrower`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags DriversLicenseValidation
     * @name DriversLicenseValidationGetBorrowersDriversLicenseData
     * @request POST:/v1/DriversLicenseValidation/GetBorrowersDriversLicenseData
     * @secure
     * @response `200` `(any)[]` OK
     */
    driversLicenseValidationGetBorrowersDriversLicenseData: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/DriversLicenseValidation/GetBorrowersDriversLicenseData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncome
     * @name EmploymentIncomeGetEmploymentQuestions
     * @summary Gets questions related to employment and income that do not trigger any conditions
     * @request POST:/v1/EmploymentIncome/GetEmploymentQuestions
     * @deprecated
     * @secure
     * @response `200` `(any)[]` OK
     */
    employmentIncomeGetEmploymentQuestions: (data, params = {}) =>
      this.request({
        path: `/v1/EmploymentIncome/GetEmploymentQuestions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncome
     * @name EmploymentIncomeSaveEmploymentAnswer
     * @summary Saves Employment related income answers
     * @request POST:/v1/EmploymentIncome/SaveEmploymentAnswer
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeSaveEmploymentAnswer: (data, params = {}) =>
      this.request({
        path: `/v1/EmploymentIncome/SaveEmploymentAnswer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncome
     * @name EmploymentIncomeGetAnsweredQuestions
     * @summary Gets the previously answered questions
     * @request POST:/v1/EmploymentIncome/GetAnsweredQuestions
     * @deprecated
     * @secure
     * @response `200` `(any)[]` OK
     */
    employmentIncomeGetAnsweredQuestions: (data, params = {}) =>
      this.request({
        path: `/v1/EmploymentIncome/GetAnsweredQuestions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncome
     * @name EmploymentIncomeGetCustomerEmploymentDetail
     * @summary Gets customer employment data
     * @request POST:/v1/EmploymentIncome/GetCustomerEmploymentDetail
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeGetCustomerEmploymentDetail: (data, params = {}) =>
      this.request({
        path: `/v1/EmploymentIncome/GetCustomerEmploymentDetail`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncome
     * @name EmploymentIncomeGetEmploymentQuestionsAndAnswers
     * @summary Gets the previously answered questions
     * @request POST:/v1/EmploymentIncome/GetEmploymentQuestionsAndAnswers
     * @secure
     * @response `200` `(any)[]` OK
     */
    employmentIncomeGetEmploymentQuestionsAndAnswers: (data, params = {}) =>
      this.request({
        path: `/v1/EmploymentIncome/GetEmploymentQuestionsAndAnswers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncome
     * @name EmploymentIncomeRemoveIncomeAndEmploymentInfo
     * @summary Delete specified employment income
     * @request POST:/v1/EmploymentIncome/RemoveIncomeAndEmploymentInfo
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeRemoveIncomeAndEmploymentInfo: (data, params = {}) =>
      this.request({
        path: `/v1/EmploymentIncome/RemoveIncomeAndEmploymentInfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncome
     * @name EmploymentIncomeGetNoDocumentsProvidedQuestionAnswers
     * @summary Gets the screen data and initial values for the no income documents provided page
     * @request POST:/v1/EmploymentIncome/GetNoDocumentsProvidedQuestionAnswers
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeGetNoDocumentsProvidedQuestionAnswers: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/EmploymentIncome/GetNoDocumentsProvidedQuestionAnswers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncomeValidation
     * @name EmploymentIncomeValidationGetEmploymentIncomeValidationData
     * @request POST:/v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationData
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeValidationGetEmploymentIncomeValidationData: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncomeValidation
     * @name EmploymentIncomeValidationGetEmploymentIncomeValidationDataWithFieldResolution
     * @request POST:/v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataWithFieldResolution
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeValidationGetEmploymentIncomeValidationDataWithFieldResolution:
      (data, params = {}) =>
        this.request({
          path: `/v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataWithFieldResolution`,
          method: 'POST',
          body: data,
          secure: true,
          type: ContentType.Json,
          format: 'json',
          ...params,
        }),
    /**
     * No description
     *
     * @tags EmploymentIncomeValidation
     * @name EmploymentIncomeValidationGetEmploymentIncomeValidationDataW2
     * @request POST:/v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataW2
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeValidationGetEmploymentIncomeValidationDataW2: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataW2`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncomeValidation
     * @name EmploymentIncomeValidationGetEmploymentIncomeValidationDataPaystub
     * @request POST:/v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataPaystub
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeValidationGetEmploymentIncomeValidationDataPaystub: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataPaystub`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncomeValidation
     * @name EmploymentIncomeValidationGetEmploymentIncomeValidationDataScheduleC
     * @request POST:/v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataScheduleC
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeValidationGetEmploymentIncomeValidationDataScheduleC: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataScheduleC`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncomeValidation
     * @name EmploymentIncomeValidationGetEmploymentIncomeValidationDataBankStatement
     * @request POST:/v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataBankStatement
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeValidationGetEmploymentIncomeValidationDataBankStatement: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataBankStatement`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncomeValidation
     * @name EmploymentIncomeValidationGetEmploymentIncomeValidationDataAwardLetter
     * @request POST:/v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataAwardLetter
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeValidationGetEmploymentIncomeValidationDataAwardLetter: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/EmploymentIncomeValidation/GetEmploymentIncomeValidationDataAwardLetter`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncomeValidation
     * @name EmploymentIncomeValidationGetEmploymentReviewData
     * @summary Returns the list of rule results and questions for the employment review page.
     * @request POST:/v1/EmploymentIncomeValidation/GetEmploymentReviewData
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeValidationGetEmploymentReviewData: (data, params = {}) =>
      this.request({
        path: `/v1/EmploymentIncomeValidation/GetEmploymentReviewData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncomeValidation
     * @name EmploymentIncomeValidationUpdateEmploymentAndQuestionAnswers
     * @request POST:/v1/EmploymentIncomeValidation/UpdateEmploymentAndQuestionAnswers
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeValidationUpdateEmploymentAndQuestionAnswers: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/EmploymentIncomeValidation/UpdateEmploymentAndQuestionAnswers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncomeValidation
     * @name EmploymentIncomeValidationUpdateEmploymentSmartQuestionAnswers
     * @request POST:/v1/EmploymentIncomeValidation/UpdateEmploymentSmartQuestionAnswers
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeValidationUpdateEmploymentSmartQuestionAnswers: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/EmploymentIncomeValidation/UpdateEmploymentSmartQuestionAnswers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncomeValidation
     * @name EmploymentIncomeValidationUpdateEmploymentReviewAnswers
     * @summary Save or update the question set responses for the employment review page.
     * @request POST:/v1/EmploymentIncomeValidation/UpdateEmploymentReviewAnswers
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeValidationUpdateEmploymentReviewAnswers: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/EmploymentIncomeValidation/UpdateEmploymentReviewAnswers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncomeValidation
     * @name EmploymentIncomeValidationInsertCpaInformation
     * @summary Inserts a collection of CPA Information to be saved
     * @request POST:/v1/EmploymentIncomeValidation/InsertCpaInformation
     * @secure
     * @response `200` `boolean` OK
     */
    employmentIncomeValidationInsertCpaInformation: (data, params = {}) =>
      this.request({
        path: `/v1/EmploymentIncomeValidation/InsertCpaInformation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncomeValidation
     * @name EmploymentIncomeValidationUpdateEmploymentReviewDateAnswers
     * @summary Save or update a date response for the employment review page.
     * @request POST:/v1/EmploymentIncomeValidation/UpdateEmploymentReviewDateAnswers
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeValidationUpdateEmploymentReviewDateAnswers: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/EmploymentIncomeValidation/UpdateEmploymentReviewDateAnswers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags EmploymentIncomeValidation
     * @name EmploymentIncomeValidationGetValidateSmartQuestionAnswers
     * @request POST:/v1/EmploymentIncomeValidation/GetValidateSmartQuestionAnswers
     * @secure
     * @response `200` `any` OK
     */
    employmentIncomeValidationGetValidateSmartQuestionAnswers: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/EmploymentIncomeValidation/GetValidateSmartQuestionAnswers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Error
     * @name ErrorReportError
     * @summary Report a UI error for logging.
     * @request POST:/v1/Error/ReportError
     * @secure
     * @response `200` `any` OK
     * @response `204` `any` The error was logged successfully.
     */
    errorReportError: (data, params = {}) =>
      this.request({
        path: `/v1/Error/ReportError`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Fees
     * @name FeesCalculateAllocatedSellerConcessions
     * @request POST:/v1/Fees/CalculateAllocatedSellerConcessions
     * @secure
     * @response `200` `number` OK
     */
    feesCalculateAllocatedSellerConcessions: (data, params = {}) =>
      this.request({
        path: `/v1/Fees/CalculateAllocatedSellerConcessions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Fees
     * @name FeesCalculateMaxAllowableSellerConcessions
     * @request POST:/v1/Fees/CalculateMaxAllowableSellerConcessions
     * @secure
     * @response `200` `number` OK
     */
    feesCalculateMaxAllowableSellerConcessions: (data, params = {}) =>
      this.request({
        path: `/v1/Fees/CalculateMaxAllowableSellerConcessions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags FinalReview
     * @name FinalReviewGetManualCheckStatus
     * @request POST:/v1/FinalReview/GetManualCheckStatus
     * @secure
     * @response `200` `(any)[]` OK
     */
    finalReviewGetManualCheckStatus: (data, params = {}) =>
      this.request({
        path: `/v1/FinalReview/GetManualCheckStatus`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags FinalReview
     * @name FinalReviewSaveManualCheckStatus
     * @request POST:/v1/FinalReview/SaveManualCheckStatus
     * @secure
     * @response `200` `any` OK
     */
    finalReviewSaveManualCheckStatus: (data, params = {}) =>
      this.request({
        path: `/v1/FinalReview/SaveManualCheckStatus`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags FinalReview
     * @name FinalReviewGetLoanStatusFlipEligibilityData
     * @request POST:/v1/FinalReview/GetLoanStatusFlipEligibilityData
     * @secure
     * @response `200` `any` OK
     */
    finalReviewGetLoanStatusFlipEligibilityData: (data, params = {}) =>
      this.request({
        path: `/v1/FinalReview/GetLoanStatusFlipEligibilityData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags FinalReview
     * @name FinalReviewSaveFinalRunValidationResult
     * @request POST:/v1/FinalReview/SaveFinalRunValidationResult
     * @secure
     * @response `200` `boolean` OK
     */
    finalReviewSaveFinalRunValidationResult: (data, params = {}) =>
      this.request({
        path: `/v1/FinalReview/SaveFinalRunValidationResult`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags FinalReview
     * @name FinalReviewChangeLoanStatus
     * @request POST:/v1/FinalReview/ChangeLoanStatus
     * @secure
     * @response `200` `boolean` OK
     */
    finalReviewChangeLoanStatus: (data, params = {}) =>
      this.request({
        path: `/v1/FinalReview/ChangeLoanStatus`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags FinalReview
     * @name FinalReviewFlipLoanStatus
     * @request POST:/v1/FinalReview/FlipLoanStatus
     * @secure
     * @response `200` `any` OK
     */
    finalReviewFlipLoanStatus: (data, params = {}) =>
      this.request({
        path: `/v1/FinalReview/FlipLoanStatus`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags FraudGuard
     * @name FraudGuardInsertFraudGuardRequest
     * @request POST:/v1/FraudGuard/InsertFraudGuardRequest
     * @secure
     * @response `200` `any` OK
     */
    fraudGuardInsertFraudGuardRequest: (data, params = {}) =>
      this.request({
        path: `/v1/FraudGuard/InsertFraudGuardRequest`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags FraudGuard
     * @name FraudGuardGetRedFlags
     * @request POST:/v1/FraudGuard/GetRedFlags
     * @secure
     * @response `200` `any` OK
     */
    fraudGuardGetRedFlags: (data, params = {}) =>
      this.request({
        path: `/v1/FraudGuard/GetRedFlags`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags FraudGuard
     * @name FraudGuardSaveThirdPartyChecksStatus
     * @request POST:/v1/FraudGuard/SaveThirdPartyChecksStatus
     * @secure
     * @response `200` `any` OK
     */
    fraudGuardSaveThirdPartyChecksStatus: (data, params = {}) =>
      this.request({
        path: `/v1/FraudGuard/SaveThirdPartyChecksStatus`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags FraudGuard
     * @name FraudGuardGetLatestFraudGuardData
     * @request POST:/v1/FraudGuard/GetLatestFraudGuardData
     * @secure
     * @response `200` `any` OK
     */
    fraudGuardGetLatestFraudGuardData: (data, params = {}) =>
      this.request({
        path: `/v1/FraudGuard/GetLatestFraudGuardData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags FraudGuard
     * @name FraudGuardUpdateFraudGuardViewedIndicator
     * @request POST:/v1/FraudGuard/UpdateFraudGuardViewedIndicator
     * @secure
     * @response `200` `any` OK
     */
    fraudGuardUpdateFraudGuardViewedIndicator: (data, params = {}) =>
      this.request({
        path: `/v1/FraudGuard/UpdateFraudGuardViewedIndicator`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeCalculator
     * @name IncomeCalculatorSave
     * @summary Save a calculated income result or override to a loan.
     * @request POST:/v1/IncomeCalculator/Save
     * @secure
     * @response `200` `boolean` OK
     */
    incomeCalculatorSave: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeCalculator/Save`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
       * No description
       *
       * @tags IncomeCalculator
       * @name IncomeCalculatorGetCalculations
       * @summary Get calculation data suitable for use on breakdown screen(s).
      
      Supports one of three types of request:
      
         * Employment income will return all calculation data for all calculators run for this income. (set RequestReferenceType=1, and include IncomeIdentifier)
      
         * Rental income will return all calculation data for a rental income by addressid (not implemented yet). (set RequestReferenceType=2, and include RentalAddressId)
      
         * Calculation will return income calculator data for a single calculation by calculationId. (set RequestReferenceType=3, and include CalculationId)
       * @request POST:/v1/IncomeCalculator/GetCalculations
       * @secure
       * @response `200` `any` OK
       */
    incomeCalculatorGetCalculations: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeCalculator/GetCalculations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeCalculator
     * @name IncomeCalculatorGetCalculationData
     * @request POST:/v1/IncomeCalculator/GetCalculationData
     * @secure
     * @response `200` `any` OK
     */
    incomeCalculatorGetCalculationData: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeCalculator/GetCalculationData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeCalculator
     * @name IncomeCalculatorGetDataForSelectedCalculator
     * @request POST:/v1/IncomeCalculator/GetDataForSelectedCalculator
     * @secure
     * @response `200` `any` OK
     */
    incomeCalculatorGetDataForSelectedCalculator: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeCalculator/GetDataForSelectedCalculator`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeCalculator
     * @name IncomeCalculatorGetSmartDataForSelectedCalculator
     * @request POST:/v1/IncomeCalculator/GetSmartDataForSelectedCalculator
     * @secure
     * @response `200` `any` OK
     */
    incomeCalculatorGetSmartDataForSelectedCalculator: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeCalculator/GetSmartDataForSelectedCalculator`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeCalculator
     * @name IncomeCalculatorSaveEmploymentIncomeToLoan
     * @request POST:/v1/IncomeCalculator/SaveEmploymentIncomeToLoan
     * @secure
     * @response `200` `any` OK
     */
    incomeCalculatorSaveEmploymentIncomeToLoan: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeCalculator/SaveEmploymentIncomeToLoan`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeCalculator
     * @name IncomeCalculatorSaveCalculationDataToLoan
     * @request POST:/v1/IncomeCalculator/SaveCalculationDataToLoan
     * @secure
     * @response `200` `any` OK
     */
    incomeCalculatorSaveCalculationDataToLoan: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeCalculator/SaveCalculationDataToLoan`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeCalculator
     * @name IncomeCalculatorRunCalculations
     * @request POST:/v1/IncomeCalculator/RunCalculations
     * @secure
     * @response `200` `any` OK
     */
    incomeCalculatorRunCalculations: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeCalculator/RunCalculations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeCalculator
     * @name IncomeCalculatorRunSmartCalculations
     * @request POST:/v1/IncomeCalculator/RunSmartCalculations
     * @secure
     * @response `200` `any` OK
     */
    incomeCalculatorRunSmartCalculations: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeCalculator/RunSmartCalculations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeCalculator
     * @name IncomeCalculatorGetCalculationsHistory
     * @request POST:/v1/IncomeCalculator/GetCalculationsHistory
     * @secure
     * @response `200` `any` OK
     */
    incomeCalculatorGetCalculationsHistory: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeCalculator/GetCalculationsHistory`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeCalculator
     * @name IncomeCalculatorGetOtherIncomeData
     * @request POST:/v1/IncomeCalculator/GetOtherIncomeData
     * @secure
     * @response `200` `any` OK
     */
    incomeCalculatorGetOtherIncomeData: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeCalculator/GetOtherIncomeData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeCalculator
     * @name IncomeCalculatorSaveOtherIncomeData
     * @request POST:/v1/IncomeCalculator/SaveOtherIncomeData
     * @secure
     * @response `200` `any` OK
     */
    incomeCalculatorSaveOtherIncomeData: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeCalculator/SaveOtherIncomeData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeCalculator
     * @name IncomeCalculatorSaveRentalIncomeToLoan
     * @request POST:/v1/IncomeCalculator/SaveRentalIncomeToLoan
     * @secure
     * @response `200` `any` OK
     */
    incomeCalculatorSaveRentalIncomeToLoan: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeCalculator/SaveRentalIncomeToLoan`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeCalculator
     * @name IncomeCalculatorCanRunLeaseAgreementCalculator
     * @request POST:/v1/IncomeCalculator/CanRunLeaseAgreementCalculator
     * @secure
     * @response `200` `boolean` OK
     */
    incomeCalculatorCanRunLeaseAgreementCalculator: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeCalculator/CanRunLeaseAgreementCalculator`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeReconciliation
     * @name IncomeReconciliationGetIncomeReconciliationData
     * @summary Gets All Income Reconciliation Data
     * @request POST:/v1/IncomeReconciliation/GetIncomeReconciliationData
     * @secure
     * @response `200` `any` OK
     */
    incomeReconciliationGetIncomeReconciliationData: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeReconciliation/GetIncomeReconciliationData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeReconciliation
     * @name IncomeReconciliationSaveIncomeReconciliationData
     * @summary Save All Income Reconciliation Data
     * @request POST:/v1/IncomeReconciliation/SaveIncomeReconciliationData
     * @secure
     * @response `200` `any` OK
     */
    incomeReconciliationSaveIncomeReconciliationData: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeReconciliation/SaveIncomeReconciliationData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeReconciliation
     * @name IncomeReconciliationCalculateDebtToIncome
     * @summary Calculate Debt to Income Data
     * @request POST:/v1/IncomeReconciliation/CalculateDebtToIncome
     * @secure
     * @response `200` `any` OK
     */
    incomeReconciliationCalculateDebtToIncome: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeReconciliation/CalculateDebtToIncome`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeResolution
     * @name IncomeResolutionGetIncomeResolutionDetails
     * @request POST:/v1/IncomeResolution/GetIncomeResolutionDetails
     * @secure
     * @response `200` `any` OK
     */
    incomeResolutionGetIncomeResolutionDetails: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeResolution/GetIncomeResolutionDetails`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeResolution
     * @name IncomeResolutionSaveIncomeResolutionDetails
     * @request POST:/v1/IncomeResolution/SaveIncomeResolutionDetails
     * @secure
     * @response `200` `any` OK
     */
    incomeResolutionSaveIncomeResolutionDetails: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeResolution/SaveIncomeResolutionDetails`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeSummary
     * @name IncomeSummarySave
     * @summary Updates the user's document selection and saves to the database.
     * @request POST:/v1/IncomeSummary/Save
     * @secure
     * @response `200` `any` The list of documents was updated successfully.
     * @response `500` `any` The documents could not be updated.
     */
    incomeSummarySave: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeSummary/Save`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeSummary
     * @name IncomeSummaryGet
     * @summary Gets the list of income documents for the user to choose from along with their previous inputs and some additional question sets.
     * @request POST:/v1/IncomeSummary/Get
     * @secure
     * @response `200` `any` The list populated successfully.
     */
    incomeSummaryGet: (data, params = {}) =>
      this.request({
        path: `/v1/IncomeSummary/Get`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeSummary
     * @name IncomeSummaryGetIncomeSummaryAndQuestionAnswerChildSupportFha
     * @request POST:/v1/IncomeSummary/GetIncomeSummaryAndQuestionAnswerChildSupportFha
     * @secure
     * @response `200` `any` OK
     */
    incomeSummaryGetIncomeSummaryAndQuestionAnswerChildSupportFha: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/IncomeSummary/GetIncomeSummaryAndQuestionAnswerChildSupportFha`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags IncomeSummary
     * @name IncomeSummarySaveIncomeSummaryAndQuestionAnswerChildSupportFha
     * @request POST:/v1/IncomeSummary/SaveIncomeSummaryAndQuestionAnswerChildSupportFha
     * @secure
     * @response `200` `any` OK
     */
    incomeSummarySaveIncomeSummaryAndQuestionAnswerChildSupportFha: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/IncomeSummary/SaveIncomeSummaryAndQuestionAnswerChildSupportFha`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags KnowYourLoan
     * @name KnowYourLoanGetKnowYourLoanSummary
     * @request POST:/v1/KnowYourLoan/GetKnowYourLoanSummary
     * @secure
     * @response `200` `any` OK
     */
    knowYourLoanGetKnowYourLoanSummary: (data, params = {}) =>
      this.request({
        path: `/v1/KnowYourLoan/GetKnowYourLoanSummary`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Liability
     * @name LiabilityGetLiabilities
     * @request POST:/v1/Liability/GetLiabilities
     * @secure
     * @response `200` `(any)[]` OK
     */
    liabilityGetLiabilities: (data, params = {}) =>
      this.request({
        path: `/v1/Liability/GetLiabilities`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Liability
     * @name LiabilityUpdateLiabilities
     * @request POST:/v1/Liability/UpdateLiabilities
     * @secure
     * @response `200` `boolean` OK
     */
    liabilityUpdateLiabilities: (data, params = {}) =>
      this.request({
        path: `/v1/Liability/UpdateLiabilities`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Liability
     * @name LiabilityCalculateRemainingTerm
     * @request POST:/v1/Liability/CalculateRemainingTerm
     * @secure
     * @response `200` `any` OK
     */
    liabilityCalculateRemainingTerm: (data, params = {}) =>
      this.request({
        path: `/v1/Liability/CalculateRemainingTerm`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Liability
     * @name LiabilityGetLiabilitiesVerifyData
     * @request POST:/v1/Liability/GetLiabilitiesVerifyData
     * @secure
     * @response `200` `any` OK
     */
    liabilityGetLiabilitiesVerifyData: (data, params = {}) =>
      this.request({
        path: `/v1/Liability/GetLiabilitiesVerifyData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Liability
     * @name LiabilityUpdateLiabilitiesVerifyData
     * @request POST:/v1/Liability/UpdateLiabilitiesVerifyData
     * @secure
     * @response `200` `any` OK
     */
    liabilityUpdateLiabilitiesVerifyData: (data, params = {}) =>
      this.request({
        path: `/v1/Liability/UpdateLiabilitiesVerifyData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Liability
     * @name LiabilityGetLiabilitiesAssociateData
     * @request POST:/v1/Liability/GetLiabilitiesAssociateData
     * @secure
     * @response `200` `any` OK
     */
    liabilityGetLiabilitiesAssociateData: (data, params = {}) =>
      this.request({
        path: `/v1/Liability/GetLiabilitiesAssociateData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Liability
     * @name LiabilityUpdateLiabilitiesAssociateData
     * @request POST:/v1/Liability/UpdateLiabilitiesAssociateData
     * @secure
     * @response `200` `any` OK
     */
    liabilityUpdateLiabilitiesAssociateData: (data, params = {}) =>
      this.request({
        path: `/v1/Liability/UpdateLiabilitiesAssociateData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Loan
     * @name LoanGetNavigationDetails
     * @request POST:/v1/Loan/GetNavigationDetails
     * @secure
     * @response `200` `any` OK
     */
    loanGetNavigationDetails: (data, params = {}) =>
      this.request({
        path: `/v1/Loan/GetNavigationDetails`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Loan
     * @name LoanGetLoanSummary
     * @request POST:/v1/Loan/GetLoanSummary
     * @secure
     * @response `200` `any` OK
     */
    loanGetLoanSummary: (data, params = {}) =>
      this.request({
        path: `/v1/Loan/GetLoanSummary`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Loan
     * @name LoanGetBoltNavFeatureFlags
     * @request POST:/v1/Loan/GetBoltNavFeatureFlags
     * @secure
     * @response `200` `any` OK
     */
    loanGetBoltNavFeatureFlags: (data, params = {}) =>
      this.request({
        path: `/v1/Loan/GetBoltNavFeatureFlags`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags LoanInfo
     * @name LoanInfoGetLoanInfo
     * @request POST:/v1/LoanInfo/GetLoanInfo
     * @secure
     * @response `200` `any` OK
     */
    loanInfoGetLoanInfo: (data, params = {}) =>
      this.request({
        path: `/v1/LoanInfo/GetLoanInfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Permission
     * @name PermissionCheckUserHasPermission
     * @request POST:/v1/Permission/CheckUserHasPermission
     * @secure
     * @response `200` `any` OK
     */
    permissionCheckUserHasPermission: (data, params = {}) =>
      this.request({
        path: `/v1/Permission/CheckUserHasPermission`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Permission
     * @name PermissionGetAllUserPermissions
     * @request POST:/v1/Permission/GetAllUserPermissions
     * @secure
     * @response `200` `any` OK
     */
    permissionGetAllUserPermissions: (data, params = {}) =>
      this.request({
        path: `/v1/Permission/GetAllUserPermissions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags PreviousEmployment
     * @name PreviousEmploymentGetPreviousEmploymentData
     * @summary Returns the list Previous Employers for the borrower with subsequent questions for each previous employment.
     * @request POST:/v1/PreviousEmployment/GetPreviousEmploymentData
     * @secure
     * @response `200` `any` OK
     */
    previousEmploymentGetPreviousEmploymentData: (data, params = {}) =>
      this.request({
        path: `/v1/PreviousEmployment/GetPreviousEmploymentData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags PreviousEmployment
     * @name PreviousEmploymentSavePreviousEmploymentData
     * @summary Returns true or false depending on if the save was completed successfully.
     * @request POST:/v1/PreviousEmployment/SavePreviousEmploymentData
     * @secure
     * @response `200` `any` OK
     */
    previousEmploymentSavePreviousEmploymentData: (data, params = {}) =>
      this.request({
        path: `/v1/PreviousEmployment/SavePreviousEmploymentData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Property
     * @name PropertyGetCdAtInitialUnderwriteData
     * @request POST:/v1/Property/GetCdAtInitialUnderwriteData
     * @secure
     * @response `200` `any` OK
     */
    propertyGetCdAtInitialUnderwriteData: (data, params = {}) =>
      this.request({
        path: `/v1/Property/GetCdAtInitialUnderwriteData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Property
     * @name PropertyGetQuestionAnswersDataAsync
     * @request POST:/v1/Property/GetQuestionAnswersDataAsync
     * @secure
     * @response `200` `any` OK
     */
    propertyGetQuestionAnswersDataAsync: (data, params = {}) =>
      this.request({
        path: `/v1/Property/GetQuestionAnswersDataAsync`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Property
     * @name PropertySaveQuestionAnswersDataAsync
     * @request POST:/v1/Property/SaveQuestionAnswersDataAsync
     * @secure
     * @response `200` `boolean` OK
     */
    propertySaveQuestionAnswersDataAsync: (data, params = {}) =>
      this.request({
        path: `/v1/Property/SaveQuestionAnswersDataAsync`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags PropertyInformation
     * @name PropertyInformationGetPropertyValidationQuestionAnswer
     * @request POST:/v1/PropertyInformation/GetPropertyValidationQuestionAnswer
     * @secure
     * @response `200` `any` OK
     */
    propertyInformationGetPropertyValidationQuestionAnswer: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/PropertyInformation/GetPropertyValidationQuestionAnswer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags PropertyInformation
     * @name PropertyInformationGetUwaiQuestionAnswer
     * @request POST:/v1/PropertyInformation/GetUwaiQuestionAnswer
     * @secure
     * @response `200` `any` OK
     */
    propertyInformationGetUwaiQuestionAnswer: (data, params = {}) =>
      this.request({
        path: `/v1/PropertyInformation/GetUwaiQuestionAnswer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags PropertyInformation
     * @name PropertyInformationGetQuestionAnswersDataAsync
     * @request POST:/v1/PropertyInformation/GetQuestionAnswersDataAsync
     * @secure
     * @response `200` `any` OK
     */
    propertyInformationGetQuestionAnswersDataAsync: (data, params = {}) =>
      this.request({
        path: `/v1/PropertyInformation/GetQuestionAnswersDataAsync`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags PropertyInformation
     * @name PropertyInformationSavePropertyInformationQuestionAnswersData
     * @request POST:/v1/PropertyInformation/SavePropertyInformationQuestionAnswersData
     * @secure
     * @response `200` `any` OK
     */
    propertyInformationSavePropertyInformationQuestionAnswersData: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/PropertyInformation/SavePropertyInformationQuestionAnswersData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags PropertyInformation
     * @name PropertyInformationSearchByWireInfo
     * @request POST:/v1/PropertyInformation/SearchByWireInfo
     * @secure
     * @response `200` `any` OK
     */
    propertyInformationSearchByWireInfo: (data, params = {}) =>
      this.request({
        path: `/v1/PropertyInformation/SearchByWireInfo`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags PropertyInformation
     * @name PropertyInformationGetUwaiV2QuestionAnswer
     * @request POST:/v1/PropertyInformation/GetUwaiV2QuestionAnswer
     * @secure
     * @response `200` `any` OK
     */
    propertyInformationGetUwaiV2QuestionAnswer: (data, params = {}) =>
      this.request({
        path: `/v1/PropertyInformation/GetUwaiV2QuestionAnswer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags PropertyInsurance
     * @name PropertyInsuranceGetHomeownersInsuranceData
     * @request POST:/v1/PropertyInsurance/GetHomeownersInsuranceData
     * @secure
     * @response `200` `any` OK
     */
    propertyInsuranceGetHomeownersInsuranceData: (data, params = {}) =>
      this.request({
        path: `/v1/PropertyInsurance/GetHomeownersInsuranceData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags PropertyInsurance
     * @name PropertyInsuranceGetInsuranceDataAsync
     * @request POST:/v1/PropertyInsurance/GetInsuranceDataAsync
     * @secure
     * @response `200` `any` OK
     */
    propertyInsuranceGetInsuranceDataAsync: (data, params = {}) =>
      this.request({
        path: `/v1/PropertyInsurance/GetInsuranceDataAsync`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags PropertyInsurance
     * @name PropertyInsuranceSaveInsuranceDataAsync
     * @request POST:/v1/PropertyInsurance/SaveInsuranceDataAsync
     * @secure
     * @response `200` `any` OK
     */
    propertyInsuranceSaveInsuranceDataAsync: (data, params = {}) =>
      this.request({
        path: `/v1/PropertyInsurance/SaveInsuranceDataAsync`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags PropertyInsurance
     * @name PropertyInsuranceTryOrderFloodCertAsync
     * @request POST:/v1/PropertyInsurance/TryOrderFloodCertAsync
     * @secure
     * @response `200` `boolean` OK
     */
    propertyInsuranceTryOrderFloodCertAsync: (data, params = {}) =>
      this.request({
        path: `/v1/PropertyInsurance/TryOrderFloodCertAsync`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags PurchaseAgreement
     * @name PurchaseAgreementGetPurchaseAgreement
     * @request POST:/v1/PurchaseAgreement/GetPurchaseAgreement
     * @secure
     * @response `200` `any` OK
     */
    purchaseAgreementGetPurchaseAgreement: (data, params = {}) =>
      this.request({
        path: `/v1/PurchaseAgreement/GetPurchaseAgreement`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags PurchaseAgreement
     * @name PurchaseAgreementUpdatePurchaseAgreement
     * @request POST:/v1/PurchaseAgreement/UpdatePurchaseAgreement
     * @secure
     * @response `200` `any` OK
     */
    purchaseAgreementUpdatePurchaseAgreement: (data, params = {}) =>
      this.request({
        path: `/v1/PurchaseAgreement/UpdatePurchaseAgreement`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags PurchaseAgreement
     * @name PurchaseAgreementGetFhaRequirements
     * @request POST:/v1/PurchaseAgreement/GetFhaRequirements
     * @secure
     * @response `200` `any` OK
     */
    purchaseAgreementGetFhaRequirements: (data, params = {}) =>
      this.request({
        path: `/v1/PurchaseAgreement/GetFhaRequirements`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags PurchaseAgreement
     * @name PurchaseAgreementUpdateFhaRequirements
     * @request POST:/v1/PurchaseAgreement/UpdateFhaRequirements
     * @secure
     * @response `200` `any` OK
     */
    purchaseAgreementUpdateFhaRequirements: (data, params = {}) =>
      this.request({
        path: `/v1/PurchaseAgreement/UpdateFhaRequirements`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Questions
     * @name QuestionsAnswers
     * @summary Saves a list of question answers to the database and re-triggers rule evaluations.
     * @request POST:/v1/Questions/Answers
     * @secure
     * @response `200` `any` OK
     * @response `204` `any` The answers were updated successfully
     * @response `400` `any` The body was malformed
     * @response `500` `any` The answers failed to update
     */
    questionsAnswers: (data, params = {}) =>
      this.request({
        path: `/v1/Questions/Answers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags RealEstateOwned
     * @name RealEstateOwnedGetRealEstateOwned
     * @request POST:/v1/RealEstateOwned/GetRealEstateOwned
     * @secure
     * @response `200` `any` OK
     */
    realEstateOwnedGetRealEstateOwned: (data, params = {}) =>
      this.request({
        path: `/v1/RealEstateOwned/GetRealEstateOwned`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags RealEstateOwned
     * @name RealEstateOwnedSaveRealEstateOwned
     * @request POST:/v1/RealEstateOwned/SaveRealEstateOwned
     * @secure
     * @response `200` `boolean` OK
     */
    realEstateOwnedSaveRealEstateOwned: (data, params = {}) =>
      this.request({
        path: `/v1/RealEstateOwned/SaveRealEstateOwned`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags RealEstateOwned
     * @name RealEstateOwnedGetRealEstateOwnedSingleProperty
     * @request POST:/v1/RealEstateOwned/GetRealEstateOwnedSingleProperty
     * @secure
     * @response `200` `any` OK
     */
    realEstateOwnedGetRealEstateOwnedSingleProperty: (data, params = {}) =>
      this.request({
        path: `/v1/RealEstateOwned/GetRealEstateOwnedSingleProperty`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags RealEstateOwned
     * @name RealEstateOwnedGetRealEstateOwnedSummary
     * @request POST:/v1/RealEstateOwned/GetRealEstateOwnedSummary
     * @secure
     * @response `200` `any` OK
     */
    realEstateOwnedGetRealEstateOwnedSummary: (data, params = {}) =>
      this.request({
        path: `/v1/RealEstateOwned/GetRealEstateOwnedSummary`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags RealEstateOwned
     * @name RealEstateOwnedDeleteRealEstateOwned
     * @request POST:/v1/RealEstateOwned/DeleteRealEstateOwned
     * @secure
     * @response `200` `any` OK
     */
    realEstateOwnedDeleteRealEstateOwned: (data, params = {}) =>
      this.request({
        path: `/v1/RealEstateOwned/DeleteRealEstateOwned`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags RealEstateOwned
     * @name RealEstateOwnedSaveRealEstateOwnedSummary
     * @request POST:/v1/RealEstateOwned/SaveRealEstateOwnedSummary
     * @secure
     * @response `200` `boolean` OK
     */
    realEstateOwnedSaveRealEstateOwnedSummary: (data, params = {}) =>
      this.request({
        path: `/v1/RealEstateOwned/SaveRealEstateOwnedSummary`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags RentalIncomeValidation
     * @name RentalIncomeValidationGetRentalIncomeValidationData
     * @request POST:/v1/RentalIncomeValidation/GetRentalIncomeValidationData
     * @secure
     * @response `200` `any` OK
     */
    rentalIncomeValidationGetRentalIncomeValidationData: (data, params = {}) =>
      this.request({
        path: `/v1/RentalIncomeValidation/GetRentalIncomeValidationData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags RentalIncomeValidation
     * @name RentalIncomeValidationSaveRentalIncomeDocumentSelectionAndQuestionAnswer
     * @request POST:/v1/RentalIncomeValidation/SaveRentalIncomeDocumentSelectionAndQuestionAnswer
     * @secure
     * @response `200` `any` OK
     */
    rentalIncomeValidationSaveRentalIncomeDocumentSelectionAndQuestionAnswer: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/RentalIncomeValidation/SaveRentalIncomeDocumentSelectionAndQuestionAnswer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags RentalIncomeValidation
     * @name RentalIncomeValidationGetRentalIncomeData
     * @request POST:/v1/RentalIncomeValidation/GetRentalIncomeData
     * @secure
     * @response `200` `any` OK
     */
    rentalIncomeValidationGetRentalIncomeData: (data, params = {}) =>
      this.request({
        path: `/v1/RentalIncomeValidation/GetRentalIncomeData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags RentalIncomeValidation
     * @name RentalIncomeValidationGetRentalIncomeQuestionsAndAnswersByAddressId
     * @request POST:/v1/RentalIncomeValidation/GetRentalIncomeQuestionsAndAnswersByAddressId
     * @secure
     * @response `200` `any` OK
     */
    rentalIncomeValidationGetRentalIncomeQuestionsAndAnswersByAddressId: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/RentalIncomeValidation/GetRentalIncomeQuestionsAndAnswersByAddressId`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags RentalIncomeValidation
     * @name RentalIncomeValidationGetNoDocumentsProvidedQuestionAnswers
     * @request POST:/v1/RentalIncomeValidation/GetNoDocumentsProvidedQuestionAnswers
     * @secure
     * @response `200` `any` OK
     */
    rentalIncomeValidationGetNoDocumentsProvidedQuestionAnswers: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/RentalIncomeValidation/GetNoDocumentsProvidedQuestionAnswers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags RentalIncomeValidation
     * @name RentalIncomeValidationSaveRentalIncomeQuestionAnswers
     * @request POST:/v1/RentalIncomeValidation/SaveRentalIncomeQuestionAnswers
     * @secure
     * @response `200` `any` OK
     */
    rentalIncomeValidationSaveRentalIncomeQuestionAnswers: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/RentalIncomeValidation/SaveRentalIncomeQuestionAnswers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Rule
     * @name RuleTriggerAllRules
     * @summary Endpoint triggering all rules to be run that are associated with a loan
     * @request POST:/v1/Rule/TriggerAllRules
     * @secure
     * @response `200` `any` OK
     */
    ruleTriggerAllRules: (data, params = {}) =>
      this.request({
        path: `/v1/Rule/TriggerAllRules`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Rule
     * @name RuleGetClassifyFlag
     * @request POST:/v1/Rule/GetClassifyFlag
     * @secure
     * @response `200` `any` OK
     */
    ruleGetClassifyFlag: (data, params = {}) =>
      this.request({
        path: `/v1/Rule/GetClassifyFlag`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags SaveAssets
     * @name SaveAssetsReconcileAssets
     * @request POST:/v1/SaveAssets/ReconcileAssets
     * @secure
     * @response `200` `boolean` OK
     */
    saveAssetsReconcileAssets: (data, params = {}) =>
      this.request({
        path: `/v1/SaveAssets/ReconcileAssets`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags SaveAssets
     * @name SaveAssetsSaveAssetSummary
     * @request POST:/v1/SaveAssets/SaveAssetSummary
     * @secure
     * @response `200` `boolean` OK
     */
    saveAssetsSaveAssetSummary: (data, params = {}) =>
      this.request({
        path: `/v1/SaveAssets/SaveAssetSummary`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags SaveAssets
     * @name SaveAssetsSaveAssetQuestionAnswers
     * @request POST:/v1/SaveAssets/SaveAssetQuestionAnswers
     * @secure
     * @response `200` `any` OK
     */
    saveAssetsSaveAssetQuestionAnswers: (data, params = {}) =>
      this.request({
        path: `/v1/SaveAssets/SaveAssetQuestionAnswers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Statement
     * @name StatementGetStatementInformation
     * @request POST:/v1/Statement/GetStatementInformation
     * @secure
     * @response `200` `any` OK
     */
    statementGetStatementInformation: (data, params = {}) =>
      this.request({
        path: `/v1/Statement/GetStatementInformation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags StatementSave
     * @name StatementSaveSaveStatementInformation
     * @request POST:/v1/StatementSave/SaveStatementInformation
     * @secure
     * @response `200` `any` OK
     */
    statementSaveSaveStatementInformation: (data, params = {}) =>
      this.request({
        path: `/v1/StatementSave/SaveStatementInformation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Submission
     * @name SubmissionSubmitLoanToSetup
     * @summary Completes the BOLT process and submits the loan to underwriting.
     * @request POST:/v1/Submission/SubmitLoanToSetup
     * @secure
     * @response `200` `any` An alert was returned that the user must confirm before submitting the loan.
     * @response `204` `any` The loan was submitted to underwriting.
     * @response `400` `any` The loan is not in a valid state to submit.
     * @response `500` `any` An error occurred while submitting the loan.
     */
    submissionSubmitLoanToSetup: (data, params = {}) =>
      this.request({
        path: `/v1/Submission/SubmitLoanToSetup`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Submission
     * @name SubmissionBailOutAndSubmitLoanToSetup
     * @summary Bails the broker out of BOLT and submits to the queue for a traditional underwrite.
     * @request POST:/v1/Submission/BailOutAndSubmitLoanToSetup
     * @secure
     * @response `200` `any` An alert was returned that the user must review before bailing out.
     * @response `204` `any` The loan was submitted successfully.
     * @response `400` `any` The loan is not in a valid state to bail out.
     * @response `500` `any` An error occurred while bailing out.
     */
    submissionBailOutAndSubmitLoanToSetup: (data, params = {}) =>
      this.request({
        path: `/v1/Submission/BailOutAndSubmitLoanToSetup`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Submission
     * @name SubmissionRunBoltApprovalProcess
     * @summary Generates the BOLT approval letter.
     * @request POST:/v1/Submission/RunBoltApprovalProcess
     * @secure
     * @response `200` `any` OK
     */
    submissionRunBoltApprovalProcess: (data, params = {}) =>
      this.request({
        path: `/v1/Submission/RunBoltApprovalProcess`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Submission
     * @name SubmissionGetFinalReviewValidationAfterAusRun
     * @summary Gets validation for submit button on Final Review based on the AUS result.
     * @request POST:/v1/Submission/GetFinalReviewValidationAfterAUSRun
     * @secure
     * @response `200` `any` Whether the submit button should be active, and a list of UI alerts.
     */
    submissionGetFinalReviewValidationAfterAusRun: (data, params = {}) =>
      this.request({
        path: `/v1/Submission/GetFinalReviewValidationAfterAUSRun`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Submission
     * @name SubmissionGetInitialFinalReviewValidation
     * @summary Grabs initial page validations for Final Review determining the active states of the buttons.
     * @request POST:/v1/Submission/GetInitialFinalReviewValidation
     * @secure
     * @response `200` `any` Whether the user can run AUS or submit the loan, and a list of UI alert messages.
     */
    submissionGetInitialFinalReviewValidation: (data, params = {}) =>
      this.request({
        path: `/v1/Submission/GetInitialFinalReviewValidation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags UnderwriterVerified
     * @name UnderwriterVerifiedSaveUnderwriterVerified
     * @request POST:/v1/UnderwriterVerified/SaveUnderwriterVerified
     * @secure
     * @response `200` `any` OK
     */
    underwriterVerifiedSaveUnderwriterVerified: (data, params = {}) =>
      this.request({
        path: `/v1/UnderwriterVerified/SaveUnderwriterVerified`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags UnderwriterVerified
     * @name UnderwriterVerifiedGetUnderwriterVerified
     * @request POST:/v1/UnderwriterVerified/GetUnderwriterVerified
     * @secure
     * @response `200` `(any)[]` OK
     */
    underwriterVerifiedGetUnderwriterVerified: (data, params = {}) =>
      this.request({
        path: `/v1/UnderwriterVerified/GetUnderwriterVerified`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags UnderwritingSummaryIncome
     * @name UnderwritingSummaryIncomeGetUnderwritingSummaryIncomeData
     * @summary Get all of the income data required for the income summary page
     * @request POST:/v1/UnderwritingSummaryIncome/GetUnderwritingSummaryIncomeData
     * @secure
     * @response `200` `any` OK
     */
    underwritingSummaryIncomeGetUnderwritingSummaryIncomeData: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/UnderwritingSummaryIncome/GetUnderwritingSummaryIncomeData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags UnderwritingSummaryIncome
     * @name UnderwritingSummaryIncomeUpsertUnderwritingSummaryIncome
     * @summary Upsert an income source (employer or specific non-employment type) along with all incomes from that source.
     * @request POST:/v1/UnderwritingSummaryIncome/UpsertUnderwritingSummaryIncome
     * @secure
     * @response `200` `boolean` OK
     */
    underwritingSummaryIncomeUpsertUnderwritingSummaryIncome: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/UnderwritingSummaryIncome/UpsertUnderwritingSummaryIncome`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags UnderwritingSummaryIncome
     * @name UnderwritingSummaryIncomeDeleteUnderwritingSummaryIncome
     * @summary Delete specified income for the specified borrower.
     * @request POST:/v1/UnderwritingSummaryIncome/DeleteUnderwritingSummaryIncome
     * @secure
     * @response `200` `any` OK
     */
    underwritingSummaryIncomeDeleteUnderwritingSummaryIncome: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/UnderwritingSummaryIncome/DeleteUnderwritingSummaryIncome`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags UnderwritingSummaryLiabilities
     * @name UnderwritingSummaryLiabilitiesGetLiabilitiesSummaryData
     * @request POST:/v1/UnderwritingSummaryLiabilities/GetLiabilitiesSummaryData
     * @secure
     * @response `200` `any` OK
     */
    underwritingSummaryLiabilitiesGetLiabilitiesSummaryData: (
      data,
      params = {},
    ) =>
      this.request({
        path: `/v1/UnderwritingSummaryLiabilities/GetLiabilitiesSummaryData`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags UnderwritingSummaryLiabilities
     * @name UnderwritingSummaryLiabilitiesUpsertLiability
     * @request POST:/v1/UnderwritingSummaryLiabilities/UpsertLiability
     * @secure
     * @response `200` `any` OK
     */
    underwritingSummaryLiabilitiesUpsertLiability: (data, params = {}) =>
      this.request({
        path: `/v1/UnderwritingSummaryLiabilities/UpsertLiability`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags UnderwritingSummaryLiabilities
     * @name UnderwritingSummaryLiabilitiesDeleteLiabilities
     * @request POST:/v1/UnderwritingSummaryLiabilities/DeleteLiabilities
     * @secure
     * @response `200` `any` OK
     */
    underwritingSummaryLiabilitiesDeleteLiabilities: (data, params = {}) =>
      this.request({
        path: `/v1/UnderwritingSummaryLiabilities/DeleteLiabilities`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Validation
     * @name ValidationSaveQuestionAnswers
     * @request POST:/v1/Validation/SaveQuestionAnswers
     * @secure
     * @response `200` `any` OK
     */
    validationSaveQuestionAnswers: (data, params = {}) =>
      this.request({
        path: `/v1/Validation/SaveQuestionAnswers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Validation
     * @name ValidationSaveBorrowerInfoQuestionAnswers
     * @request POST:/v1/Validation/SaveBorrowerInfoQuestionAnswers
     * @secure
     * @response `200` `any` OK
     */
    validationSaveBorrowerInfoQuestionAnswers: (data, params = {}) =>
      this.request({
        path: `/v1/Validation/SaveBorrowerInfoQuestionAnswers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags ValidationData
     * @name ValidationDataGetIncomeValidationKeyDates
     * @request POST:/v1/ValidationData/GetIncomeValidationKeyDates
     * @secure
     * @response `200` `any` OK
     */
    validationDataGetIncomeValidationKeyDates: (data, params = {}) =>
      this.request({
        path: `/v1/ValidationData/GetIncomeValidationKeyDates`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  sdui = {
    /**
     * No description
     *
     * @tags ServerDrivenUI
     * @name Sdui
     * @summary Retrieve a YAML layout for rendering a page using the app renderer.
     * @request POST:/sdui
     * @secure
     * @response `200` `any` The page was generated successfully.
     * @response `404` `any` The requested page does not exist.
     * @response `501` `any` The YAML builder for the given rule has not yet been implemented.
     */
    sdui: (data, query, params = {}) =>
      this.request({
        path: `/sdui`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags ServerDrivenUI
     * @name Data
     * @summary Retrieve the data for the associated page that is used to hydrate the YAML layout.
     * @request POST:/sdui/data
     * @secure
     * @response `200` `any` The page was generated successfully.
     * @response `404` `any` The requested page does not exist.
     * @response `501` `any` The YAML builder for the given rule has not yet been implemented.
     */
    data: (data, query, params = {}) =>
      this.request({
        path: `/sdui/data`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags ServerDrivenUI
     * @name Answers
     * @summary Save question answers based on a page rendered with server-driven YAML layouts.
     * @request POST:/sdui/answers
     * @secure
     * @response `200` `any` OK
     * @response `204` `any` The data was saved.
     * @response `403` `any` The user does not have permission to write to the requested question.
     * @response `404` `any` The requested page does not exist.
     * @response `501` `any` The YAML builder for the given rule has not yet been implemented.
     */
    answers: (data, query, params = {}) =>
      this.request({
        path: `/sdui/answers`,
        method: 'POST',
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  v2 = {
    /**
     * No description
     *
     * @tags ClassifyDocuments
     * @name ClassifyDocumentsGetClassifiedDocuments
     * @request POST:/v2/ClassifyDocuments/GetClassifiedDocuments
     * @secure
     * @response `200` `DtoV2ClassifyDocumentsGetClassifiedDocumentsResponse` OK
     */
    classifyDocumentsGetClassifiedDocuments: (data, params = {}) =>
      this.request({
        path: `/v2/ClassifyDocuments/GetClassifiedDocuments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags ClassifyDocuments
     * @name ClassifyDocumentsGetDocumentTypeAssociations
     * @request POST:/v2/ClassifyDocuments/GetDocumentTypeAssociations
     * @secure
     * @response `200` `DtoV2ClassifyDocumentsGetDocumentTypeAssociationsResponseV2` OK
     */
    classifyDocumentsGetDocumentTypeAssociations: (data, params = {}) =>
      this.request({
        path: `/v2/ClassifyDocuments/GetDocumentTypeAssociations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags ClassifyDocuments
     * @name ClassifyDocumentsGetClassifyDocumentsV2FeatureFlagDetail
     * @request GET:/v2/ClassifyDocuments/GetClassifyDocumentsV2FeatureFlag/{obfuscatedLenderDatabaseId}/{obfuscatedLoanRecordId}
     * @secure
     * @response `200` `boolean` OK
     */
    classifyDocumentsGetClassifyDocumentsV2FeatureFlagDetail: (
      obfuscatedLenderDatabaseId,
      obfuscatedLoanRecordId,
      params = {},
    ) =>
      this.request({
        path: `/v2/ClassifyDocuments/GetClassifyDocumentsV2FeatureFlag/${obfuscatedLenderDatabaseId}/${obfuscatedLoanRecordId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags DocumentAssociation
     * @name DocumentAssociationGetDocumentAssociations
     * @request POST:/v2/DocumentAssociation/GetDocumentAssociations
     * @secure
     * @response `200` `DtoV2DocumentAssociationGetDocumentAssociationsResponseV2` OK
     */
    documentAssociationGetDocumentAssociations: (data, params = {}) =>
      this.request({
        path: `/v2/DocumentAssociation/GetDocumentAssociations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags DocumentAssociation
     * @name DocumentAssociationSaveDocumentAssociations
     * @request POST:/v2/DocumentAssociation/SaveDocumentAssociations
     * @secure
     * @response `200` `DtoV1DocumentAssociationSuccessResponse` OK
     */
    documentAssociationSaveDocumentAssociations: (data, params = {}) =>
      this.request({
        path: `/v2/DocumentAssociation/SaveDocumentAssociations`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Loan
     * @name LoanGetLoanSummary
     * @request POST:/v2/Loan/GetLoanSummary
     * @secure
     * @response `200` `any` OK
     */
    loanGetLoanSummary: (data, params = {}) =>
      this.request({
        path: `/v2/Loan/GetLoanSummary`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags RealEstateOwned
     * @name RealEstateOwnedSaveRealEstateOwned
     * @request POST:/v2/RealEstateOwned/SaveRealEstateOwned
     * @secure
     * @response `200` `boolean` OK
     */
    realEstateOwnedSaveRealEstateOwned: (data, params = {}) =>
      this.request({
        path: `/v2/RealEstateOwned/SaveRealEstateOwned`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
}
