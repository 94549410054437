import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Oops, Spinner } from 'common/components';
import useCalculationData from 'pages/IncomeCalculator/Pages/GenericReviewBreakdown/useCalculationData';
import CalculationBreakdown from 'pages/IncomeCalculator/Pages/ReviewBreakdown/QualifyingCalculationBreakdown';

export default function QualifyingIncomeBreakdown({ incomeIdentifier }) {
  const { data, isFetching, isError } = useCalculationData(incomeIdentifier);

  const calculationId = data?.initialFormValues?.calculationId;
  const selectedResult = useMemo(() => {
    if (!calculationId) {
      return null;
    }

    return data.screenData.calculationData.find(
      (calc) => calc.calculationId === calculationId,
    );
  }, [calculationId, data?.screenData?.calculationData]);

  if (isError) {
    return <Oops />;
  }

  if (isFetching || !data) {
    return <Spinner />;
  }

  return <CalculationBreakdown selectedResult={selectedResult} hideAccordion />;
}
QualifyingIncomeBreakdown.propTypes = {
  incomeIdentifier: PropTypes.object.isRequired,
};
